import React from 'react';
import { redirect } from 'react-router-dom';
import { fetchPrices } from '../../actions/billing';
import { fetchStarting } from '../../actions/consultation';
import { fetchGroup } from '../../actions/group';
import { fetchPermissions } from '../../actions/permission';
import BillingSettings from '../../components/BillingSettings';
import ConsultationsStarting from '../../components/ConsultationsStarting';
import CreditPurchaseForm from '../../components/CreditPurchaseForm';
import CreditSettings from '../../components/CreditSettings';
import FAIcon from '../../components/Icon/FAIcon';
import LayoutPage from '../../components/Layout/LayoutPage';
import Link from '../../components/Link';
import Settings from '../../components/Settings';
import { WrapperComponent } from '../../components/WrapperComponent';
import { formatAccountType } from '../../core/billing';
import { darkGreen } from '../../core/colors';
import AboutPage from './AboutPage';
import Branding from './Branding';
import Preferences from './Preferences';
import SavedSearches from './SavedSearches';
import Subdomain from './Subdomain';

export default {
  path: '/team/:teamSlug/settings/:section?/:subsection?',
  element: <WrapperComponent />,
  async action({ store, params }) {
    try {
      const path = !params.section
        ? null
        : !params.subsection
          ? params.section
          : `${params.section}/${params.subsection}`;
      const { viewer } = store.getState();

      const { teamSlug } = params;

      const [group] = await Promise.all(
        [
          fetchGroup(teamSlug, {
            transactions: true,
            aboutPage: true,
            profileKeywordsDefinition: true,
            internalNetwork: true,
            domain: true,
            savedSearches: true,
          }),
          fetchStarting(),
        ].map(store.dispatch)
      );
      if (!group) return redirect('/404');

      await store.dispatch(fetchPrices(group.billing_account?.id));

      const [
        canViewSettings,
        canUpdateGroup,
        canUpdateHigherTierSettings,
        canUpdateAdminSettings,
      ] = await store.dispatch(
        fetchPermissions(
          viewer.id,
          [
            { service: 'group', action: 'view_settings', resource: group.id },
            { service: 'group', action: 'update', resource: group.id },
            {
              service: 'group',
              action: 'update_higher_tier_settings',
              resource: group.id,
            },
            {
              service: 'group',
              action: 'update_admin_settings',
              resource: group.id,
            },
          ].filter(Boolean)
        )
      );

      if (
        !canViewSettings.allowed ||
        (!canUpdateGroup.allowed && !group.billing_account)
      ) {
        return redirect('/404');
      }
      const sections = [];

      if (group.billing_account) {
        sections.push({
          title: 'Credits',
          path: 'credits',
          component: CreditSettings,
          componentProps: {
            account: group.billing_account,
            purchasePath: `/team/${teamSlug}/settings/credits/purchase`,
            // memberNotOwner: !memberOwner,
            accountType: formatAccountType(group.account_type),
            seatCount: group.billing_account?.seat_count,
          },
        });

        sections.push({
          title: 'Billing',
          path: 'billing',
          url: true,
          component: BillingSettings,
          componentProps: {
            account: group.billing_account,
          },
        });
      }

      if (canUpdateGroup.allowed) {
        sections.push({
          title: 'Purchase Credits',
          path: 'credits/purchase',
          component: CreditPurchaseForm,
          componentProps: {
            account: group.billing_account,
            purchaseDonePath: `/team/${teamSlug}/settings/credits`,
            groupId: group.id,
          },
        });
        sections.push({
          title: 'Preferences',
          path: 'preferences',
          component: Preferences,
          componentProps: { group },
        });
      }

      if (canUpdateHigherTierSettings.allowed) {
        sections.push({
          title: 'About Page',
          path: 'about_page',
          component: AboutPage,
          componentProps: { groupId: group.id, isViewerAdmin: viewer.admin },
        });
      }

      const adminSections = [];

      if (
        group.account_type === 'enterprise' &&
        canUpdateAdminSettings.allowed
      ) {
        adminSections.push({
          title: 'Subdomain',
          path: 'subdomain',
          component: Subdomain,
          componentProps: { groupId: group.id, isViewerAdmin: viewer.admin },
        });

        adminSections.push({
          title: 'Saved Searches',
          path: 'saved_searches',
          component: SavedSearches,
          componentProps: { groupId: group.id },
        });

        adminSections.push({
          title: 'Branding',
          path: 'branding',
          component: Branding,
          componentProps: { groupId: group.id, isViewerAdmin: viewer.admin },
        });
      }

      const section = path
        ? sections.find((s) => s.path === path) ||
          adminSections.find((s) => s.path === path)
        : sections[0];
      if (!section) return redirect('/404');

      document.title = `${group.name} Settings`;
      return (
        <LayoutPage showNav selected="team">
          <ConsultationsStarting />
          <Settings
            pathPrefix={`/team/${teamSlug}/settings`}
            subTitle={
              <Link
                to={`/team/${teamSlug}`}
                style={{
                  display: 'inline-block',
                  marginBottom: 25,
                  fontWeight: 500,
                }}
              >
                <FAIcon
                  iconSet="fal"
                  color={darkGreen}
                  icon="long-arrow-left"
                />{' '}
                {group.name}
              </Link>
            }
            nav={[
              { title: 'Team Settings', items: sections },
              adminSections.length > 0 && {
                title: 'Admin Only',
                items: adminSections,
              },
            ].filter(Boolean)}
            {...section}
          />
        </LayoutPage>
      );
    } catch (e) {
      if (e.isPermissionError) return redirect('/404');
      throw e;
    }
  },
};
