import React, { Component } from 'react';

import { formatSeconds } from '../../../core/time';
import TimelineEvent from './TimelineEvent';
import TimelineCall from './TimelineCall';
import TimelineNetworkEvent from './TimelineNetworkEvent';
import TimelinePopover from './TimelinePopover';
import s from './Timeline.module.scss';

class Timeline extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cursorPosition: -1,
      cursorSecond: '00:00',
      callAnchorElement: undefined,
      selectedParticipant: undefined,
    };
  }

  updateCursor = (event) => {
    const conferenceDuration = this.props.range.duration().seconds();
    const timelineWidth = event.currentTarget.clientWidth;
    const cursorPosition = event.clientX - event.currentTarget.offsetLeft;
    const pxPerSecond = timelineWidth / conferenceDuration;
    const cursorSecond = cursorPosition / pxPerSecond;

    if (cursorPosition > timelineWidth) {
      return this.hideCursor();
    }

    this.setState({
      cursorPosition,
      cursorSecond,
    });
  };

  hideCursor = () => {
    this.setState({
      cursorPosition: -1,
      cursorSecond: '00:00',
    });
  };

  handleParticipantPopover = (e, p) => {
    e.preventDefault();
    this.setState({
      selectedParticipant: p,
      callAnchorElement: e.currentTarget,
    });
  };

  handleCallClose = () => {
    this.setState({
      selectedParticipant: undefined,
      callAnchorElement: null,
    });
  };

  render() {
    const { carrier, range, participants } = this.props;

    const {
      cursorPosition,
      cursorSecond,
      selectedParticipant,
      callAnchorElement,
    } = this.state;

    const conferenceDuration = range.duration().seconds();

    return (
      <div onMouseMove={this.updateCursor} onMouseLeave={this.hideCursor}>
        <div className={s.lines}>
          <div className={s.conference} />

          {participants.map((p) => {
            const events = p.events();

            const timelineNetworkEvents = events
              ? events
                  .filter(
                    (e) =>
                      e.level === 'WARNING' &&
                      e.group === 'network-quality-warning-raised'
                  )
                  .map((e) => (
                    <TimelineNetworkEvent
                      key={`network-${p.callId()}-${e.timestamp}-${e.group}-${
                        e.name
                      }`}
                      event={e}
                      participant={p}
                      range={range}
                      conferenceDuration={conferenceDuration}
                      onClick={(evt) => this.handleParticipantPopover(evt, p)}
                    />
                  ))
              : [];

            const timelineEvents = events
              ? events
                  .filter(
                    (e) =>
                      e.level === 'ERROR' ||
                      e.level === 'WARNING' ||
                      e.name === 'muted' ||
                      e.name === 'unmuted'
                  )
                  .map((e) => (
                    <TimelineEvent
                      key={`event-${p.callId()}-${e.timestamp}-${e.group}-${
                        e.name
                      }`}
                      event={e}
                      callId={p.callId()}
                      range={range}
                      conferenceDuration={conferenceDuration}
                    />
                  ))
              : [];

            return [
              <TimelineCall
                key={p.id}
                participant={p}
                range={range}
                conferenceDuration={conferenceDuration}
                onClick={
                  p.url()
                    ? (evt) => this.handleParticipantPopover(evt, p)
                    : undefined
                }
              />,
              ...timelineEvents,
              ...timelineNetworkEvents,
            ];
          })}

          {cursorPosition > -1 && (
            <div className={s.cursor} style={{ left: cursorPosition }}>
              {formatSeconds(cursorSecond || 0)}
            </div>
          )}
        </div>

        {selectedParticipant && (
          <TimelinePopover
            open={!!callAnchorElement}
            carrier={carrier}
            anchorEl={callAnchorElement}
            participant={selectedParticipant}
            range={range}
            onClose={this.handleCallClose}
          />
        )}
      </div>
    );
  }
}
Timeline = Timeline;

export default Timeline;
