import { useState, useEffect, useRef } from 'react';
import { createAudioMeter } from './audioMeter';
import { useApp } from '../../hooks/useAppContext';

export default function useDetectVoice(minVolume = 0.3) {
  const { voipCarrier } = useApp();

  const [talking, setTalking] = useState(null);
  const [volume, setVolume] = useState(0);
  const [error, setError] = useState(null);
  const [deviceId, setDeviceId] = useState(
    (voipCarrier && voipCarrier.inputDeviceSelected()) || 'default'
  );
  const meter = useRef();
  const rafID = useRef();
  const streamRef = useRef();

  const shutdownIfRunning = () => {
    if (meter.current) {
      meter.current.shutdown();
    }

    if (streamRef.current) {
      streamRef.current.getTracks().forEach((t) => t.stop());
    }
  };

  const onMicrophoneGranted = (stream) => {
    const AudioContext = window.AudioContext || window.webkitAudioContext;

    // Always shutdown previous mediaStream
    shutdownIfRunning();

    const audioContext = new AudioContext();

    // Limit on audioContext running
    if (!audioContext) return;

    // Create an AudioNode from the stream.
    streamRef.current = stream;
    const mediaStreamSource = audioContext.createMediaStreamSource(stream);
    // Create a new volume meter and connect it.
    meter.current = createAudioMeter(audioContext);
    mediaStreamSource.connect(meter.current);

    // Trigger callback that shows the level of the "Volume Meter"
    onLevelChange();
  };

  const onLevelChange = () => {
    // 2.1 allows to have a good reference between 0.0 and 1.0 volume.
    const newVolume = meter.current.volume * 2.1;
    setVolume(newVolume);
    setTalking(newVolume >= minVolume);

    // set up the next visual callback
    rafID.current = window.requestAnimationFrame(onLevelChange);
  };

  useEffect(() => {
    if (voipCarrier) {
      voipCarrier.on('inputDeviceSelected', setDeviceId);
      return () => {
        voipCarrier.removeListener('inputDeviceSelected', setDeviceId);
        shutdownIfRunning();
      };
    }
  }, []);

  useEffect(() => {
    // Ask for an audio input
    navigator.mediaDevices
      .getUserMedia({
        audio: {
          deviceId,
        },
        video: false,
      })
      .then(onMicrophoneGranted)
      .catch(setError);
  }, [deviceId]);

  return [talking, volume, error];
}
