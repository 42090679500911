import React, { PureComponent, useState } from 'react';
import { connect } from 'react-redux';
import { Button as MaterialButton } from '@mui/material';
import MaterialIcon from '../Icon/MaterialIcon';
import { notify, popup } from '../../actions/ui';
import { track } from '../../actions/tracking';
import { createChannel } from '../../actions/messaging';
import history from '../../core/history';
import { promoPopup } from './promo';
import { useNavigate } from 'react-router-dom';

const CREATE_CHANNEL_AUTH_ERROR =
  'GraphQL Error: basic accounts cannot create messaging channels';

const SendMessageButton = (props) => {
  const navigate = useNavigate();
  const [creating, setCreating] = useState(false);

  const { viewer, userId, notify, createChannel, popup, track, ...other } =
    props;

  const handleSendMessage = () => {
    const { viewer, userId, notify, createChannel, track, popup } = props;

    setCreating(true);
    createChannel([viewer.id, userId])
      .then((channelId) => {
        navigate(`/messaging/${channelId}?channel=true`);
      })
      .catch((err) => {
        setCreating(false);

        if (err.message === CREATE_CHANNEL_AUTH_ERROR) {
          return promoPopup(popup, track);
        }

        notify('An error occurred when starting chat with user.', 'error');
        throw err;
      });
  };

  return (
    <MaterialButton
      size="small"
      startIcon={<MaterialIcon icon="chat" />}
      onClick={handleSendMessage}
      disabled={creating}
      {...other}
    >
      Send Message
    </MaterialButton>
  );
};

export default connect(
  (state) => ({
    viewer: state.viewer,
  }),
  {
    popup,
    notify,
    track,
    createChannel,
  }
)(SendMessageButton);
