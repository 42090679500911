import {
  getPayoutAccountQuery,
  createPayoutLoginURLQuery,
  createPayoutAccountURLQuery,
} from '../constants/queries';

/**
 * @param {number} userId
 * @returns {Promise<{ payouts_enabled: boolean }>} payoutAccount
 */
export function payoutAccountByUserID(userId) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.send(getPayoutAccountQuery, { userId });
    return result.payoutAccountByUserID;
  };
}

/**
 * @param {number} userId
 * @returns {Promise<string>} payoutLoginUrl
 */
export function createPayoutLoginURLByUser(userId) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.query(createPayoutLoginURLQuery, { userId });
    return result.createPayoutLoginURL;
  };
}

/**
 * @param {number} userId
 * @param {string} type
 * @returns {Promise<string>} payoutLoginUrl
 */
export function createPayoutAccountURLByUserAndType(userId, type) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.query(createPayoutAccountURLQuery, {
      userId,
      type,
    });
    return result.createPayoutAccountURL;
  };
}
