import ActionTypes from '../core/ActionTypes';
import { add, removeAt } from './utils';

const initialState = {
  notifications: [],
  lastNotification: undefined,
  userContext: 'client',
  userContextOptions: [],
  lastUserIdViewed: null,
};

export default function ui(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.UI__SHOW_MESSAGE:
      return {
        ...state,
        notifications: add(state.notifications, {
          message: action.message,
          messageType: action.messageType,
          duration: action.duration,
        }),
      };
    case ActionTypes.UI__HIDE_MESSAGE:
      return {
        ...state,
        notifications: removeAt(state.notifications, 0),
        lastNotification: state.notifications[0],
      };
    case ActionTypes.UI__SHOW_POPUP:
      return {
        ...state,
        popup: action,
      };
    case ActionTypes.UI__HIDE_POPUP:
      return {
        ...state,
        popup: null,
      };
    case ActionTypes.UI__SET_USER_CONTEXT:
      return {
        ...state,
        userContext: action.userContext,
        userContextOptions: action.userContextOptions,
      };
    case ActionTypes.UI__SAVE_USER_VIEW:
      return {
        ...state,
        lastUserIdViewed: action.userId,
      };
    default:
      return state;
  }
}
