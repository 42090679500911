import React from 'react';
import SuccessStories from './SuccessStories';
import { fetchCaseStudy } from '../../actions/caseStudy';

const featuredCases = [
  'payroll-lending',
  'solar-energy',
  'renewable-energy',
  'animal-feed-in-nigeria',
  'ict-in-angola',
  'preventative-healthcare',
  'off-grid-electricity',
];

export default {
  path: '/success-stories',
  element: <SuccessStories />,
  async action({ store }) {
    document.title = 'Success Stories';
    return await Promise.all(
      featuredCases.map((id) => store.dispatch(fetchCaseStudy(id)))
    );
  },
};
