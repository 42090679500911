import React from 'react';
import EmptyMessage from '../../components/EmptyMessage';
import AuthPage from '../../components/AuthPage';
import { urlToInternalPath } from '../../core/util';
import { useLoaderData } from 'react-router-dom';

const ExpertAuthPage = () => {
  const loaderProps = useLoaderData();
  if (!loaderProps) return null;

  const { expertRequest, signup, tags } = loaderProps;

  return (
    <AuthPage
      signupType="expert"
      nextUrl={urlToInternalPath(expertRequest.public_html_url)}
      signup={signup}
      signupLink={`/expert_request/${expertRequest.slug}/refer/signup`}
      signinLink={`/expert_request/${expertRequest.slug}/refer/signin`}
      tags={tags}
    >
      <EmptyMessage
        border={false}
        style={{ padding: 15 }}
        title="Refer friends for paid calls on OnFrontiers."
        body="You’ll receive a $100 bonus to your account once they book a call."
      />
    </AuthPage>
  );
};

export default ExpertAuthPage;
