import React from 'react';
import { redirect } from 'react-router-dom';
import { WrapperComponent } from '../../components/WrapperComponent';
import Admin from './Admin';

export default {
  path: '/admin/:model?/:id?',
  element: <WrapperComponent />,
  async action({ store, params }) {
    const { viewer } = store.getState();
    if (!viewer.admin) return redirect('/404');

    const { model, id } = params;

    if (!model) {
      return redirect('/admin/landing_pages');
    }

    document.title = 'Admin';
    return <Admin model={model} id={id} />;
  },
};
