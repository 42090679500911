import React from 'react';
import ProfileConflicts from './ProfileConflicts';
import { fetchConflicts } from '../../actions/profile';
import { redirect } from 'react-router-dom';

export default {
  path: '/profile-conflicts',
  element: <ProfileConflicts />,
  async action({ store }) {
    const { viewer } = store.getState();
    if (!viewer.admin) return redirect('/404');

    store.dispatch(fetchConflicts());

    document.title = 'Profile Merge Conflicts';
    return null;
  },
};
