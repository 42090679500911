import config from '../../../config';
import { policies } from '../../core/agreements';
import { redirect } from 'react-router-dom';

export default {
  path: '/legal/:slug?',

  async action({ params }) {
    const { slug } = policies?.[params.slug || 'terms-of-use'];
    return redirect(`${config.mainSiteUrl}/${slug}/`);
  },
};
