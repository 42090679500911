import React from 'react';
import { redirect } from 'react-router-dom';
import { fetchCountries } from '../../actions/country';
import { fetchExpertRequests } from '../../actions/expertRequest';
import { fetchAllGroups } from '../../actions/group';
import { fetchProfile } from '../../actions/profile';
import { fetchSectors } from '../../actions/sector';
import LayoutPage from '../../components/Layout/LayoutPage';
import { WrapperComponent } from '../../components/WrapperComponent';
import ProfileMerge from './ProfileMerge';

export default {
  path: '/merge/:destProfileUrlEndpoint/:srcProfileUrlEndpoint',
  element: <WrapperComponent />,
  async action({ store, params }) {
    const { viewer } = store.getState();
    if (!viewer.admin) return redirect('/404');

    const [destProfile, srcProfile] = await Promise.all([
      store.dispatch(
        fetchProfile(params.destProfileUrlEndpoint, {
          leadOnly: false,
          internalNetworks: true,
          expertRequestCandidates: true,
          experiences: true,
          addresses: true,
          expertise: true,
          education: true,
          sources: true,
          rawPictureUrl: true,
        })
      ),
      store.dispatch(
        fetchProfile(params.srcProfileUrlEndpoint, {
          leadOnly: true,
          internalNetworks: true,
          expertRequestCandidates: true,
          experiences: true,
          addresses: true,
          education: true,
          sources: true,
          rawPictureUrl: true,
        })
      ),
      store.dispatch(fetchCountries()),
      store.dispatch(fetchSectors()),
      store.dispatch(fetchExpertRequests({ withGroupOnly: false })),
      store.dispatch(
        fetchAllGroups({
          collection: 'networks',
          internalNetwork: true,
        })
      ),
    ]);

    if (!destProfile || !srcProfile) return redirect('/404');

    document.title = 'Profile Merge';
    return (
      <LayoutPage showNav>
        <ProfileMerge existing={destProfile} conflicting={srcProfile} />
      </LayoutPage>
    );
  },
};
