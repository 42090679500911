import React from 'react';
import { redirect } from 'react-router-dom';
import { fetchCountries } from '../../actions/country';
import { fetchExpertRequest } from '../../actions/expertRequest';
import { createChannel } from '../../actions/messaging';
import { fetchPermissions } from '../../actions/permission';
import { fetchSectors } from '../../actions/sector';
import { track } from '../../actions/tracking';
import { fetchUser } from '../../actions/user';
import Layout from '../../components/Layout';
import Profile from '../../components/Profile';
import RequestConsultation from '../../components/RequestConsultation';
import { isBot, pathAndQuery } from '../../core/util';
import { WrapperComponent } from '../../components/WrapperComponent';

const CREATE_CHANNEL_AUTH_ERROR =
  'GraphQL Error: basic accounts cannot create messaging channels';

function getLinkedData(profile) {
  const exp = profile.experiences && profile.experiences.find((e) => e.current);
  const data = {
    '@context': 'http://schema.org',
    '@type': 'Person',
    name: profile.name,
    url: profile.html_url,
    jobTitle: profile.title,
    address: {
      '@type': 'PostalAddress',
      addressCountry: profile.country,
      addressLocality: profile.city,
    },
    worksFor: exp && {
      '@type': 'Organization',
      legalName: exp.company_name,
      address: exp.region,
    },
  };

  return data;
}

function trackProfileView(store, viewer, user, ua) {
  const { profile } = user;

  const isSelf = viewer.id === user.id;
  if (isSelf) return;

  if (viewer.id) {
    return store.dispatch(track('profile.view', profile.id, undefined, true));
  }

  return store.dispatch(
    track('profile.view', profile.id, { ua: ua?.string }, true)
  );
}

async function action(
  { params, store, query, location },
  { requestConsultation, messagingPromo }
) {
  const {
    viewer,
    ui: { userAgentParsed: ua },
  } = store.getState();

  const { username } = params;
  if (!username) {
    return viewer.html_url && redirect(viewer.html_url);
  }

  const anonymousRequest = !viewer.id && requestConsultation;
  if (anonymousRequest) {
    return redirect(`/login?next=${pathAndQuery(location)}`);
  }

  const [user] = await Promise.all([
    store.dispatch(
      fetchUser(params.username, {
        stats: true,
        groups: true,
        recruiter: true,
        audit: true,
        experiences: true,
        education: true,
        addresses: true,
        expertise: true,
        groupKeywords: true,
        sources: true,
        internalNetworks: true,
        otpAuthEnabled: viewer.admin,
        availableConsultation: true,
      })
    ),
    store.dispatch(fetchCountries()),
    store.dispatch(fetchSectors()),
  ]);

  if (!user) return redirect('/404');

  const self = viewer.id === user.id;
  const { profile } = user;

  const [updatePerm] = await store.dispatch(
    fetchPermissions(viewer.id, [
      { service: 'profile', action: 'update', resource: profile.id },
      {
        service: 'group_member',
        action: 'update_group_keywords',
        resource: profile.id,
      },
      { service: 'promo', action: 'see_start_chat', resource: user.id },
      {
        service: 'messaging',
        action: 'start_non_anonymous_chat',
        resource: user.id,
      },
    ])
  );

  const expertRequest =
    query?.expertRequestId &&
    (await store.dispatch(fetchExpertRequest(query.expertRequestId)));

  const bot = isBot(ua);

  // must await to add tracking response to the redux store and prevent
  // client/server duplication, and ignore bots
  if (!bot) {
    await trackProfileView(store, viewer, user, ua);
  }

  // const linkedData = getLinkedData(profile);

  document.title = `${profile.name} — OnFrontiers Expert`;
  return (
    <Layout showNav selected="profile">
      <Profile
        userId={user.id}
        profileId={profile.id}
        editable={updatePerm.allowed}
        self={self}
        expertRequestId={query.expertRequestId}
        openedDialog={query.dialog}
        messagingPromo={messagingPromo}
      />
      {viewer.id && requestConsultation && (
        <RequestConsultation
          open={requestConsultation}
          expertId={user.id}
          profile={profile}
          expertRequest={expertRequest}
          returnTo={`/profile/${profile.url_endpoint}`}
        />
      )}
    </Layout>
  );
}

const path = '/profile';

export default {
  path: '/profile',
  children: [
    {
      path: `${path}/:username?`,
      element: <WrapperComponent />,
      async action(context) {
        return action(context, { requestConsultation: false });
      },
    },
    {
      path: `${path}/:username/request`,
      element: <WrapperComponent />,
      async action(context) {
        return action(context, { requestConsultation: true });
      },
    },
    {
      path: `${path}/:username/message`,
      element: <WrapperComponent />,
      async action(context) {
        const { store, params } = context;
        const { viewer } = store.getState();
        const { username } = params;

        if (!viewer.id) {
          return redirect(`/login?next=/profile/${username}/message`);
        }

        const user = await store.dispatch(fetchUser(username));

        try {
          const channelId = await store.dispatch(
            createChannel([viewer.id, user.id])
          );
          return redirect(`/messaging/${channelId}?channel=true`);
        } catch (err) {
          if (err.message === CREATE_CHANNEL_AUTH_ERROR) {
            return action(context, { messagingPromo: true });
          }
          Promise.reject(err);
        }
      },
    },
  ],
};
