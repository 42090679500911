import React from 'react';
import { connect } from 'react-redux';
import FAIcon from '../Icon/FAIcon';
import Button from '../Button';
import EmptyMessage from '../EmptyMessage';
import { darkGreen } from '../../core/colors';
import { track } from '../../actions/tracking';

class TeamAccountPromo extends React.Component {
  handleClick = () => {
    const {
      onContactSales,
      clickEventTrack = 'promo.click.team_account.expert_request',
      track,
    } = this.props;

    if (clickEventTrack) track(clickEventTrack);

    window.Intercom('show');

    if (onContactSales) onContactSales();
  };

  render() {
    const { iconProps, buttonProps } = this.props;

    return (
      <EmptyMessage
        border={false}
        icon={
          <FAIcon
            iconSet="fas"
            icon="users-class"
            size={40}
            color={darkGreen}
            {...iconProps}
          />
        }
        title="Link a team billing account to proceed"
        style={{ margin: '0 auto' }}
        body={
          <React.Fragment>
            We no longer support individual billing accounts.
            <br />
            Contact our sales team and we’ll get you set up.
          </React.Fragment>
        }
        action={
          <Button onClick={this.handleClick} {...buttonProps}>
            Contact Sales
          </Button>
        }
        {...this.props}
      />
    );
  }
}

TeamAccountPromo = connect(undefined, {
  track,
})(TeamAccountPromo);

export default TeamAccountPromo;
