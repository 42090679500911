import React from 'react';
import { redirect } from 'react-router-dom';
import { fetchSignupDomain } from '../../actions/domain';
import { acceptInvitation } from '../../actions/invitation';
import { validateToken } from '../../actions/token';
import { fetchUser } from '../../actions/user';
import Layout from '../../components/Layout';
import LayoutPage from '../../components/Layout/LayoutPage';
import { marketingLinks } from '../../components/MarketingLayout/marketingLinks';
import Signup from '../../components/Signup';
import { WrapperComponent } from '../../components/WrapperComponent';
import { rewriteSubdomainUrl } from '../../core/domain';
import { queryPart } from '../../core/util';
import { getJoinTitle } from '../../reducers/utils';
import BodyContainer from './BodyContainer';
import SelectSignupType from './SelectSignupType';

const TOKEN_EXPIRED_ERROR = 'GraphQL Error: expired token';
const APPLICATION_PATH = '/signup/expert/basic-information';

const clientLongAboutText = `In today's intricate knowledge economy, standing out requires an edge. OnFrontiers understands this intricacy and offers professionals a unique opportunity to connect with a diverse pool of industry leaders and experts from around the globe. Our platform serves as a bridge, eliminating the distance between a question and its expert answer. Whether you're looking for insights on a specific topic or seeking a long-term expert partnership, OnFrontiers has been designed with your needs in mind.

Our commitment isn't just about facilitating connections. At the heart of OnFrontiers is a dedication to privacy, robust compliance checks, and a user-friendly experience that transforms past interactions into a treasure trove of knowledge. This dedication to excellence is reflected in our collaboration with giants like PWC, CACI, and IFC, attesting to our platform's trusted and proven capabilities.

Positioned at the nexus of professional networking and expertise sourcing, OnFrontiers stands ready to amplify your strategies and projects.

<b>Here’s what we’re hearing from our customers:</b>

<i>“OnFrontiers was able to identify Chadian health supply chain experts that are also from Chad, which speaks volumes about their services and ability to find local expertise.”</i>
 - James (Technical Researcher at Chemonics)

<i>“The expert's recent work on justice reform issues in Uzbekistan was really helpful to us as we began our capture efforts. On a 45 minute call he was able to quickly orient us on the key issues and reform actors, which allowed us to move forward efficiently with our planning.”</i>
 - Jason (Senior Director at Tetra Tech)
`;

export default {
  subdomain: '*',
  path: '/:subdomain?/signup/:type?',
  element: <WrapperComponent />,
  async action({ store, query, path, params, subdomain }) {
    if (subdomain) {
      return redirect(rewriteSubdomainUrl(path, subdomain, query));
    }

    const domain = await store.dispatch(fetchSignupDomain(params.subdomain));
    if (params.subdomain && !domain) return redirect('/404');

    const { stepPath = '', type } = params;
    const { method, invite, t: tags, next, email } = query;
    const { viewer } = store.getState();
    const defaultRedirect = query.next || '/dashboard';

    const links = marketingLinks({
      showLogin: !domain,
      domain: params.subdomain,
    });

    if (!type) {
      if (viewer.id) {
        return redirect(defaultRedirect);
      }

      document.title = 'Create OnFrontiers account';
      return (
        <LayoutPage headerBorder={false} marketingLinks={links}>
          <SelectSignupType subdomain={params.subdomain} nextUrl={next} />
        </LayoutPage>
      );
    }

    if (invite) {
      try {
        await store.dispatch(validateToken(invite));
      } catch (e) {
        const error =
          e.message === TOKEN_EXPIRED_ERROR
            ? 'Invitation has expired'
            : 'Invitation is not valid';

        return redirect('/login');
      }
    }

    if (viewer.id && invite) {
      try {
        const redirectTo = await store.dispatch(acceptInvitation(invite));
        if (redirectTo) {
          return redirect(redirectTo);
        }
        return redirect(defaultRedirect);
      } catch (e) {
        return redirect(defaultRedirect);
      }
    }

    const defaultEmail = email && decodeURIComponent(email);

    switch (type) {
      case 'member':
        return signupMember({
          stepPath,
          method,
          invite,
          domain,
          tags,
          next,
          viewer,
          defaultEmail,
          query,
          links,
          store,
        });
      case 'expert':
        const nextUrl = next ?? APPLICATION_PATH;

        if (domain) {
          return joinExpert({
            invite,
            domain,
            tags,
            next: nextUrl,
            viewer,
            store,
            defaultEmail,
            links,
          });
        }

        return signupExpert({
          invite,
          tags,
          next: nextUrl,
          viewer,
          store,
          defaultEmail,
          links,
        });
      default:
        return redirect('/signup');
    }
  },
};

async function signupMember({
  stepPath,
  invite,
  domain,
  tags,
  next,
  viewer,
  defaultEmail,
  query,
  links,
}) {
  // if (!invite && !domain) {
  //   return {
  //     redirect: `/select_domain${queryPart(query)}`,
  //   };
  // }

  // Path should be empty, redirect to correct path
  if (stepPath) {
    return redirect(`/signup/member${queryPart({ next, invite })}`);
  }

  // User already logged in should not land on this page.
  // Redirect to dashboard
  if (viewer.id) {
    return redirect(`/dashboard`);
  }

  const title = 'Join your team at OnFrontiers';
  document.title = title;
  return (
    <LayoutPage headerBorder={false} marketingLinks={links}>
      <Signup
        signupType="client"
        domain={domain}
        title={title}
        shortAboutText={domain && domain.member_signup_about_mobile}
        longAboutText={
          (domain && domain.member_signup_about) || clientLongAboutText
        }
        tags={tags}
        viewer={viewer}
        invite={invite}
        defaultEmail={defaultEmail}
      />
    </LayoutPage>
  );
}

async function signupExpert({
  next,
  tags,
  invite,
  viewer,
  defaultEmail,
  links,
}) {
  const title = 'Join the OnFrontiers Expert Marketplace';
  document.title = title;

  // Go to application
  if (viewer.id) {
    return redirect(APPLICATION_PATH);
  }

  const onNext = () => redirect(next);

  return (
    <Layout
      hideSearch
      showNewRequest={false}
      headerBorder={false}
      marketingLinks={links}
    >
      <BodyContainer>
        <Signup
          viewer={viewer}
          nextUrl={next}
          onNext={onNext}
          defaultEmail={defaultEmail}
          signupType="expert"
          title={title}
          tags={tags}
          invite={invite}
        />
      </BodyContainer>
    </Layout>
  );
}

async function joinExpert({
  domain,
  next,
  tags,
  invite,
  viewer,
  store,
  defaultEmail,
  links,
}) {
  const title = getJoinTitle(domain);

  if (viewer.id) {
    viewer = await store.dispatch(
      fetchUser(viewer.username, {
        signupSubdomain: true,
        internalNetworks: true,
      })
    );
  }

  const onNext = () => redirect(next);

  document.title = title;
  return (
    <Layout
      hideSearch
      showNewRequest={false}
      headerBorder={false}
      marketingLinks={links}
    >
      <BodyContainer>
        <Signup
          viewer={viewer}
          domain={domain}
          nextUrl={next}
          onNext={onNext}
          defaultEmail={defaultEmail}
          signupType="expert"
          title={title}
          shortAboutText={domain.expert_signup_about_mobile}
          longAboutText={domain.expert_signup_about}
          tags={tags}
          invite={invite}
          signinNextUrl={`/${domain.subdomain}/signup/expert`}
        />
      </BodyContainer>
    </Layout>
  );
}
