import React from 'react';
import { fetchProfile } from '../../actions/profile';
import { WrapperComponent } from '../../components/WrapperComponent';
import Platform from './Platform';

const featuredExpertIds = ['lmccarthy', 'afataki', 'fliu', 'mfischetti'];

export default {
  path: '/platform',
  element: <WrapperComponent />,
  async action({ store }) {
    const profiles = await Promise.all(
      featuredExpertIds.map((urlEndpoint) =>
        store.dispatch(fetchProfile(urlEndpoint))
      )
    );

    document.title = 'OnFrontiers';
    return <Platform profiles={profiles.filter(Boolean)} />;
  },
};
