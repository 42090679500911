import { loginAs } from '../../core/login';
export default {
  subdomain: '*',
  path: '/loginas',

  async action({ graphqlClient, query }) {
    const { next, user } = query;

    await loginAs(graphqlClient, user, next);
  },
};
