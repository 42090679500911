import { redirect } from 'react-router-dom';
import { fetchPermissions } from '../actions/permission';
import agreements from '../core/agreements';
import { hasAcceptedEmail } from '../core/user';
import { pathAndQuery } from '../core/util';
import { fetchUnreviewed } from '../actions/consultation';

export function loginRequired(route) {
  if (route.public) return route;

  return {
    ...route,
    async action(...args) {
      const [context] = args || [];
      const location = new URL(context.request.url);
      const { viewer } = context.store.getState();

      if (!viewer.id) {
        return redirect(`/login?next=${pathAndQuery(location)}`);
      }

      if (viewer.password_expiry && viewer.password_expiry.expired) {
        return redirect(`/change_password?next=${pathAndQuery(location)}`);
      }

      await context.store.dispatch(fetchUnreviewed());

      return route.action(...args);
    },
  };
}

export function redirectIfAgreementsNotAccepted(store, location) {
  const { viewer } = store.getState();

  if (viewer.id) {
    const agreement = agreements(viewer.agreements);
    const isExpert = viewer.signup_type === 'expert';

    if (!agreement.hasAccepted('terms-of-use', 'privacy')) {
      return redirect(`/legal_ack/privacy?next=${pathAndQuery(location)}`);
    }

    if (isExpert && !agreement.hasAccepted('expert-participation-agreement')) {
      return redirect(
        `/legal_ack/expert-participation-agreement?next=${pathAndQuery(
          location
        )}`
      );
    }
  }
}

export function agreementsRequired(route) {
  return {
    ...route,

    async action(...args) {
      const [context] = args || [];
      const location = new URL(context.request.url);

      return (
        redirectIfAgreementsNotAccepted(context.store, location) ||
        route.action(...args)
      );
    },
  };
}

export function redirectIfEmailNotVerified(store) {
  const { viewer } = store.getState();

  if (viewer.id && !hasAcceptedEmail(viewer)) {
    return redirect('/awaiting_email_validation');
  }
}

export function verifiedEmailRequired(route) {
  return {
    ...route,

    async action(...args) {
      const [context] = args || [];

      return redirectIfEmailNotVerified(context.store) || route.action(...args);
    },
  };
}

export function superAdminRequired(route) {
  return {
    ...route,

    async action(...args) {
      const [context] = args || [];
      const { viewer } = context.store.getState();

      if (!viewer.id) {
        return redirect('/');
      }

      const [canViewAdminPanel] = await context.store.dispatch(
        fetchPermissions(viewer.id, [
          { service: 'profile', action: 'super_admin', resource: viewer.id },
        ])
      );

      if (!canViewAdminPanel.allowed) {
        return redirect('/');
      }

      return route.action(...args);
    },
  };
}

export function allRequired(route) {
  return loginRequired(agreementsRequired(verifiedEmailRequired(route)));
}
