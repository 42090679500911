import React from 'react';
import { fetchStarting } from '../../actions/consultation';
import { fetchGroups } from '../../actions/group';
import ConsultationsStarting from '../../components/ConsultationsStarting';
import LayoutPage from '../../components/Layout/LayoutPage';
import { WrapperComponent } from '../../components/WrapperComponent';
import TeamList from './TeamList';

export default {
  path: '/teams',
  element: <WrapperComponent />,
  async action({ store }) {
    const { viewer } = store.getState();
    await Promise.all([
      store.dispatch(
        fetchGroups({
          force: true,
          collection: 'default',
          memberOnly: !viewer.admin,
          memberLimit: 5,
          billingAccount: true,
          stats: true,
        })
      ),
      store.dispatch(fetchStarting()),
    ]).catch((e) => {
      if (!e.isPermissionError) throw e;
    });

    document.title = 'Teams';
    return (
      <LayoutPage showNav selected="teams">
        <ConsultationsStarting />
        <TeamList />
      </LayoutPage>
    );
  },
};
