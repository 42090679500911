import React from 'react';
import About from './About';
import { fetchAboutPageSections } from '../../actions/about';

export default {
  path: '/about',
  element: <About />,
  async action({ store }) {
    document.title = 'About Us';
    return await store.dispatch(fetchAboutPageSections());
  },
};
