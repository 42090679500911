import React, { useState } from 'react';
import { connect } from 'react-redux';
import EditIcon from '../../../components/EditIcon';
import EditExternalConsultation from '../../../components/EditExternalConsultation';
import { fetchExpertRequests } from '../../../actions/expertRequest';

function Title({
  consultation,
  isViewerExpert,
  isViewerRequester,
  fetchExpertRequests,
  requesterName,
  expertName,
  isWrittenConsultation,
}) {
  const { external } = consultation;
  const userName = isViewerExpert ? requesterName : expertName;
  const [editConsultation, setEditConsultation] = useState(false);

  const openEditConsultation = () => {
    fetchExpertRequests({ withGroupOnly: false }).then(() =>
      setEditConsultation(true)
    );
  };

  const closeEditConsultation = () => {
    setEditConsultation(false);
  };

  const handleEditSubmit = () => {
    setEditConsultation(false);
  };

  const target = isWrittenConsultation ? 'Consultation' : 'Call';

  return (
    <>
      <span>
        {external || isViewerRequester
          ? `${target} with ${userName}`
          : isViewerExpert
            ? `${userName} requested a ${target.toLowerCase()} with you`
            : `${requesterName} requested a ${target.toLowerCase()} with ${expertName}`}
      </span>
      {external && (
        <EditIcon onClick={openEditConsultation} style={{ marginLeft: 10 }} />
      )}

      {external && (
        <EditExternalConsultation
          open={editConsultation}
          consultation={consultation}
          initialValues={{
            ...consultation,
            expert_request_id:
              consultation.expert_request && consultation.expert_request.id,
            started_at: new Date(consultation.started_at),
            recording_url: consultation.original_recording_url,
          }}
          onSubmit={handleEditSubmit}
          onClose={closeEditConsultation}
        />
      )}
    </>
  );
}

Title = connect(undefined, {
  fetchExpertRequests,
})(Title);

export default Title;
