import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ProfileTitle from '../ProfileTitle';
import ConsultationFields from './Fields';
import WrittenEngagementFields from '../RequestWrittenEngagement/Fields';
import LegalNotes from './LegalNotes';
import Actions from './Actions';
import {
  fetchExpertRequests,
  fetchExpertRequestMembers,
  fetchCandidate,
  isFixedRate as isERFixedRate,
  isSupportedType,
} from '../../actions/expertRequest';
import { fetchAllGroups } from '../../actions/group';
import {
  engagementTypes,
  fetchPredictTransactions,
  validateExpertConsultationPreferences,
  isFixedRate as isConsultationFixedRate,
} from '../../actions/consultation';
import { money } from '../../core/money';

import { ERR_OUT_OF_MARKETPLACE, MSG_OUT_OF_MARKETPLACE } from './constants';
import s from './RequestConsultation.module.scss';
import config from '../../../config';

const useStyles = makeStyles({
  paper: {
    padding: 24,
  },
  paperWidthSm: {
    maxWidth: 640,
  },
  paperFullScreen: {
    boxSizing: 'border-box',
  },
});

function RequestConsultationDialog({
  // Props
  expertId,
  profile,
  open,
  onClose,
  expertRequest: initialExpertRequest,
  getEngagementType,

  // Redux State
  viewer,
  expertRequests,
  groups,
  popupOpen,

  // Redux Actions
  fetchExpertRequests,
  fetchCandidate,
  fetchAllGroups,
  fetchExpertRequestMembers,
  fetchPredictTransactions,
  validateExpertConsultationPreferences,

  // Final Form
  form,
  values,
  submitting,
  handleSubmit,
  initialValues,
}) {
  if (viewer.groups && viewer.groups.length === 1) {
    // if the viewer is associated with exactly 1 group, then simply
    // pre-select it for them (as it would be their only option),
    // and the "Add to team" dropdown will be hidden.
    values.group_id = viewer.groups[0].id;
  }

  // State
  const [expertRequest, setExpertRequest] = useState(initialExpertRequest);
  const [candidate, setCandidate] = useState();
  const [isFixedRate, setIsFixedRate] = useState(false);
  const [expertCredits, setExpertCredits] = useState();
  const type = getEngagementType();

  // Form Values
  const {
    expert_request_id: expertRequestId,
    group_id: groupId,
    booking_fee: bookingFee,
    engagement_type: engagementType,
    duration,
  } = values;

  // Hooks
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();

  const creditRate = useMemo(
    () => (candidate && candidate.credit_rate) || profile.credit_rate,
    [candidate, profile]
  );

  const enableOpportunityCall = useMemo(
    () => config.enableOpportunityCallAccountIds.includes(groupId),
    [groupId]
  );

  useEffect(() => {
    form.change('engagement_type', getEngagementType());
  }, [expertRequestId]);

  // Initial fetch
  useEffect(() => {
    if (!open) return;
    // If there is no initial ER or that ER is a deprecated type, fetch
    // ERs to allow the user to attach the consultation to an existing ER.
    if (
      !initialExpertRequest ||
      !isSupportedType(initialExpertRequest.er_type)
    ) {
      fetchExpertRequests({ state: 'open' });
    }
    fetchAllGroups();
  }, [open]);

  // Fetch expert request
  useEffect(() => {
    if (!open) return;

    (async function () {
      let request = expertRequest;
      if (!initialExpertRequest) {
        request = expertRequestId
          ? await fetchExpertRequestMembers(expertRequestId)
          : null;
        setExpertRequest(request);
      }

      if (request) {
        form.change('group_id', request.project?.group?.id);
        form.change('disclosure', request.disclosure);
        form.change('tracking_code', request.project?.tracking_code);
        form.change('engagement_type', getEngagementType(request));
      } else {
        form.change('group_id', initialValues.group_id);
        form.change('tracking_code', initialValues.tracking_code);
      }
    })();
  }, [open, expertRequestId]);

  // Fetch candidate
  useEffect(() => {
    if (!open) return;

    (async function () {
      const candidate = expertRequestId
        ? await fetchCandidate(expertRequestId, profile.id)
        : null;

      setCandidate(candidate);
    })();
  }, [open, expertRequest]);

  useEffect(() => {
    if (!open) return;

    // Fetch booking fee
    if (expertRequestId || groupId) {
      (async function () {
        const predictTransactions = await fetchPredictTransactions(
          expertId,
          expertRequestId,
          groupId,
          engagementType,
          duration
        );
        form.change(
          'booking_fee',
          money(predictTransactions.booking_fee).cents || 0
        );
        setExpertCredits(
          money(predictTransactions.client_expert_fee).cents || 0
        );
      })();
    }

    // Validate expert's preferences
    (async function () {
      if (!groupId) return;

      let warning;
      let error;
      try {
        await validateExpertConsultationPreferences({
          expertId,
          engagementType,
          expertRequestId,
          groupId,
        });
      } catch (err) {
        if (err.message !== ERR_OUT_OF_MARKETPLACE) {
          throw err;
        }
        if (viewer.admin) {
          warning = MSG_OUT_OF_MARKETPLACE;
        } else {
          error = MSG_OUT_OF_MARKETPLACE;
        }
      } finally {
        form.mutators.setFieldData('group_id', { warning });
        form.change('marketplace_error', error);
      }
    })();
  }, [open, groupId, engagementType, expertRequestId, duration]);

  useEffect(() => {
    setIsFixedRate(
      isConsultationFixedRate(engagementType) ||
        isERFixedRate(expertRequest?.er_type)
    );
  }, [open, expertRequest, engagementType]);

  const isWrittenEngagement = type === engagementTypes.writtenResponse;
  const Fields = isWrittenEngagement
    ? WrittenEngagementFields
    : ConsultationFields;
  return (
    <Dialog
      open={open && !popupOpen}
      scroll="body"
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      classes={{
        paper: classes.paper,
        paperWidthSm: classes.paperWidthSm,
        paperFullScreen: classes.paperFullScreen,
      }}
    >
      <ProfileTitle
        profile={profile}
        requestLabel={
          isWrittenEngagement ? 'Request Written Engagement' : 'Request Call'
        }
      />

      <div
        className={s.message}
        style={enableOpportunityCall ? {} : { marginBottom: 0 }}
      >
        If {profile.first_name} accepts,{' '}
        {isWrittenEngagement
          ? 'you can instantly start sharing documents.'
          : 'your call will be instantly confirmed.'}
      </div>

      <form onSubmit={handleSubmit}>
        <Fields
          viewer={viewer}
          profile={profile}
          expertRequests={expertRequests}
          groups={groups}
          groupId={groupId}
          enableOpportunityCall={enableOpportunityCall}
          expertRequest={expertRequest}
          engagementType={engagementType}
          isFixedRate={isFixedRate}
          creditRate={creditRate}
          form={form}
        />

        <LegalNotes
          showEngagementAgreement={
            candidate ? candidate.show_engagement_agreement : true
          }
          bookingFee={bookingFee}
        />

        <Actions
          bookingFee={bookingFee}
          expertCredits={expertCredits}
          submitting={submitting}
          onCancel={onClose}
        />
      </form>
    </Dialog>
  );
}

RequestConsultationDialog = connect(
  (state) => ({
    viewer: state.viewer,
    expertRequests: state.expertRequests.open,
    groups: state.groups.all,
    popupOpen: !!state.ui.popup,
  }),
  {
    fetchExpertRequests,
    fetchCandidate,
    fetchAllGroups,
    fetchExpertRequestMembers,
    fetchPredictTransactions,
    validateExpertConsultationPreferences,
  }
)(RequestConsultationDialog);

RequestConsultationDialog = RequestConsultationDialog;

export default RequestConsultationDialog;
