import isEqual from 'lodash.isequal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchPermissions } from '../../../actions/permission';
import {
  fetchSiteSettings,
  updateSiteSettings,
} from '../../../actions/siteSettings';
import SettingsSection from '../../../components/SettingsSection';
import ModelForm from '../ModelForm';

function SiteSettings({
  viewer,
  id,
  model,
  fetchSiteSettings,
  fetchPermissions,
  updateSiteSettings,
}) {
  const navigate = useNavigate();
  const [siteSettings, setSiteSettings] = useState(undefined);
  useEffect(() => {
    async function loadSiteSettings() {
      const settings = await fetchSiteSettings();
      setSiteSettings(settings);
    }
    loadSiteSettings();
  }, []);

  const [readOnly, setReadOnly] = useState(undefined);
  useEffect(() => {
    async function loadReadOnly() {
      const [isSuperOwner] = await fetchPermissions(
        viewer.id,
        [
          { service: 'profile', action: 'super_owner', resource: 'dummy' },
        ].filter(Boolean)
      );
      setReadOnly(!isSuperOwner.allowed);
    }
    loadReadOnly();
  }, []);

  const carrierOptions = useMemo(
    () => [
      { value: 'twilio', label: 'Twilio' },
      { value: 'zoom', label: 'Zoom' },
    ],
    []
  );

  const carrierDescription = (
    <p>
      The Default Conference Carrier specifies the sitewide default carrier used
      by the API&apos;s conference service.
    </p>
  );

  const aboutPageTextDescription = (
    <p>
      The OnFrontiers About Page Text is presented in the&nbsp;
      <a href="/team/onfrontiers/about/" target="_blank" rel="noreferrer">
        about page
      </a>
      . This page is enabled and further configured via the&nbsp;
      <a
        href="/team/onfrontiers/settings/about_page/"
        target="_blank"
        rel="noreferrer"
      >
        team preferences.
      </a>
    </p>
  );

  const settingsSection = (
    <SettingsSection
      title="Site Settings"
      text=<div>
        <ul>
          <li>{aboutPageTextDescription}</li>

          <li>{carrierDescription}</li>
        </ul>
      </div>
    />
  );

  const editableFields = useMemo(
    () => [
      {
        name: 'group_about_page_onfrontiers_text',
        label: 'OnFrontiers About Page Text',
        type: 'textarea',
        required: true,
        maxLength: 10000,
        readOnly,
        columnWidth: 8,
      },
      {
        name: 'default_conference_carrier',
        type: 'select',
        required: true,
        options: carrierOptions,
        disabled: readOnly,
        getOptionLabel: (option) => option.label,
        getOptionValue: (option) => option.value,
        isOptionEqualToValue: (option, value) => isEqual(option, value),
      },
    ],
    [readOnly]
  );

  const handleSubmit = useCallback(async (values) => {
    await updateSiteSettings(values);
    navigate(`/admin/${model.path}/`);
  }, []);

  return siteSettings && readOnly !== undefined ? (
    <ModelForm
      title={settingsSection}
      id={id}
      model={model}
      onSubmit={handleSubmit}
      initialModel={siteSettings}
      fields={editableFields}
      readOnly={readOnly}
    />
  ) : null;
}

SiteSettings = connect(
  (state) => ({
    viewer: state.viewer,
  }),
  {
    fetchSiteSettings,
    fetchPermissions,
    updateSiteSettings,
  }
)(SiteSettings);

export default SiteSettings;
