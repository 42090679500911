import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import MarkdownIt from 'markdown-it';
import Layout from '../../components/MarketingLayout';
import MarketingSection from '../../components/MarketingSection';
import Button from '../../components/Button/Button';
import Link from '../../components/Link';
import { sand } from '../../core/colors';
import MediaQuery from '../../components/MediaQuery';
import Logo from '../../components/Logo';
import LongText from '../../components/LongText';
import { SCREEN_XS } from '../../constants';
import s from './TeamAboutPage.module.scss';
import { useLoaderData } from 'react-router-dom';

const md = new MarkdownIt();

const TeamAboutPage = (props) => {
  const { group } = useLoaderData();
  const { siteSettings } = props;
  const aboutPage = group.about_page;

  const bodyLogo = (
    <img
      alt="logo"
      className={s.aboutImage}
      src={aboutPage.body_logo_url}
      style={{ maxWidth: 250, maxHeight: 250 }}
    />
  );

  return (
    <Layout>
      <MarketingSection
        className={s.hero}
        bodyClassName={s.heroBody}
        padding={0}
      >
        <div className={s.splashTitle}>{group.name}</div>
        <div className={s.splashSubTitle}>
          Join our knowledge network on OnFrontiers
        </div>
      </MarketingSection>

      <MarketingSection className={s.aboutSection} bodyClassName={s.about}>
        {aboutPage.body_logo_url && (
          <div className={s.aboutLogo}>
            {aboutPage.logo_link_url ? (
              <Link to={aboutPage.logo_link_url}>{bodyLogo}</Link>
            ) : (
              bodyLogo
            )}
          </div>
        )}

        <div className={s.aboutText}>
          <LongText text={group.about} />

          {aboutPage.signup_url && (
            <MediaQuery maxWidth={SCREEN_XS}>
              {(isXs) => (
                <div className={s.aboutAction}>
                  <Link to={aboutPage.signup_url}>
                    <Button
                      size={isXs ? 'large' : 'normal'}
                      style={{
                        fontSize: 18,
                        ...(isXs ? { width: '100%' } : {}),
                      }}
                    >
                      Join Network
                    </Button>
                  </Link>
                </div>
              )}
            </MediaQuery>
          )}
        </div>
      </MarketingSection>

      <MarketingSection padding={60} style={{ backgroundColor: sand }}>
        <div className={s.aboutOF}>
          <div className={s.aboutOFImage}>
            <img alt="" src={require('./connections.svg')} />
          </div>
          <div className={s.aboutOFContent}>
            <Logo dark style={{ width: 320, height: 30 }} />

            <div
              dangerouslySetInnerHTML={{
                __html: md.render(
                  siteSettings.group_about_page_onfrontiers_text || ''
                ),
              }}
            />
          </div>
        </div>
      </MarketingSection>
    </Layout>
  );
};

export default connect((state) => ({
  siteSettings: state.siteSettings || {},
}))(TeamAboutPage);
