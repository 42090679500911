import React from 'react';
import Link from '../Link';
import MemberSettingsMenu from '../EditProjectMembers/SettingsMenu';
import MemberList from '../MemberList';
import MaterialIcon from '../Icon/MaterialIcon';
import { darkGreen } from '../../core/colors';
import s from './ProjectBar.module.scss';

function ProjectBar({ viewer, project, showSettings, members }) {
  const { group } = project;
  const trackingCode = project.tracking_code;
  const groupName = viewer.admin && group ? group.name : '';
  let titleSufix;
  if (groupName && trackingCode)
    titleSufix = ` (${groupName}: ${trackingCode})`;
  else if (groupName) titleSufix = ` (${groupName})`;
  else if (trackingCode) titleSufix = ` (${trackingCode})`;

  return (
    <div className={s.bar}>
      <div className={s.title}>
        <Link className={s.link} to={project.html_url}>
          <MaterialIcon
            color={darkGreen}
            className={s.icon}
            icon="arrow_back"
          />
          <span className={s.project}> Project:</span>
          {project.name}
          <span className={s.sufix}>{titleSufix}</span>
        </Link>
      </div>
      {}
      <div className={s.members}>
        {members && <MemberList members={members} />}
        {showSettings && <MemberSettingsMenu project={project} />}
      </div>
    </div>
  );
}

export default ProjectBar;
