import React from 'react';
import LayoutPage from '../../components/Layout/LayoutPage';
import { WrapperComponent } from '../../components/WrapperComponent';
import DomainSelector from './DomainSelector';

export default {
  path: '/select_domain',
  element: <WrapperComponent />,
  async action() {
    document.title = 'Select domain';
    return (
      <LayoutPage headerBorder={false}>
        <DomainSelector />
      </LayoutPage>
    );
  },
};
