import React from 'react';
import { redirect } from 'react-router-dom';
import { fetchTemplate } from '../../actions/messageTemplate';
import {
  fetchChannel,
  fetchChannels,
  fetchUnreadCount,
  markChannelAsRead,
  updateTextInput,
} from '../../actions/messaging';
import { WrapperComponent } from '../../components/WrapperComponent';
import { getChannelId, getChannelUrl } from '../../core/messaging';
import Messaging from './Messaging';

async function loadChannel(store, channelUrl) {
  const channel = await store.dispatch(fetchChannel(channelUrl));

  if (channel && channel.raw.unreadMessageCount > 0) {
    await store.dispatch(markChannelAsRead(channelUrl));
    store.dispatch(fetchUnreadCount());
  }

  return channel;
}

async function prefillWithTemplate(
  store,
  channelUrl,
  { channel, expert, request, template }
) {
  const { viewer } = store.getState();
  if (!channel || !expert || !request || !template) return;
  const t = await store.dispatch(
    fetchTemplate(template, {
      senderId: viewer.id,
      requestId: request,
      expertId: expert,
    })
  );
  if (t) {
    store.dispatch(updateTextInput(channelUrl, t.rendered));
  }
  return t;
}

export default {
  path: '/messaging/:channelId?',
  element: <WrapperComponent />,
  async action({ params, store, query }) {
    const { channelId } = params;

    const channelUrl = channelId && getChannelUrl(channelId);

    // Async load and set channel text input with template's content
    const t = await prefillWithTemplate(store, channelUrl, query);

    if (channelUrl) {
      const channel = await loadChannel(store, channelUrl);
      if (!channel) {
        return redirect('/messaging');
      }
    } else if (t) {
      const queryParams = query.channel ? `?channel=${query.channel}` : '';
      return redirect(`/messaging/${channelId}${queryParams}`);
    } else {
      const channels = await store.dispatch(fetchChannels());
      const channel = channels?.[0];
      if (channel) {
        return redirect(`/messaging/${getChannelId(channel.raw.url)}`);
      }
    }

    document.title = 'Messaging';
    return (
      <Messaging
        channelUrl={channelUrl}
        mobileChannelSelected={query.channel === 'true'}
      />
    );
  },
};
