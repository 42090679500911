import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createSector,
  fetchSector,
  removeSector,
} from '../../../actions/sector';
import { notify } from '../../../actions/ui';
import ModelForm from '../ModelForm';

function SectorModel({
  id,
  model,
  fetchSector,
  createSector,
  removeSector,
  notify,
  sector,
}) {
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      (async (id) => {
        try {
          await fetchSector({ id });
        } catch (error) {
          notify(error.message, 'error');
          navigate(`/admin/${model.path}`);
        }
      })(id);
    }
  }, [id]);

  const editableFields = id
    ? []
    : [{ name: 'name', type: 'text', maxLength: 60, required: true }];

  const handleSubmit = useCallback(async (values) => {
    const sector = await createSector(values);
    navigate(`/admin/${model.path}/${sector.id}`);
  }, []);

  const handleDelete = useCallback(() => removeSector(id), [id]);

  return (
    <ModelForm
      title={id ? `Sector ${id} - ${sector.name}` : 'Create new sector'}
      id={id}
      model={model}
      onSubmit={id ? null : handleSubmit}
      onDelete={handleDelete}
      initialModel={sector}
      fields={editableFields}
      returnPath={model.path}
    />
  );
}

SectorModel = connect(
  (state, ownProps) => {
    return {
      sector: state.sectors.all.find((s) => s.id === ownProps.id) || {},
    };
  },
  {
    fetchSector,
    createSector,
    removeSector,
    notify,
  }
)(SectorModel);

export default SectorModel;
