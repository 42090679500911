import React from 'react';
import { connect } from 'react-redux';
import { completeStep } from '../../actions/expertComplianceTraining';
import Wizard from '../../components/Wizard';
import { useNavigate } from 'react-router-dom';

const ComplianceWizard = (props) => {
  const navigate = useNavigate();
  const onNext = () => {
    const { completeStep, nextUrl, user, path } = props;

    completeStep(path, user.id);
    navigate(nextUrl);
  };

  const { children, ...other } = props;
  const page = React.cloneElement(children, { onNext });

  return <Wizard {...other}>{page}</Wizard>;
};

export default connect(null, {
  completeStep,
})(ComplianceWizard);
