import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import cx from 'classnames';
import { Checkbox, FormControlLabel } from '@mui/material';

import { TimeRange } from '../../../core/time';
import Timeline from './Timeline';
import RecordingPlayer from '../RecordingPlayer';
import LabelledOutline from '../../../components/LabelledOutline';
import { getParticipants } from './participant';
import { fetchAttachmentContent } from '../../../actions/consultation';
import { fileTypes } from '../../../core/consultation';
import s from './Metrics.module.scss';

function Legend() {
  return (
    <div className={s.legend}>
      <div className={s.legendItem}>
        <div className={cx(s.legendSquare, s.notConnectedLegend)} />
        Not connected
      </div>
      <div className={s.legendItem}>
        <div className={cx(s.legendSquare, s.connectedLegend)} />
        Connected
      </div>
      <div className={s.legendItem}>
        <div className={cx(s.legendSquare, s.warningLegend)} />
        Slow network
      </div>
      <div className={s.legendItem}>
        <div className={cx(s.legendDot, s.info)} />
        Info
      </div>
      <div className={s.legendItem}>
        <div className={cx(s.legendDot, s.warning)} />
        Warning
      </div>
      <div className={s.legendItem}>
        <div className={cx(s.legendDot, s.error)} />
        Error
      </div>
    </div>
  );
}

function Calls({ carrier, title, participants, conferenceRange }) {
  return (
    <div>
      <div style={{ marginBottom: 10 }}>{title}</div>
      <Timeline
        carrier={carrier}
        range={conferenceRange}
        participants={participants}
      />
    </div>
  );
}

function ChatLog({ conference, fetchAttachmentContent }) {
  const [chatContent, setChatContent] = useState(undefined);

  async function loadChatUrl() {
    if (conference?.id) {
      const chat = conference?.attachments?.find(
        (attachment) => attachment.file_type === fileTypes.chat
      );
      if (!chat) {
        return;
      }
      const content = await fetchAttachmentContent(chat);
      setChatContent(content);
    }
  }

  useEffect(() => {
    if (conference !== undefined) {
      loadChatUrl();
    }
  }, [conference]);

  return (
    <div>
      <h3>Chat Log</h3>
      {chatContent ? (
        <LabelledOutline
          classes={{
            content: s.chatLogContent,
          }}
        >
          <div className={s.chatLog}>{chatContent}</div>
        </LabelledOutline>
      ) : (
        <p className={s.chatLogUnavailable}>Chat log not available.</p>
      )}
    </div>
  );
}
ChatLog = connect(undefined, {
  fetchAttachmentContent,
})(ChatLog);

function Timelines({
  oneTimelineForEachConnection,
  participants,
  consultation,
  participantsGrouped,
  conferenceRange,
  now,
}) {
  return oneTimelineForEachConnection
    ? participants.map((p) => (
        <Calls
          carrier={consultation.conference.carrier}
          key={p.id}
          title={p.getCallOriginDescription()}
          participants={[p]}
          conferenceRange={conferenceRange}
          now={now}
        />
      ))
    : Object.keys(participantsGrouped).map((origin) => (
        <Calls
          carrier={consultation.conference.carrier}
          key={origin}
          title={origin}
          participants={participantsGrouped[origin]}
          conferenceRange={conferenceRange}
          now={now}
        />
      ));
}

function groupParticipants(participants) {
  const groupedParticipants = {};

  participants.forEach((p) => {
    const origin = p.getCallOriginDescription();
    if (!groupedParticipants[origin]) groupedParticipants[origin] = [];
    groupedParticipants[origin].push(p);
  });

  return groupedParticipants;
}

class Metrics extends Component {
  constructor(props) {
    super(props);

    const participants = getParticipants(props.consultation.conference);

    let firstCallStart;
    let lastCallEnd;
    participants
      .filter((p) => p.hasCall())
      .forEach((p) => {
        const start = new Date(p.callStart());
        const end = p.callEnd() ? new Date(p.callEnd()) : new Date();

        if (!firstCallStart || start < firstCallStart) firstCallStart = start;
        if (!lastCallEnd || end > lastCallEnd) lastCallEnd = end;
      });

    const conferenceRange = new TimeRange(
      moment(firstCallStart),
      moment(lastCallEnd)
    );

    this.state = {
      participants,
      conferenceRange,
      now: moment(),
    };
  }

  render() {
    const { participants } = this.state;
    const { consultation } = this.props;
    const { oneTimelineForEachConnection, hideConnectionsWithZeroDuration } =
      this.state;

    let clientParticipants = participants.filter(
      (p) =>
        p.identifier === consultation.requester_identifier ||
        p.getGrouper() === 'requester'
    );
    let expertParticipants = participants.filter(
      (p) =>
        p.identifier === consultation.expert_identifier ||
        p.getGrouper() === 'expert'
    );
    let unknownParticipants = participants.filter(
      (p) => p.getGrouper() === 'unknown'
    );
    if (hideConnectionsWithZeroDuration) {
      clientParticipants = clientParticipants.filter((p) => p.callDuration());
      expertParticipants = expertParticipants.filter((p) => p.callDuration());
      unknownParticipants = unknownParticipants.filter((p) => p.callDuration());
    }

    const clientParticipantsGrouped = groupParticipants(clientParticipants);
    const expertParticipantsGrouped = groupParticipants(expertParticipants);
    const unknownParticipantsGrouped = groupParticipants(unknownParticipants);
    const clientData = Object.keys(clientParticipants).length > 0;
    const expertData = Object.keys(expertParticipants).length > 0;
    const unknownData = Object.keys(unknownParticipants).length > 0;
    const noData =
      Object.keys(clientParticipants).length === 0 &&
      Object.keys(expertParticipants).length === 0 &&
      Object.keys(unknownParticipants).length === 0;

    const hasEvents = participants.some((p) => p.events());

    const timelinesProps = {
      oneTimelineForEachConnection,
      consultation,
      conferenceRange: this.state.conferenceRange,
      now: this.state.now,
    };

    return (
      <div>
        {hasEvents && <Legend />}
        <div className={s.checks}>
          <FormControlLabel
            classes={{ root: s.check }}
            control={
              <Checkbox
                checked={oneTimelineForEachConnection}
                onChange={() =>
                  this.setState((prev) => ({
                    oneTimelineForEachConnection:
                      !prev.oneTimelineForEachConnection,
                  }))
                }
              />
            }
            label="Show one timeline for each connection"
          />
          <FormControlLabel
            classes={{ root: s.check }}
            control={
              <Checkbox
                checked={hideConnectionsWithZeroDuration}
                onChange={() =>
                  this.setState((prev) => ({
                    hideConnectionsWithZeroDuration:
                      !prev.hideConnectionsWithZeroDuration,
                  }))
                }
              />
            }
            label="Hide connections with zero duration"
          />
        </div>
        {clientData && <h3>Client</h3>}
        <Timelines
          {...timelinesProps}
          participants={clientParticipants}
          participantsGrouped={clientParticipantsGrouped}
        />

        {expertData && <h3>Expert</h3>}
        <Timelines
          {...timelinesProps}
          participants={expertParticipants}
          participantsGrouped={expertParticipantsGrouped}
        />

        {unknownData && <h3>Other</h3>}
        <Timelines
          {...timelinesProps}
          participants={unknownParticipants}
          participantsGrouped={unknownParticipantsGrouped}
        />

        <RecordingPlayer conference={consultation.conference} enabled />

        <ChatLog conference={consultation.conference} />

        {noData && <h3>No metrics data available.</h3>}
      </div>
    );
  }
}

Metrics = Metrics;

export default Metrics;
