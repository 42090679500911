import GraphiQL from 'graphiql';
import React from 'react';
import { redirect } from 'react-router-dom';
import config from '../../../config';
import { WrapperComponent } from '../../components/WrapperComponent';

function graphQLFetcher(token, graphQLParams) {
  const headers = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return fetch(`${config.apiUrl}/graphql`, {
    method: 'post',
    headers,
    body: JSON.stringify(graphQLParams),
  }).then((response) => response.json());
}

export default {
  path: '/graphiql',
  element: <WrapperComponent />,
  async action({ store }) {
    const { viewer } = store.getState();
    if (!process.env.NODE_ENV === 'development' && !viewer.admin)
      return redirect('/404');

    const { token } = store.getState().runtime;

    document.title = 'GraphiQL';
    return (
      <div style={{ height: '100vh' }}>
        <GraphiQL fetcher={(params) => graphQLFetcher(token, params)} />
      </div>
    );
  },
};
