import React from 'react';
import { redirect } from 'react-router-dom';
import { fetchLegalPages } from '../../actions/blog';
import { fetchUser } from '../../actions/user';
import LayoutPage from '../../components/Layout/LayoutPage';
import MediaQuery from '../../components/MediaQuery';
import { WrapperComponent } from '../../components/WrapperComponent';
import { SCREEN_SM } from '../../constants';
import LegalAcknowledgement from './LegalAcknowledgement';

const policyConfig = {
  privacy: {
    props: {
      aggregateAgreementName: 'Terms of Use and Privacy Policy',
      policyKeys: ['terms-of-use', 'privacy'],
    },
  },
  'expert-participation-agreement': {
    props: {
      aggregateAgreementName: 'Expert Participation Agreement',
      policyKeys: ['expert-participation-agreement'],
    },
  },
};

function ExpertAgreememtInternalNetworkNote({ networks }) {
  if (!networks || networks.length === 0) return redirect('/404');

  const names = networks.map((n) => (
    <span>
      <b>{n.name}</b>
      {networks.length > 1 ? ',' : ''}
    </span>
  ));

  return (
    <div style={{ lineHeight: 1.25 }}>
      Joining OnFrontiers allows you to be engaged by {names} and other clients
      for paid phone consultations and other types of work. It does not inhibit
      in any way your current or future work with {names} or any other client we
      introduce with whom you may already have a relationship.
    </div>
  );
}

export default {
  path: '/legal_ack/:policy',
  element: <WrapperComponent />,
  async action({ store, query, params }) {
    const state = store.getState();
    if (!state.legal.pages) await store.dispatch(fetchLegalPages());

    let note;
    if (params.policy === 'expert-participation-agreement') {
      const viewer = await store.dispatch(
        fetchUser(state.viewer.username, {
          internalNetworks: true,
        })
      );
      const expertNetworks = viewer.profile.expert_internal_networks;
      const networks = expertNetworks && expertNetworks.map((e) => e.network);
      if (networks) {
        note = <ExpertAgreememtInternalNetworkNote networks={networks} />;
      }
    }

    const config = policyConfig[params.policy];

    document.title = `Updated ${config.props.aggregateAgreementName}`;
    return (
      <LayoutPage hideSearch showNewRequest={false}>
        <MediaQuery maxWidth={SCREEN_SM}>
          {(isMobileVersion) => (
            <LegalAcknowledgement
              userId={state.viewer.id}
              nextUrl={query.next || '/dashboard'}
              isMobileVersion={isMobileVersion}
              note={note}
              {...config.props}
            />
          )}
        </MediaQuery>
      </LayoutPage>
    );
  },
};
