import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import cx from 'classnames';
import { Badge } from '@mui/material';
import Picture from '../../components/Picture';
import SvgIcon from '../../components/Icon/SvgIcon';
import TypingIndicator from './TypingIndicator';
import OnlineStatus from './OnlineStatus';
import history from '../../core/history';
import { darkGreen, white, black } from '../../core/colors';
import {
  isNotAnonymous,
  getChannelId,
  isAnonymous,
} from '../../core/messaging';
import s from './Channel.module.scss';
import { useNavigate } from 'react-router-dom';

const Channel = (props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const { onSelect } = props;
    navigate(`/messaging/${getChannelId(props.channel.raw.url)}`);
    if (onSelect) onSelect(props.channel);
  };

  const { users, selected, viewer, channel } = props;

  const { lastMessage } = channel.raw;

  const m = channel.raw.members.find((m) => m.userId !== viewer.id);

  if (!m) return null;

  const user = isNotAnonymous(channel.raw, m.userId) && users[m.userId];

  const messageDate = lastMessage
    ? new Date(lastMessage.createdAt)
    : new Date();
  const format = moment(messageDate)
    .startOf('day')
    .isSame(moment().startOf('day'))
    ? 'h:mma'
    : 'D MMM';

  const unreadCount = channel.raw.unreadMessageCount;
  const isTyping = channel.raw.getTypingMembers().length > 0;

  return (
    <div
      onClick={handleClick}
      onKeyPress={handleClick}
      className={cx(s.channel, {
        [s.channelSelected]: selected,
        [s.unreadMessage]: unreadCount > 0,
      })}
    >
      <div className={s.picture}>
        <Picture user={user} />
        {m.connectionStatus === 'online' && <OnlineStatus />}
      </div>

      <div className={s.content}>
        <div className={s.name}>{user ? user.name : 'Confidential Client'}</div>

        {lastMessage && (
          <div className={s.date}>{moment(messageDate).format(format)}</div>
        )}
      </div>

      <div className={s.actions}>
        {unreadCount > 0 ? (
          <Badge
            style={{ padding: 0, verticalAlign: 'middle' }}
            badgeStyle={{
              position: 'initial',
              backgroundColor: selected ? white : darkGreen,
              color: selected ? darkGreen : white,
            }}
            badgeContent={unreadCount}
          />
        ) : isTyping ? (
          <TypingIndicator />
        ) : (
          isAnonymous(channel.raw, viewer.id) && (
            <SvgIcon
              icon="incognito"
              width={25}
              height={25}
              fill={selected ? white : black}
            />
          )
        )}
      </div>
    </div>
  );
};

export default connect((state) => ({
  users: state.users,
  viewer: state.viewer,
}))(Channel);
