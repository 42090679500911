import React, { memo } from 'react';
import cx from 'classnames';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AddButton from '../Forms/Profile/buttons/AddButton';
import FAIcon from '../Icon/FAIcon';
import { black, borderColor } from '../../core/colors';
import s from './DraggableList.module.scss';

function Input({
  showRemove,
  name,
  index,
  onAdd,
  onRemove,
  inputProps,
  inputComponent,
  className,
  dragClassName,
  removeClassName,
  value,
  disabled,
}) {
  const InputComponent = inputComponent;

  return (
    <Draggable draggableId={name} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={cx(
            s.container,
            className,
            snapshot.isDragging ? s.draggingListItem : ''
          )}
        >
          {!disabled && (
            <div
              {...provided.dragHandleProps}
              className={cx(s.dragAction, dragClassName)}
            >
              <FAIcon icon="bars" className={s.dragButton} />
            </div>
          )}
          <div className={cx(s.removeAction, removeClassName)}>
            <FAIcon
              icon="trash"
              className={s.removeButton}
              onClick={() => showRemove && onRemove()}
              style={{
                color: showRemove ? black : borderColor,
                cursor: showRemove ? 'pointer' : 'not-allowed',
              }}
            />
          </div>

          <InputComponent
            name={name}
            index={index}
            onAdd={onAdd}
            value={value}
            {...inputProps}
          />
        </div>
      )}
    </Draggable>
  );
}

const DraggableList = memo(
  ({
    fields,
    inputProps,
    inputComponent,
    minLength = 0,
    addButtonLabel,
    multi,
    style,
    className,
    itemClassName,
    removeClassName,
    disabled,
    showRemoveForField = () => true,
  }) => {
    const handleDragEnd = ({ destination, source }) => {
      if (!destination) return;
      fields.swap(source.index, destination.index);
    };

    const handleAdd = () => {
      const values = fields.value;
      const hasEmptyValue = values?.some((x) =>
        multi ? !Object.keys(x).length : !x
      );
      if (!hasEmptyValue) {
        fields.push(multi ? {} : '');
      }
    };

    const showRemove = fields.length > minLength;

    return (
      <>
        <div style={style} className={className}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable-list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {fields.map((name, index) => (
                    <Input
                      key={name}
                      inputComponent={inputComponent}
                      name={name}
                      index={index}
                      inputProps={inputProps}
                      onAdd={handleAdd}
                      onRemove={() => fields.remove(index)}
                      showRemove={
                        !disabled &&
                        showRemove &&
                        showRemoveForField(fields.value?.[index])
                      }
                      disabled={disabled}
                      className={itemClassName}
                      removeClassName={removeClassName}
                      value={fields.value[index]}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        {!disabled && (
          <div className={s.addAction}>
            <AddButton label={addButtonLabel} onClick={handleAdd} />
          </div>
        )}
      </>
    );
  }
);

export default DraggableList;
