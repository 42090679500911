import React from 'react';
import { connect } from 'react-redux';

import Link from '../../../components/Link/Link';
import { formatSeconds } from '../../../core/time';
import { darkGreen, teal500, red500 } from '../../../core/colors';
import s from './TwilioPopoverContent.module.scss';

function getMosColor(value) {
  if (!value) return;
  return { color: value >= 3.5 ? teal500 : red500 };
}

function getJitterColor(value, compare) {
  if (!value) return;
  return { color: value < compare ? teal500 : red500 };
}

function getLatencyColor(value, compare) {
  if (!value) return;
  return { color: value <= compare ? teal500 : red500 };
}

function getPacketLossColor(value) {
  if (!value) return;
  return { color: value <= 5 ? teal500 : red500 };
}

function TwilioPopoverContent({ participant, range, allCountries }) {
  const selectedCallType = participant.inferCallType();
  const selectedCallDuration =
    participant.callDuration() || range.duration().seconds();

  // https://www.twilio.com/docs/voice/insights/api/voice-insights-call-tags#tags
  // https://www.twilio.com/docs/voice/insights/api/call-summary-resource#get-call-summary

  /* carrier_edge represents the connection between Twilio and our immediate
  carrier partners. The metrics here describe the media stream metadata as
  it was received from the carrier on the inbound, and as it was delivered
  to the carrier on the outbound. */

  /* client_edge represents the Twilio media gateway for Client calls. The
  metrics here describe the media stream metadata as it was received from
  the Client SDK application on the inbound, and as it was sent to the
  Client SDK application on the outbound. */

  /* sdk_edge represents the sensor library in the SDK application itself.
  The metrics here describe the media stream that was received at the SDK
  from the Twilio media gateway. Packet loss, jitter, MOS, and RTT are
  reporting on the inbound stream to the SDK. */

  const {
    from,
    to,
    sdk_edge: sdkEdge,
    client_edge: clientEdge,
    carrier_edge: carrierEdge,
    tags: callTags,
  } = participant.summary() || {};

  const { sdk, carrier: fromCarrier } = from || {};
  const { carrier: toCarrier } = to || {};
  const { browser, os, tags: sdkTags } = sdk || {};

  const sdkMetrics = sdkEdge && sdkEdge.metrics && sdkEdge.metrics.inbound;
  const {
    jitter: sdkJitter,
    rtt: sdkRtt,
    packets_loss_percentage: sdkPacketLoss,
    mos: sdkMos,
  } = sdkMetrics || {};

  const providerInMetrics =
    clientEdge && clientEdge.metrics && clientEdge.metrics.inbound;
  const {
    jitter: providerInJitter,
    latency: providerInLatency,
    packets_loss_percentage: providerInPacketLoss,
    codec_name: providerInCodec,
  } = providerInMetrics || {};

  const providerOutMetrics =
    clientEdge && clientEdge.metrics && clientEdge.metrics.outbound;
  const {
    jitter: providerOutJitter,
    latency: providerOutLatency,
    packets_loss_percentage: providerOutPacketLoss,
    codec_name: providerOutCodec,
  } = providerOutMetrics || {};

  const carrierInMetrics =
    carrierEdge && carrierEdge.metrics && carrierEdge.metrics.inbound;
  const {
    jitter: carrierInJitter,
    latency: carrierInLatency,
    packets_loss_percentage: carrierInPacketLoss,
    codec_name: carrierInCodec,
  } = carrierInMetrics || {};

  const carrierOutMetrics =
    carrierEdge && carrierEdge.metrics && carrierEdge.metrics.outbound;
  const {
    jitter: carrierOutJitter,
    latency: carrierOutLatency,
    packets_loss_percentage: carrierOutPacketLoss,
    codec_name: carrierOutCodec,
  } = carrierOutMetrics || {};

  const locationSource = selectedCallType === 'dialout' ? to : from;
  const {
    city,
    country_code: countryCode,
    location: coords,
  } = locationSource || {};
  const { lat, lon } = coords || {};

  const country =
    countryCode &&
    allCountries.find((c) => c.country_iso2_code === countryCode);
  const countryName = country ? country.name : countryCode;
  const location = `${city || ''}${city && countryName ? ', ' : ''}${
    countryName || ''
  }`;

  return (
    <React.Fragment>
      {sdkTags && sdkTags.length > 0 && (
        <Field label="Browser" style={{ marginTop: 0 }}>
          <small style={{ color: darkGreen }}>{sdkTags.join(', ')}</small>
        </Field>
      )}

      {callTags && callTags.length > 0 && (
        <Field label="Twilio" style={{ marginTop: 0 }}>
          <small style={{ color: darkGreen }}>{callTags.join(', ')}</small>
        </Field>
      )}

      <Field
        label="Duration"
        value={`${formatSeconds(
          selectedCallDuration
        )} (${participant.callTypeLabel()})`}
        style={{ marginTop: 0 }}
      />

      {participant.callFrom() && participant.callTo() && (
        <Field
          label="From"
          value={`${participant.callFrom()}${
            fromCarrier ? ` (${fromCarrier})` : ''
          }`}
        />
      )}

      {participant.callTo() && (
        <Field
          label="To"
          value={`${participant.callTo()}${toCarrier ? ` (${toCarrier})` : ''}`}
        />
      )}

      {location && (
        <Field
          label="Location"
          value={location}
          to={
            lat &&
            `https://maps.google.com/?q=${lat},${lon}&ll=${lat},${lon}&z=6`
          }
        />
      )}

      {os && <Field label="System" value={`${os.name} ${os.version}`} />}

      {browser && (
        <Field label="Browser" value={`${browser.name} ${browser.version}`} />
      )}

      {sdkMetrics && (
        <MetricsSection
          title="Browser (Received) Avg/Max/Min"
          mos={sdkMos}
          jitter={sdkJitter}
          rtt={sdkRtt}
          packetLoss={sdkPacketLoss}
        />
      )}

      {providerInMetrics && (
        <MetricsSection
          title="Twilio (Received) Avg/Max"
          jitter={providerInJitter}
          latency={providerInLatency}
          packetLoss={providerInPacketLoss}
          codec={providerInCodec}
        />
      )}

      {providerOutMetrics && (
        <MetricsSection
          title="Twilio (Sent) Avg/Max"
          jitter={providerOutJitter}
          latency={providerOutLatency}
          packetLoss={providerOutPacketLoss}
          codec={providerOutCodec}
        />
      )}

      {carrierInMetrics && (
        <MetricsSection
          title="Carrier (Received) Avg/Max"
          jitter={carrierInJitter}
          latency={carrierInLatency}
          packetLoss={carrierInPacketLoss}
          codec={carrierInCodec}
        />
      )}

      {carrierOutMetrics && (
        <MetricsSection
          title="Carrier (Sent) Avg/Max"
          jitter={carrierOutJitter}
          latency={carrierOutLatency}
          packetLoss={carrierOutPacketLoss}
          codec={carrierOutCodec}
        />
      )}
    </React.Fragment>
  );
}

function MetricsSection({
  title,
  mos,
  jitter,
  latency,
  rtt,
  packetLoss,
  codec,
}) {
  return (
    <div className={s.metricsSection}>
      <p style={{ textDecoration: 'underline' }}>{title}</p>
      {mos ? (
        <Field label="Quality Score">
          <b style={getMosColor(mos.avg)}>
            {mos.avg ? mos.avg.toFixed(2) : 'NA'}
          </b>
          &nbsp;/&nbsp;
          <b style={getMosColor(mos.max)}>
            {mos.max ? mos.max.toFixed(2) : 'NA'}
          </b>
          &nbsp;/&nbsp;
          <b style={getMosColor(mos.min)}>
            {mos.min ? mos.min.toFixed(2) : 'NA'}
          </b>
        </Field>
      ) : undefined}
      {jitter ? (
        <Field label="Jitter (ms)">
          <b style={getJitterColor(jitter.avg, 5)}>
            {jitter.avg ? jitter.avg.toFixed(1) : 'NA'}
          </b>
          &nbsp;/&nbsp;
          <b style={getJitterColor(jitter.max, 30)}>
            {jitter.max ? jitter.max.toFixed(1) : 'NA'}
          </b>
        </Field>
      ) : undefined}
      {latency ? (
        <Field label="Latency (ms)">
          <b style={getLatencyColor(latency.avg, 150)}>
            {latency.avg ? latency.avg.toFixed(0) : 'NA'}
          </b>
          &nbsp;/&nbsp;
          <b style={getLatencyColor(latency.max, 150)}>
            {latency.max ? latency.max.toFixed(0) : 'NA'}
          </b>
        </Field>
      ) : undefined}
      {rtt ? (
        <Field label="RTT (ms)">
          <b style={getLatencyColor(rtt.avg, 400)}>
            {rtt.avg ? rtt.avg.toFixed(0) : 'NA'}
          </b>
          &nbsp;/&nbsp;
          <b style={getLatencyColor(rtt.max, 400)}>
            {rtt.max ? rtt.max.toFixed(0) : 'NA'}
          </b>
        </Field>
      ) : undefined}
      {packetLoss ? (
        <Field label="Packet Loss (%)">
          <b style={getPacketLossColor(packetLoss)}>{packetLoss.toFixed(2)}</b>
        </Field>
      ) : undefined}
      {codec ? <Field label="Codec" value={codec} /> : undefined}
    </div>
  );
}

function Field({ label, value, children, style, to }) {
  const content = to ? (
    <Link newTab to={to}>
      {value}
    </Link>
  ) : (
    value
  );
  return (
    <p style={style}>
      {label}: {children || <b>{content}</b>}
    </p>
  );
}

TwilioPopoverContent = connect((state) => ({
  allCountries: state.countries,
}))(TwilioPopoverContent);

TwilioPopoverContent = TwilioPopoverContent;

export default TwilioPopoverContent;
