import React from 'react';

import Link from '../../../../components/Link';
import TimeButton from '../../TimeButton';
import { darkGreen } from '../../../../core/colors';
import { getTimezoneDescription } from '../../../../core/time';
import { formatDuration } from '../../../../core/consultation';
import s from './ProposedTimes.module.scss';
import Button from '../../../../components/Button/Button';

function ProposedTimes({
  viewer,
  selectedDate,
  userFirstName,
  isWaitingViewerConfirmation,
  isWaitingOtherPartConfirmation,
  isCanceled,
  isDenied,
  isExpired,
  canConfirm,
  canSuggestTime,
  proposedTimes,
  rejectedTimes,
  expectedDuration,
  onOpenSuggestTime,
  onDateSelect,
}) {
  const expectedDurationString = formatDuration(expectedDuration);

  return (
    <div className={s.suggestedTimes}>
      <div className={s.suggestedTimesTitle}>
        {(isWaitingViewerConfirmation &&
          rejectedTimes.length > 0 &&
          `${userFirstName} suggested an alternate time for a ${expectedDurationString} call:`) ||
          (isWaitingViewerConfirmation &&
            rejectedTimes.length === 0 &&
            `Let ${userFirstName} know if any of these times will work:`) ||
          (isWaitingOtherPartConfirmation &&
            `Waiting to hear back from ${userFirstName} if ${
              proposedTimes.length === 1 ? 'this time' : 'any of these times'
            } will work...`) ||
          ((isCanceled || isDenied || isExpired) && 'Suggested call times')}
      </div>

      {proposedTimes
        .sort((a, b) => new Date(a) - new Date(b))
        .map((date) => (
          <TimeButton
            disabled={!canConfirm}
            key={date}
            date={date}
            timezone={viewer.timezone}
            expectedDuration={expectedDuration}
            selected={selectedDate === date}
            onClick={() => onDateSelect(date)}
          />
        ))}

      {canSuggestTime && (
        <Button
          square
          color="white"
          fontColor={darkGreen}
          style={{
            textTransform: 'initial',
            fontWeight: 500,
            padding: '0 10px',
            marginBottom: 20,
          }}
          onClick={onOpenSuggestTime}
        >
          {isWaitingViewerConfirmation ? 'Suggest Another' : 'Change Times'}
        </Button>
      )}

      <div className={s.changeTimezone}>
        <Link className={s.changeTimezoneLink} to="/settings/communication">
          Change My Timezone {getTimezoneDescription(viewer.timezone)}
        </Link>
      </div>
    </div>
  );
}

export default ProposedTimes;
