import React from 'react';
import { redirect } from 'react-router-dom';
import {
  fetchNetworkExpert,
  updateNetworkExpert,
} from '../../actions/internalNetwork';
import { fetchProfile } from '../../actions/profile';
import LayoutPage from '../../components/Layout/LayoutPage';
import { WrapperComponent } from '../../components/WrapperComponent';
import AcceptNetwork from './AcceptNetwork';
import ExpertNetwork from './ExpertNetwork';

export default [
  {
    path: '/network',
    element: <WrapperComponent />,
    async action({ store }) {
      const { viewer } = store.getState();

      const profile = await store.dispatch(
        fetchProfile(viewer.username, {
          force: true,
          internalNetworks: true,
          groupAboutPage: true,
          internalNetworkConsultations: true,
          connectionStates: ['active', 'awaiting_acceptance'],

          // for completeness
          experiences: true,
          education: true,
          addresses: true,
          expertise: true,
        })
      );

      document.title = 'My Clients';
      return (
        <LayoutPage showNav selected="my-network">
          <ExpertNetwork profileId={profile.id} />
        </LayoutPage>
      );
    },
  },
  {
    path: '/network/:networkExpertId/:connectionState',
    element: <WrapperComponent />,
    async action({ store, params }) {
      const { networkExpertId, connectionState } = params;

      if (!['rejected', 'removed', 'active'].includes(connectionState))
        return redirect('/404');

      const networkExpert = await store.dispatch(
        fetchNetworkExpert(networkExpertId)
      );

      const { domain } = networkExpert.network.group;

      const canAccept =
        !domain || !domain.agreements || domain.agreements.length === 0;

      if (connectionState !== 'active' || canAccept) {
        await store.dispatch(
          updateNetworkExpert({
            id: networkExpertId,
            connection_state: connectionState,
          })
        );
        return redirect('/network');
      }

      document.title = 'My Clients';
      return (
        <LayoutPage showNav selected="my-network">
          <AcceptNetwork networkExpert={networkExpert} />
        </LayoutPage>
      );
    },
  },
];
