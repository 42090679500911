import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Alert from '../Alert';
import Link from '../Link';
import Button from '../Button';
import Picture from '../Picture';
import MaterialIcon from '../Icon/MaterialIcon';
import { getUserName, getUser } from '../../core/consultation';
import { isCallType } from '../../actions/expertRequest';
import { darkBlue, white } from '../../core/colors';
import s from './ConsultationsStarting.module.scss';

class ConsultationsStarting extends Component {
  state = {
    consultationDialogClosed: {},
  };

  closeConsultationAlert = (id) => {
    this.setState((prevState) => ({
      consultationDialogClosed: {
        ...prevState.consultationDialogClosed,
        [id]: true,
      },
    }));
  };

  render() {
    const { consultations, userContext, viewer, call } = this.props;
    const { consultationDialogClosed } = this.state;

    const filteredConsultations = consultations.filter(
      (c) =>
        !consultationDialogClosed[c.id] &&
        c.id !== call.consultationId &&
        (!c.expert_request || isCallType(c.expert_request.er_type))
    );

    if (consultations.length === 0) return null;

    return (
      <div>
        {filteredConsultations.map((c) => {
          const diff = moment(c.starts_at).diff(moment());
          const minutes = moment.duration(diff).minutes();

          const user = getUser(viewer, userContext, c, false);
          const userName = getUserName(viewer, userContext, c, false);

          return (
            <Alert
              key={c.id}
              backgroundColor={darkBlue}
              onRequestClose={() => this.closeConsultationAlert(c.id)}
            >
              <div className={s.consultationStarting}>
                <div>
                  <Picture user={user} size={80} />
                </div>

                <div className={s.consultationStartingContent}>
                  <div className={s.consultationStartingText}>
                    {minutes <= 0
                      ? 'In progress'
                      : `Starting in ${minutes} ${
                          minutes === 1 ? 'minute' : 'minutes'
                        }`}
                    : &nbsp;
                    <span className={s.consultationStartingUser}>
                      Call with {userName}
                    </span>
                  </div>

                  <Link to={`${c.html_url}?join_call=true`}>
                    <Button
                      startIcon={
                        <MaterialIcon color={white} icon="phone" size={20} />
                      }
                      size="medium"
                    >
                      Join Call
                    </Button>
                  </Link>
                </div>
              </div>
            </Alert>
          );
        })}
      </div>
    );
  }
}

ConsultationsStarting = ConsultationsStarting;

export default connect((state) => ({
  viewer: state.viewer,
  call: state.call,
  userContext: state.ui.userContext,
  consultations: ((state.consultations.starting || {}).edges || []).map(
    (c) => c.node
  ),
}))(ConsultationsStarting);
