import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Checkbox as MuiCheckbox } from '@mui/material';
import Button from '../../components/Button/Button';
import Form from '../../components/Form';
import KeywordInput from '../../components/KeywordInput';
import SettingsSection from '../../components/SettingsSection';
import { promoPopup } from '../../components/SendMessageButton/promo';
import { notify, popup } from '../../actions/ui';
import { fetchGroupKeywordCounts, updateGroup } from '../../actions/group';
import { track } from '../../actions/tracking';
import { isAdvancedUser } from '../../core/group';
import { fetchUser } from '../../actions/user';
import {
  Checkbox,
  TextField,
} from '../../components/FormAdapters/FormAdapters';
import { useApp } from '../../hooks/useAppContext';

function Preferences(props) {
  const { permissions } = useApp();
  const {
    viewer,
    group,
    popup,
    track,
    fetchUser,
    updateGroup,
    notify,
    fetchGroupKeywordCounts,
    handleSubmit: onSubmit,
  } = props;

  const handleUpdateGroup = async (values) => {
    try {
      await updateGroup(values);

      // update viewer groups
      fetchUser(viewer.username, { groups: true });
      notify('Team settings updated.', 'success');
    } catch (err) {
      notify('Error when updating team settings.', 'error');
    }
  };

  const handleConfirmation = (values, removes) => {
    const contents = (
      <div>
        Profiles marked with deleted team keyword(s) will have that
        annotation&nbsp;removed:
        {removes.groupKeywordCounts.map((c) => (
          <p key={c.id}>
            {c.name}: {c.count} profile(s) affected
          </p>
        ))}
      </div>
    );

    popup({
      title: 'Warning',
      contents,
      buttonAlignment: 'space-between',
      buttons: [
        { flat: true, label: 'Cancel' },
        {
          label: 'Confirm',
          callback: async () => {
            handleUpdateGroup(values);
          },
        },
      ],
    });
  };

  const handleSubmit = async (values) => {
    const removeIDs = [];
    group.profile_keywords_definition.forEach((keyword) => {
      if (values.profile_keywords_definition.includes(keyword.name) === false) {
        removeIDs.push(keyword.id.toString());
      }
    });

    const removes = await fetchGroupKeywordCounts(removeIDs);

    if (removes.groupKeywordCounts && removes.groupKeywordCounts.length > 0) {
      handleConfirmation(values, removes);
    } else handleUpdateGroup(values);
  };

  const showMessagingPromo = () => {
    promoPopup(popup, track);
  };

  /*
  For now, we allow super-admins to enable 2FA enforcement ONLY to the OnFrontiers group.
  To enable other teams and/or allow other team admins/owners to enable this feature we
  need to relax the following logic.  If team admins/owners wish to enable this feature
  on their team, `update_higher_tier_settings` permissions are appropriate. Note we can't
  use this for OnFrontiers group because members do not have this permission explicitly
  revoked and would be granted permission.

  TODO: When we choose to allow members outside of super-admins (OF owners/admins) to
  enforce 2FA or enable this feature for other teams, modify the below logic appropriately.
*/
  const displayEnforce2FA =
    group.name === 'OnFrontiers' &&
    viewer.admin &&
    permissions.allowed('group', 'update_admin_settings', group.id);

  return (
    <div>
      <Form onSubmit={onSubmit(handleSubmit)} disableSubmitOnEnter>
        <SettingsSection title="General">
          <Field
            component={TextField}
            name="about"
            multiline
            minRows={3}
            maxRows={10}
            label="About"
            placeholder="This will be displayed on your team sign up page"
            fullWidth
          />
        </SettingsSection>

        <SettingsSection title="Messaging" box>
          {isAdvancedUser(viewer) ? (
            <Field
              type="checkbox"
              component={Checkbox}
              name="default_anonymous_messaging"
              label="Keep team members anonymous by default when messaging experts"
            />
          ) : (
            <MuiCheckbox
              label="Keep team members anonymous by default when messaging experts"
              checked={false}
              onClick={showMessagingPromo}
            />
          )}
        </SettingsSection>

        {displayEnforce2FA && (
          <SettingsSection title="Security" box>
            <Field
              type="checkbox"
              component={Checkbox}
              name="enforce_2fa"
              label="Require members to activate Two Factor Authentication"
            />
          </SettingsSection>
        )}

        {permissions.allowed(
          'group',
          'update_higher_tier_settings',
          group.id
        ) && (
          <SettingsSection
            title="Team Keywords"
            text="Define terms team members can use when listing expertise and organizing experts within your network"
            box
          >
            <Field
              component={KeywordInput}
              primary
              freeSolo
              getOptionLabel={(o) => o}
              name="profile_keywords_definition"
              placeholder="Click to add keywords"
            />
          </SettingsSection>
        )}
        <Button type="submit" style={{ marginTop: 20 }} size="medium">
          Save
        </Button>
      </Form>
    </div>
  );
}

Preferences = reduxForm({
  form: 'teamPreferences',
})(Preferences);

Preferences = connect(
  (state, ownProps) => ({
    viewer: state.viewer,
    initialValues: {
      id: ownProps.group.id,
      about: ownProps.group.about,
      default_anonymous_messaging: ownProps.group.default_anonymous_messaging,
      enforce_2fa: ownProps.group.enforce_2fa,
      profile_keywords_definition: (
        ownProps.group.profile_keywords_definition || []
      ).map((k) => k.name),
    },
  }),
  {
    fetchGroupKeywordCounts,
    updateGroup,
    fetchUser,
    notify,
    popup,
    track,
  }
)(Preferences);

export default Preferences;
