import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchExpertShowcases } from '../../../actions/expertShowcase';
import ListItems from '../ListItems';
import ExpertShowcase from './ExpertShowcaseModel';

export default function ExpertShowcaseModels({
  fetchExpertShowcases,
  expertShowcases,
  model,
  id,
}) {
  const navigate = useNavigate();
  useEffect(() => {
    fetchExpertShowcases();
  }, []);

  const items = expertShowcases.map((page) => ({
    id: page.id,
    name: `${page.id} ${page.subdomain} - ${page.title}`,
  }));

  return id ? (
    <ExpertShowcase model={model} id={id !== 'new' ? id : undefined} />
  ) : (
    <ListItems
      model={model}
      items={items}
      onCreate={() => navigate(`/admin/${model.path}/new`)}
    />
  );
}

ExpertShowcaseModels = connect(
  (state) => {
    return {
      expertShowcases: state.expertShowcase.all || [],
    };
  },
  {
    fetchExpertShowcases,
  }
)(ExpertShowcaseModels);
