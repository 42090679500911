import React, { PureComponent } from 'react';

import { Button as MaterialButton, MenuItem } from '@mui/material';
import Button from '../../../components/Button/Button';
import Picture from '../../../components/Picture';
import Link from '../../../components/Link';
import IconMenu from '../../../components/IconMenu';
import Dialog from '../../../components/Dialog';
import MaterialIcon from '../../../components/Icon/MaterialIcon';
import Title from './Title';
import FAIcon from '../../../components/Icon/FAIcon';
import SendMessageButton from '../../../components/SendMessageButton';
import { black } from '../../../core/colors';
import { getStateDescription } from '../../../core/consultation';
import { useApp } from '../../../hooks/useAppContext';
import s from './Header.module.scss';
import { engagementTypes } from '../../../actions/consultation';
import { erTypeLabels } from '../../../actions/expertRequest';

const engagementTypeLabels = {
  [engagementTypes.consultation]: 'Call',
  [engagementTypes.writtenResponse]: 'Written Response',
  [engagementTypes.opportunity]: 'Opportunity Call',
};

class ConsultationActions extends PureComponent {
  state = {
    deleteConfirmation: false,
  };

  openDeleteConfirmation = () => {
    this.setState({ deleteConfirmation: true });
  };

  handleDelete = () => {
    const { onDelete } = this.props;
    onDelete();
  };

  render() {
    const { onDelete } = this.props;

    if (!onDelete) return null;

    return (
      <div className={s.actions}>
        <IconMenu iconSize={24} iconStyle={{ color: black, opacity: 1 }}>
          {onDelete && (
            <MenuItem onClick={this.openDeleteConfirmation}>Delete</MenuItem>
          )}
        </IconMenu>
        <Dialog
          open={this.state.deleteConfirmation}
          title="Are you sure?"
          subTitle={
            'You will lose all details and audio from this consultation. ' +
            'Click delete to confirm.'
          }
          onCancel={() => this.setState({ deleteConfirmation: false })}
          onConfirm={this.handleDelete}
          confirmLabel="Delete"
          cancelLabel="Cancel"
        />
      </div>
    );
  }
}

function Header(props) {
  const { permissions } = useApp();

  const {
    consultation,
    user,
    isViewerExpert,
    isViewerAdmin,
    isCompleted,
    isMobileVersion,
    callActions,
    onReviewCall,
    onDelete,
    shouldReview,
  } = props;

  const { expert, expert_request: expertRequest } = consultation;

  const stateDescription = getStateDescription(consultation);

  const requesterId = consultation.requester && consultation.requester.id;

  const showMessageButton = !!(
    // must be a valid, non admin, user
    (
      !isViewerAdmin &&
      user &&
      user.username &&
      requesterId &&
      (permissions.allowed(
        'messaging',
        'start_non_anonymous_chat',
        requesterId
      ) ||
        permissions.allowed('messaging', 'start_anonymous_chat', requesterId))
    )
  );

  const engagementType = isViewerAdmin
    ? engagementTypeLabels[consultation.engagement_type]
    : null;

  const erType =
    isViewerAdmin && expertRequest ? erTypeLabels[expertRequest.er_type] : null;

  return (
    <div className={s.header}>
      {user && (
        <div className={s.photo}>
          <Picture user={user} size={100} />
        </div>
      )}

      <div className={s.headerInfo}>
        <div className={s.status}>
          <FAIcon
            className={s.statusIcon}
            iconSet={stateDescription.iconSet}
            icon={stateDescription.icon}
            color={stateDescription.color}
          />
          <span
            style={{ color: stateDescription.color }}
            className={s.statusText}
          >
            {stateDescription.text}
          </span>
          <ConsultationActions onDelete={onDelete} />
        </div>

        <div className={s.name}>
          <Title {...props} />
        </div>

        {engagementType && (
          <div className={s.engagementType}>{engagementType}</div>
        )}

        {erType && <div className={s.erType}>{erType}</div>}

        {showMessageButton && (
          <SendMessageButton
            userId={user.id}
            style={{ marginTop: 5, marginLeft: isMobileVersion ? 0 : -10 }}
          />
        )}
      </div>

      {(isCompleted || (!isMobileVersion && !!callActions)) && (
        <div className={s.headerActions}>
          {!isMobileVersion && callActions}

          {shouldReview && (
            <Button
              fullWidth
              size="large"
              style={{ marginBottom: 10 }}
              onClick={onReviewCall}
            >
              Review
            </Button>
          )}
          {isCompleted && !isViewerExpert && expert && (
            <div>
              <Link
                href={`${expert.html_url}/request?expertRequestId=${
                  expertRequest ? expertRequest.id : ''
                }`}
              >
                <MaterialButton
                  startIcon={
                    <MaterialIcon icon="cached" style={{ fontSize: 22 }} />
                  }
                  style={{ fontSize: 16 }}
                >
                  Request Again
                </MaterialButton>
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Header;
