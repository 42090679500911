import React from 'react';
import PropTypes from 'prop-types';
import MaterialIcon from '../Icon/MaterialIcon';
import IconButton from '../IconButton/IconButton';
import { useNavigate } from 'react-router-dom';

const EditIcon = ({ onClick, backToLocation, ...props }) => {
  const navigate = useNavigate();
  return (
    <IconButton
      onClick={onClick || (() => navigate(backToLocation))}
      style={{ ...props.style, opacity: 0.3 }}
    >
      <MaterialIcon icon="mode_edit" />
    </IconButton>
  );
};

EditIcon.propTypes = {
  onClick: PropTypes.func,
  backToLocation: PropTypes.string,
};

export default EditIcon;
