import React from 'react';
import { redirect, useLoaderData } from 'react-router-dom';
import { validateEmail } from '../../actions/address';
import { fetchSignupDomain } from '../../actions/domain';
import AwaitingEmailValidation from './AwaitingEmailValidation';
import EmailValidated from './EmailValidated';

const TOKEN_EXPIRED_ERROR = 'GraphQL Error: expired token';

export default [
  {
    path: '/validate_email',
    public: true,
    element: <div>Link expired.</div>,
    async action({ store, query }) {
      const { validate_token: token } = query;
      document.title = 'OnFrontiers';

      if (!token) return redirect('/dashboard');

      try {
        await store.dispatch(validateEmail(token));
      } catch (err) {
        if (err.message === TOKEN_EXPIRED_ERROR) {
          return null;
        }
        throw err;
      }

      return redirect('/email_validated');
    },
  },
  {
    subdomain: '*',
    path: '/awaiting_email_validation',
    public: false,
    element: <AwaitingEmailValidation />,
    async action({ store, subdomain }) {
      document.title = 'Validate Email Address';
      const domain = await store.dispatch(fetchSignupDomain(subdomain));
      if (subdomain && !domain) return redirect('/dashboard');

      const { viewer } = store.getState();
      if (viewer.email && viewer.email.accepted) {
        return redirect('/dashboard');
      }
      return null;
    },
  },
  {
    path: '/email_validated',
    public: false,
    element: <EmailValidated />,
    action: () => {
      document.title = 'Email Validated';
      return null;
    },
  },
];
