import React from 'react';
import { validateToken } from '../../actions/token';
import LayoutPage from '../../components/Layout/LayoutPage';
import { WrapperComponent } from '../../components/WrapperComponent';
import RequestPasswordReset from './RequestPasswordReset';
import SetPassword from './SetPassword';

export default {
  path: '/password_reset',
  element: <WrapperComponent />,
  async action({ store, query }) {
    const { password_token: token } = query;

    let isInvalid = false;
    try {
      if (token) {
        await store.dispatch(validateToken(token));
      }
    } catch (e) {
      isInvalid = true;
    }

    document.title = 'Reset Password | OnFrontiers';
    return (
      <LayoutPage>
        {token ? (
          <SetPassword token={token} isInvalid={isInvalid} />
        ) : (
          <RequestPasswordReset />
        )}
      </LayoutPage>
    );
  },
};
