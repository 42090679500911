import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import WizardFooter from './WizardFooter';
import s from './WizardPage.module.scss';

function WizardPage({
  title,
  subTitle,
  nextLabel,
  nextUrl,
  nextDisabled,
  children,
  onNext,
  titleStyle,
  subTitleStyle,
}) {
  const navigate = useNavigate();
  return (
    <div className={s.root}>
      <header>
        <h2 style={titleStyle} className={s.title}>
          {title}
        </h2>
        <h3 style={subTitleStyle} className={s.subTitle}>
          {subTitle}
        </h3>
      </header>

      {children}

      {(onNext || nextUrl) && (
        <WizardFooter
          onNext={onNext || (() => navigate(nextUrl))}
          nextLabel={nextLabel}
          nextDisabled={nextDisabled}
        />
      )}
    </div>
  );
}

WizardPage.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  nextLabel: PropTypes.string,
  nextUrl: PropTypes.string,
  nextDisabled: PropTypes.bool,
  onNext: PropTypes.func,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.node,
};

export default WizardPage;
