import gql from 'graphql-tag';

export const billingAccountQuery = gql`
  query getBillingAccount($id: String!) {
    billingAccount(id: $id) {
      id
      type
      entity_type
      seat_count
      state
      purchases {
        remaining {
          cents
          currency
        }
        expires_at
      }
      credit_balance {
        cents
        currency
      }
      transactions {
        id
        created_at
        description
        money {
          cents
          currency
        }
        credits {
          cents
          currency
        }
        tracking_code
        state
      }
    }
  }
`;

export const billingGroupQuery = gql`
  query getGroup($id: String!) {
    group(id: $id) {
      billing_account {
        id
      }
    }
  }
`;

export const createPortalURLMutation = `
(
  $accountId: String!
  $path: String!
) {
  createPortalURL(
    account_id: $accountId
    path: $path
  )
}`;

export const getPayoutAccountQuery = gql`
  query getPayoutAccount($userId: String!) {
    payoutAccountByUserID(id: $userId) {
      payouts_enabled
    }
  }
`;

export const createPayoutLoginURLQuery = `
(
  $userId: String!
) {
  createPayoutLoginURL(
    user_id: $userId
  )
}`;

export const createPayoutAccountURLQuery = `
(
  $userId: String!
  $type: PayoutAccountURLType!
) {
  createPayoutAccountURL(
    user_id: $userId
    type: $type
  )
}`;
