// @ts-nocheck
import gql from 'graphql-tag';
import React, { useState, useContext, memo } from 'react';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import withStyles from '@mui/styles/withStyles';
import cx from 'classnames';
import { AppContext } from '../../App';
import Button from '../Button';
import Link from '../Link';
import { money, rate } from '../../core/money';
import { notify } from '../../actions/ui';
import { purchaseCredits } from '../../actions/billing';
import s from './CreditPurchaseForm.module.scss';
import { useApp } from 'access/hooks/useAppContext';

const ensureUserBillingAccountMutation = gql`
  mutation _($userId: String) {
    ensureUserBillingAccount(user_id: $userId) {
      id
    }
  }
`;

const createCheckoutURLMutation = gql`
  mutation _(
    $accountId: String!
    $priceId: String!
    $quantity: Int
    $path: String!
  ) {
    createCheckoutURL(
      account_id: $accountId
      price_id: $priceId
      quantity: $quantity
      path: $path
    )
  }
`;

async function createCheckoutURL(ctx, accountId, priceId, quantity, path) {
  const { graphql } = ctx;
  if (!accountId) {
    const result = await graphql.send(ensureUserBillingAccountMutation);
    accountId = result.ensureUserBillingAccount.id;
  }
  const result = await graphql.send(createCheckoutURLMutation, {
    accountId,
    priceId,
    quantity,
    path,
  });
  return result.createCheckoutURL;
}

interface Viewer {
  id: string;
}

interface RawMoney {
  cents: number;
  currency: string;
}

interface RawPrice {
  id: string;
  credits: RawMoney;
  money: RawMoney;
}

interface BillingState {
  prices: RawPrice[];
}

interface RootState {
  billing: BillingState;
  viewer: Viewer;
}

interface Money {
  cents: number;
  currency: string;
  formatted: (any?) => string;
  lessThan: (other: Money) => boolean;
  equal: (other: Money) => boolean;
  subtract: (other: Money) => Money;
  times: (other: number) => Money;
  div: (other: Money) => number;
}

interface Rate {
  baseCurrency: string;
  quote: Money;
  apply: (amount: Money) => Money;
}

interface Price {
  id: string;
  credits: Money;
  rate: Rate;
}

interface Account {
  id: string;
  credit_balance: Money;
  credit_price?: RawPrice;
}

type CreditPurchaseFormProps = {
  prices: Price[];
  account: Account;
  purchaseDonePath: string;
};

export const CreditPurchaseFormPure = memo(
  ({ prices, account, purchaseDonePath }: CreditPurchaseFormProps) => {
    const appContext = useApp();

    const [{ priceId, customAmount }, selectOption] = useState<{
      priceId: string;
      customAmount?: money;
    }>({
      priceId: prices[0].id,
      customAmount: undefined,
    });

    const balance =
      account?.credit_balance || money({ currency: 'OFC', cents: 0 });

    const unitPrice = prices[0];
    const price = prices.find((p) => p.id === priceId) || unitPrice;
    const amount = priceId === 'custom' ? customAmount : price?.credits;

    const nominalCost = amount && unitPrice.rate.apply(amount);
    const actualCost = amount && price.rate.apply(amount);
    const discount =
      actualCost && nominalCost && actualCost.subtract(nominalCost);
    const discountPercentage =
      discount && Math.round(discount.times(-1).div(nominalCost) * 100);

    const step = unitPrice.credits.cents / 100;

    const handleSelect = (event) => {
      selectOption({ priceId: event.target.value, customAmount });
    };

    const handlePurchaseClick = () => {
      const quantity = amount && amount.div(price.credits);
      const checkoutPriceId = priceId === 'custom' ? unitPrice.id : priceId;
      createCheckoutURL(
        appContext,
        account.id,
        checkoutPriceId,
        quantity,
        purchaseDonePath
      ).then((url) => {
        window.location = url;
      });
    };

    return (
      <div>
        <div className={s.balance}>
          Your current balance is&nbsp;
          <span
            className={cx({
              [s.balanceWarning]: balance.cents <= 0,
            })}
          >
            {money(balance).formatted()}&nbsp;
            {balance.cents === 100 ? 'credit' : 'credits'}
          </span>
          .
        </div>

        <div className={s.amount}>
          <RadioGroup value={priceId} onChange={handleSelect}>
            {prices.map((p) => (
              <FormControlLabel
                key={p.id}
                value={p.id}
                control={<Radio />}
                style={{ marginBottom: 10 }}
                label={`${p.credits.formatted()} ${
                  p.credits.cents === 100 ? 'Credit' : 'Credits'
                }: ${p.rate.quote.formatted({ omitEmptyCents: true })}/credit`}
              />
            ))}

            <div className="MuiFormControlLabel-root">
              <Radio
                key="custom"
                value="custom"
                // style={{ display: 'inline-block', width: 100 }}
              />
              <TextField
                id="customCredits"
                placeholder="Custom amount"
                value={
                  customAmount === undefined ? '' : customAmount.cents / 100
                }
                margin="none"
                fullWidth={false}
                type="number"
                inputProps={{
                  min: 1,
                  max: 1000,
                  step,
                  style: { width: '200px' },
                }}
                onClick={() => {
                  selectOption({
                    priceId: 'custom',
                    customAmount,
                  });
                }}
                onChange={(e) => {
                  e.stopPropagation();
                  const s = e.target.value || '';
                  if (s.length > 3 || s.includes('-')) return;
                  const value = Math.max(0, Math.min(1000, parseInt(s)));
                  const credits = Number.isNaN(value)
                    ? undefined
                    : money(value * 100, 'OFC');
                  selectOption({ priceId: 'custom', customAmount: credits });
                }}
              />
            </div>
          </RadioGroup>
        </div>

        <div className={s.order}>
          <div className={s.orderTitle}>Your Order</div>
          <hr />
          <div className={s.orderRow}>
            <div className={s.orderCell}>
              {amount ? amount.formatted() : '0'}&nbsp;
              {amount && amount.cents === 100 ? 'Credit' : 'Credits'}
            </div>
            <div className={s.orderShortCell}>
              {nominalCost ? nominalCost.formatted() : '$0.00'}
            </div>
          </div>
          <div
            className={s.orderRow}
            style={{
              visibility: discountPercentage ? 'visible' : 'hidden',
            }}
          >
            <div className={cx(s.orderCell, s.orderDiscount)}>
              {discountPercentage}% Discount
            </div>
            <div className={s.orderShortCell}>
              {discount && discount.formatted()}
            </div>
          </div>
          <hr />
          <div className={s.orderRow}>
            <div className={s.orderCell}>Total</div>
            <div className={s.orderShortCell}>
              {actualCost ? actualCost.formatted() : '$0.00'}
            </div>
          </div>
        </div>
        <div className={s.footer}>
          <div className={cx(s.footerComponent, s.footerCard)} />
          <Button
            className={s.footerComponent}
            disabled={
              !actualCost ||
              !amount ||
              amount.cents === 0 ||
              actualCost.cents > 99999999
            }
            onClick={handlePurchaseClick}
            primary
            type="submit"
            size="large"
            label="Purchase"
          />
        </div>
        <div className={s.questions}>
          For large purchases, please contact our{' '}
          <Link to="mailto:hello@onfrontiers.com">customer care team</Link>.
        </div>
        {/* For reference: there is PaymentUpdate component in git history */}
      </div>
    );
  }
);

export default connect(
  (state: RootState, props: CreditPurchaseFormProps) => {
    if (!state.billing) throw new Error('billing not found in state');
    const { account } = props;
    const { prices: rawPrices } = state.billing;

    // check and prepare prices
    if (!rawPrices) throw new Error('billing prices not found in state');

    const prices = rawPrices
      .map((rawPrice) => {
        const quote =
          account && account.credit_price && money(rawPrice.money).cents
            ? account.credit_price.money
            : money(
                (100 * rawPrice.money.cents) / rawPrice.credits.cents,
                rawPrice.money.currency
              );

        return {
          id: rawPrice.id,
          credits: money(rawPrice.credits),
          rate: rate({
            baseCurrency: 'OFC',
            quote: money(quote),
          }),
        };
      })
      .filter((p) => p.credits.cents && p.credits.currency === 'OFC')
      .filter((p) => p.rate.quote.cents)
      .sort((a, b) => a.credits.cents - b.credits.cents);

    if (!prices.length) throw new Error('at least one price must be supplied');

    const { viewer } = state;

    return { viewer, account, prices };
  },
  {
    purchaseCredits,
    notify,
  }
)(withStyles(s)(CreditPurchaseFormPure));
