import React from 'react';
import LayoutPage from '../../components/Layout/LayoutPage';
import { WrapperComponent } from '../../components/WrapperComponent';
import ChangePassword from './ChangePassword';
import { redirect } from 'react-router-dom';

export default {
  path: '/change_password',
  element: <WrapperComponent />,
  async action({ store, query }) {
    const { viewer } = store.getState();

    if (!viewer.id) {
      return redirect('/login');
    }

    document.title = 'Change Password';
    return (
      <LayoutPage
        hideSearch
        showNewRequest={false}
        showReviewConsultation={false}
      >
        <ChangePassword next={query.next} />
      </LayoutPage>
    );
  },
};
