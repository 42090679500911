import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import MaterialButton from '@mui/material/Button';
import Logo from '../Logo';
import Form from '../Form';
import Link from '../Link';
import ConferenceCall from '../ConferenceCall';
import Nav from './Nav';
import MaterialIcon from '../Icon/MaterialIcon';
import IconButton from '../IconButton';
import Button from '../Button/Button';
import MarketingNav from '../MarketingLayout/MarketingNav';
import {
  borderColor,
  darkBlue,
  grey300,
  teal500,
  white,
} from '../../core/colors';
import { toQueryString } from '../../core/search';
import { setLoadingProgress } from '../../actions/loading';
import TeamAccountPromoButton from '../TeamAccountPromo/TeamAccountPromoButton';
import PictureNav from './PictureNav';
import { useApp } from '../../hooks/useAppContext';
import { useNavigate } from 'react-router-dom';

const useSearchStyles = makeStyles((theme) => ({
  search: {
    borderLeft: `1px solid ${grey300}`,
    padding: '0 20px',
    height: 70,
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      border: 0,
      margin: 0,
      height: 53,
      width: '100%',
      boxSizing: 'border-box',
      borderBottom: `5px solid ${darkBlue}`,
      flexGrow: 'initial',
      paddingRight: 0,
      backgroundColor: white,
      zIndex: 1082,
    },
  },
  searchIcon: {
    color: `${borderColor} !important`,
    fontSize: 30,
    marginRight: '6px !important',
  },
}));

function Search({ viewer, query }) {
  const { store } = useApp();
  const navigate = useNavigate();
  const s = useSearchStyles();

  const [text, setText] = useState(query?.text ?? '');

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    const newText = query?.text ?? '';
    if (newText !== text) {
      setText(newText);
    }
  }, [query]);

  const handleChange = ({ target: { value } }) => {
    setText(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    store.dispatch(setLoadingProgress(10));
    navigate(
      `/search?${toQueryString({
        ...query,
        text,
        sort: undefined,
        ascending: undefined,
      })}`
    );
  };

  const searchHintText = useMemo(() => {
    const groups = viewer.groups
      ? viewer.groups.filter((g) => g.internal_network)
      : [];

    return groups.length === 1
      ? `Search the ${groups[0].name} Knowledge Network`
      : 'Search Your Knowledge Network';
  }, [viewer.groups]);

  return (
    <Form onSubmit={handleSubmit} className={s.search} id="searchForm">
      {!mobile && <MaterialIcon icon="search" className={s.searchIcon} />}

      <TextField
        id="search"
        name="q"
        placeholder={searchHintText}
        InputProps={{
          disableUnderline: true,
          style: { fontSize: 20 },
        }}
        value={text}
        onChange={handleChange}
        fullWidth
        autoFocus={mobile}
      />

      {mobile && (
        <MaterialButton type="submit" style={{ color: darkBlue, fontSize: 20 }}>
          Go
        </MaterialButton>
      )}
    </Form>
  );
}

Search = connect((state) => ({
  viewer: state.viewer,
  query: state.search.query,
}))(Search);

const useHeaderStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('lg')]: {
      position: 'fixed' /* Sticky header */,
      width: '100%',
      zIndex: 1081,
    },
  },
  mainRow: (props) => ({
    boxSizing: 'border-box',
    height: 70,
    flex: '0 0 70px' /* 2 */,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 40px',
    backgroundColor: white,
    fontSize: 0,
    ...(props.border ? { borderBottom: `1px solid ${grey300}` } : {}),
    [theme.breakpoints.down('lg')]: {
      padding: '0 20px',
      height: 60,
      flex: '0 0 60px',
      zIndex: 1081,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px',
    },
  }),
  logo: {
    marginRight: 40,
    [theme.breakpoints.down('lg')]: {
      marginRight: 0,
    },
  },
  headerRight: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function Header({
  border = true,
  viewer,
  hideSearch,
  showNav,
  showNewRequest,
  selected,
  marketingLinks,
}) {
  const [searchOpen, setSearchOpen] = useState(false);

  const s = useHeaderStyles({ border });

  const handleSearchToggle = () => {
    setSearchOpen((searchOpen) => !searchOpen);
  };

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('lg'));

  const iconSize = mobile ? 40 : 30;
  const pictureSize = mobile ? 40 : 50;

  const showSearch = !hideSearch;

  return (
    <div className={s.root}>
      <ConferenceCall />

      <div className={s.mainRow}>
        <div className={s.logo}>
          <Link
            force
            to={viewer.id ? '/dashboard' : '/'}
            style={{ lineHeight: 0 }}
          >
            <Logo user={viewer} dark />
          </Link>
        </div>

        {showSearch && viewer.id && (!mobile || (mobile && searchOpen)) && (
          <Search />
        )}

        {viewer.id ? (
          <div className={s.headerRight}>
            {showNewRequest &&
              (mobile ? (
                <TeamAccountPromoButton
                  component={IconButton}
                  style={{ marginLeft: 10, marginRight: 10 }}
                >
                  <MaterialIcon
                    icon="person_add"
                    color={teal500}
                    size={iconSize}
                  />
                </TeamAccountPromoButton>
              ) : (
                <TeamAccountPromoButton
                  component={Button}
                  size="small"
                  label="Find Experts"
                  style={{ marginRight: 15 }}
                />
              ))}

            {showSearch && mobile && (
              <IconButton
                style={{ marginRight: 10 }}
                onClick={handleSearchToggle}
              >
                <MaterialIcon icon="search" color={darkBlue} size={iconSize} />
              </IconButton>
            )}

            <PictureNav
              size={pictureSize}
              viewer={viewer}
              id="userHeadDashboard"
            />
          </div>
        ) : (
          <MarketingNav links={marketingLinks} theme="light" />
        )}
      </div>
      {showNav && <Nav selected={selected} />}
    </div>
  );
}

Header = connect((state) => ({
  viewer: state.viewer,
}))(Header);

export default Header;
