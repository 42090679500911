import moment from 'moment-timezone';
import ActionTypes from '../core/ActionTypes';
import { renderPermKey } from '../core/permissions';

const { PERMISSION__SET } = ActionTypes;

export function fetchPermission(userId, service, action, resource) {
  return async (dispatch) => {
    const permissions = await dispatch(
      fetchPermissions(userId, [
        {
          service,
          action,
          resource,
        },
      ])
    );
    return permissions?.[0]?.allowed || false;
  };
}

export function fetchPermissions(userId, requests = []) {
  return async (dispatch, getState, { graphql }) => {
    const cachedPerms = [];

    for (let i = 0; i < requests.length; i++) {
      const perm = requests[i];
      const { service, action, resource } = perm;
      if (!service || !action || !resource) {
        throw new Error('permission request incomplete');
      }
      const key = renderPermKey(service, action, resource);
      const cache = getState().permissions?.[userId];
      const cached = cache && cache[key];
      if (cached && moment().isBefore(cached.expires)) cachedPerms.push(cached);
      else break;
    }

    if (cachedPerms.length === requests.length) {
      return cachedPerms;
    }

    const resp = await graphql.query(
      `
      query fetchPermissions(
        $userId: String
        $requests: [PermissionRequestInput]!
      ) {
        permissions(
          user_id: $userId
          requests: $requests
        ) {
          service
          action
          resource
          allowed
        }
      }
    `,
      {
        userId,
        requests,
      }
    );

    const perms = resp.permissions;
    if (!perms || perms.length !== requests.length) {
      const err = new Error('Invalid permissions received');
      err.perms = perms;
      throw err;
    }

    perms.forEach((perm) => {
      const { service, action, resource } = perm;
      const key = renderPermKey(service, action, resource);
      dispatch({
        type: PERMISSION__SET,
        userId,
        key,
        allowed: perm.allowed,
      });
    });

    return perms;
  };
}
