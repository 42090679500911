import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import Link from '../Link';
import Button from '../Button';
import TransactionList from '../TransactionList';
import { money } from '../../core/money';
import { formatDate, formatCreditsText } from '../../core/util';
import s from './CreditSettings.module.scss';
import { useNavigate } from 'react-router-dom';

const CreditSettings = (props) => {
  const navigate = useNavigate();
  const { accountType, memberNotOwner, seatCount } = props;

  const account = props.account || {};
  const transactions = account.transactions || [];
  const balance =
    account.credit_balance || money({ currency: 'OFC', cents: 0 });

  const entityType = account.entity_type || 'organization';

  const handlePurchaseCredits = () => {
    const { purchasePath } = props;
    navigate(purchasePath);
  };

  const RenderExpiries = () => {
    const account = props.account || {};
    const expiries = (account.purchases || []).filter(
      (p) => money(p.remaining.cents).cents && p.expires_at
    );

    if (!expiries.length) return 'N/A';

    return (
      <div>
        {expiries.map((e) => (
          <div>
            {formatCreditsText(money(e.remaining).cents)} on&nbsp;
            {formatDate(e.expires_at)}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div className={s.details}>
        <div className={s.detailsRow}>
          <div className={s.detailsCol1}>Balance</div>
          <div
            className={cx(s.detailsCol2, {
              [s.balanceWarning]: balance.cents <= 0,
            })}
          >
            {money(balance).formatted()}{' '}
            {balance.cents === '100' ? 'Credit' : 'Credits'}
          </div>
          <div className={s.detailsCol3}>
            {memberNotOwner ? (
              <div className={s.balanceNotOwnerInstructions}>
                Contact Team Owner to purchase credits
              </div>
            ) : (
              <Button
                secondary
                size="small"
                label="Purchase Credits"
                onClick={handlePurchaseCredits}
              />
            )}
          </div>
        </div>
        {accountType && (
          <div className={s.detailsRow}>
            <div className={s.detailsCol1}>Account Type</div>
            <div className={s.detailsCol2}>{accountType}</div>
          </div>
        )}
        {entityType === 'organization' && (
          <div className={s.detailsRow}>
            <div className={s.detailsCol1}>Seat Count</div>
            <div className={s.detailsCol2}>{seatCount || 0}</div>
          </div>
        )}
        <div className={s.detailsRow}>
          <div className={s.detailsCol1}>Credit Expiry</div>
          <div className={s.detailsCol2}>
            <RenderExpiries />
          </div>
        </div>
      </div>
      <TransactionList
        transactions={transactions}
        amountColumn={{
          header: 'Credits Amount',
          field: 'credits',
        }}
      />
      <div className={s.questions}>
        Questions about credit usage? Contact our{' '}
        <Link to="https://try.onfrontiers.com/knowledge">
          customer care team
        </Link>
        .
      </div>
    </div>
  );
};

export default connect((state) => ({
  viewer: state.viewer,
}))(CreditSettings);
