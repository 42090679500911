import React from 'react';
import cx from 'classnames';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import {
  white,
  red500,
  teal500,
  darkBrown,
  borderColor,
} from '../../core/colors';

const colorTypes = {
  success: teal500,
  error: red500,
};

const useStyles = makeStyles({
  root: {
    padding: 20,
    marginBottom: 30,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    backgroundColor: white,
  },
  alert: {
    boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
    fontWeight: 'bold',
    fontSize: 20,
    color: white,
  },
  notification: {
    border: `1px solid ${borderColor}`,
    color: darkBrown,
    fontSize: 16,
  },
  message: {
    padding: 0,
    width: '100%',
  },
  icon: {
    padding: 0,
  },
});

function Alert({
  children,
  type,
  preset = 'alert',
  messageClassName,
  style,
  onRequestClose,
  center,
  backgroundColor,
  ...other
}) {
  const s = useStyles();

  return (
    <MuiAlert
      icon={false}
      {...other}
      style={{
        textAlign: center ? 'center' : undefined,
        backgroundColor: backgroundColor || colorTypes[type],
        ...style,
      }}
      classes={{
        root: cx(s.root, s[preset]),
        message: cx(s.message, messageClassName),
        icon: s.icon,
      }}
      action={
        onRequestClose && (
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onRequestClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )
      }
    >
      {children}
    </MuiAlert>
  );
}

export default Alert;
