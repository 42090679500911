import React from 'react';
import TeamAboutPage from './TeamAboutPage';
import { fetchGroup } from '../../actions/group';
import { fetchSiteSettings } from '../../actions/siteSettings';
import { redirect } from 'react-router-dom';

export default {
  path: '/team/:slug/about',
  element: <TeamAboutPage />,
  async action({ store, params }) {
    const group = await store.dispatch(
      fetchGroup(params.slug, {
        aboutPage: true,
        publicData: true,
      })
    );

    if (!group || !group.about_page) {
      return redirect('/404');
    }

    await store.dispatch(fetchSiteSettings());

    document.title = `Join ${group.name}`;
    return {
      disableIndexing: !group.about_page.search_engine_index,
      group: group,
    };
  },
};
