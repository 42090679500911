import Grid from '@mui/material/Grid';
import React from 'react';
import { useLoaderData } from 'react-router-dom';
import Button from '../../components/Button';
import Link from '../../components/Link';
import Layout from '../../components/MarketingLayout';
import Hero from '../../components/MarketingLayout/MarketingHero';
import MarketingSection from '../../components/MarketingSection';
import MediaQuery from '../../components/MediaQuery';
import SplashTitle from '../../components/SplashTitle';
import { SCREEN_MD } from '../../constants';
import { darkBrown, sand } from '../../core/colors';
import s from './About.module.scss';

function TeamMember({
  name,
  title,
  profile_slug: profile,
  picture_url: picture,
}) {
  return (
    <Link to={profile && `/profile/${profile}`} className={s.teamMember}>
      <img alt="" className={s.teamMemberImage} src={picture} />
      <h4 className={s.teamMemberName}>{name}</h4>
      <div className={s.teamMemberTitle}>{title}</div>
    </Link>
  );
}

function Vision() {
  return (
    <MediaQuery maxWidth={SCREEN_MD}>
      {(isMd) => (
        <MarketingSection
          padding={isMd ? 40 : 60}
          style={{ backgroundColor: sand }}
        >
          <div className={s.vision}>
            <div className={s.visionImage}>
              <img alt="" src={require('./connections.svg')} />
            </div>
            <div className={s.visionContent}>
              <h2 className={s.visionTitle}>
                It is costly and time consuming to access expertise you need
                when you need it. We are changing this.
              </h2>
              <p>
                As problems we face become more intricate, and the pace and
                complexity of work becomes more demanding, we need a better
                medium for connecting and learning from one another.
              </p>
              <p>
                Most questions we ask have already been answered by others.
                Engaging people in the know at the right time avoids wasted
                effort and delivers a better result.
              </p>
              <p>
                We envision a world where people from different backgrounds can
                instantly be introduced, engage, collaborate, and take action.
                In this world, we as individuals become more powerful, our
                organizations more impactful, and we collectively find solutions
                we could not before.
              </p>
            </div>
          </div>
        </MarketingSection>
      )}
    </MediaQuery>
  );
}

const About = () => {
  const sections = useLoaderData();
  return (
    <Layout>
      <Hero style={{ minHeight: 480 }} backgroundColor={darkBrown}>
        <SplashTitle>
          Our purpose is to build a more intelligent world
        </SplashTitle>
      </Hero>

      <Vision />

      <MarketingSection
        titleStyle={{ maxWidth: 850 }}
        title="Our team is changing the way professionals connect and learn from one another"
      >
        <h3 className={s.sectionTitle}>Core Team</h3>

        <Grid container spacing={4}>
          {sections
            .find((x) => x.name === 'members')
            .members.map((m) => (
              <Grid
                key={m.profile}
                container
                justifyContent="center"
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <TeamMember {...m} />
              </Grid>
            ))}
        </Grid>
      </MarketingSection>

      <MarketingSection style={{ backgroundColor: sand }}>
        <h3 className={s.sectionTitle}>Investors, Advisors, and Board</h3>

        <Grid container spacing={4}>
          {sections
            .find((x) => x.name === 'advisors')
            .members.map((m) => (
              <Grid
                key={m.profile}
                container
                justifyContent="center"
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <TeamMember {...m} />
              </Grid>
            ))}
        </Grid>
      </MarketingSection>

      <MarketingSection className={s.hiring}>
        <h2 className={s.hiringTitle}>We’re hiring!</h2>
        <p className={s.hiringText}>
          Are you excited about innovation, big data, knowledge and
          marketplaces?
          <br />
          Do you want to help build the world’s leading platform for on-demand
          expertise?
        </p>
        <Link href="/careers">
          <Button className={s.hiringAction} size="large">
            View Careers
          </Button>
        </Link>
      </MarketingSection>
    </Layout>
  );
};

export default About;
