import React, { useState } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm, Field } from 'redux-form';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import { TextField, Checkbox } from '../FormAdapters';
import CustomCheckbox from '../Checkbox';
import Dialog from '../Dialog';
import Link from '../Link';
import StarRating from './StarRating';
import { notify, hideMessage } from '../../actions/ui';
import {
  getEngagementTimestamp,
  reviewConsultation,
} from '../../actions/consultation';
import { formatDateTime } from '../../core/util';
import { getTimezoneDescription } from '../../core/time';

const useStyles = makeStyles(() => ({
  callDetail: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  detailTitle: {
    color: 'darkBrown',
    marginBottom: '5px',
  },
  detailContent: {
    color: 'black',
    fontSize: '18px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  detailContentInline: {
    flexDirection: 'row',
    alignItems: 'initial',
  },
  timezone: {
    color: 'darkGray',
  },
  detailLink: {
    flexDirection: 'row',
    textAlign: 'flex-start',
  },
}));

function ReviewConsultation({
  open,
  user,
  viewer,
  reviewConsultation,
  isViewerExpert,
  consultation,
  consultationLink,
  hideMessage,
  match_experience_rating: matchExperienceRating,
  uselfulness_rating: uselfulnessRating,
  communication_rating: communicationRating,
  connection_quality_rating: connectionQualityRating,
  expert_usefulness_rating: expertUsefulnessRating,
  time_savings_feedback: timeSavings,
  improved_bid_confidence_feedback: improvedBidConfidence,
  partner_identification_feedback: partnerIdentification,
  cost_savings_feedback: costSavings,
  new_opportunity_identification_feedback: newOpportunityIdentification,
  other_feedback: otherFeedback,

  onConfirm,
  onCancel,

  notify,
  change,
  reset,
  handleSubmit: handleSubmitProp,
}) {
  const s = useStyles();
  const [otherTextLength, setOtherTextLength] = useState(0);

  const handleRatingChange = (nextValue, _, name) => {
    change(name, nextValue);
  };

  const handleCheckboxChange = (event) => {
    change(event.target.name, event.target.checked);
  };

  const resetRatings = () => {
    change('match_experience_rating', 0);
    change('uselfulness_rating', 0);
    change('communication_rating', 0);
    change('connection_quality_rating', 0);
    change('expert_usefulness_rating', 0);
  };

  const handleSubmit = (values) => {
    hideMessage();

    return reviewConsultation({
      ...values,
      consultation_id: consultation.id,
    }).then(
      () => {
        notify('Thanks for your feedback!', 'success');
        reset();
        resetRatings();
        if (onConfirm) {
          onConfirm();
        }
      },
      () => {
        notify('An error occurred when saving review.', 'error');
      }
    );
  };

  const engagementTime = getEngagementTimestamp(consultation);
  const subTitle = (
    <div>
      {engagementTime && (
        <div className={s.callDetail}>
          <div className={s.detailTitle}>Date & Time</div>
          <div
            className={cx(s.detailContent, { [s.detailContentInline]: true })}
          >
            {formatDateTime(consultation.starts_at, viewer.timezone)}&nbsp;
            <span className={s.timezone}>
              {getTimezoneDescription(viewer.timezone)}
            </span>
          </div>
        </div>
      )}
      {consultationLink && (
        <div className={s.detailLink}>
          <Link to={consultation.html_url}>Show details</Link>
        </div>
      )}
    </div>
  );

  return (
    <Dialog
      open={open}
      title={`How was your call${user ? ` with ${user.name}?` : '?'}`}
      subTitle={subTitle}
      subTitleStyle={{ marginTop: 15 }}
      confirmLabel="Submit"
      onClose={onCancel}
      onCancel={onCancel}
      onConfirm={handleSubmitProp(handleSubmit)}
    >
      {!isViewerExpert && (
        <>
          <StarRating
            style={{ marginTop: 30 }}
            title="How useful was the information the expert provided for this opportunity?"
            onStarClick={handleRatingChange}
            name="expert_usefulness_rating"
            value={expertUsefulnessRating}
          />
          <div style={{ marginTop: 30 }}>
            What Value did you get from this call? Check all that apply
          </div>
          <CustomCheckbox
            name="time_savings_feedback"
            label="Time Savings (Increase speed to next decision gate)"
            checked={timeSavings}
            onChange={handleCheckboxChange}
          />
          <CustomCheckbox
            name="improved_bid_confidence_feedback"
            label="Improved Bid Confidence (The information provided gave me an edge I needed to improve my Pwin)"
            checked={improvedBidConfidence}
            onChange={handleCheckboxChange}
          />
          <CustomCheckbox
            name="partner_identification_feedback"
            label="Partner Identification (Was able to identify Prime or Sub Prime Contractors I could Partern with)"
            checked={partnerIdentification}
            onChange={handleCheckboxChange}
          />
          <CustomCheckbox
            name="cost_savings_feedback"
            label="Cost Savings (This call saved me from having to engage a consultant or consulting firm on a larger engagement to get the same info)"
            checked={costSavings}
            onChange={handleCheckboxChange}
          />
          <CustomCheckbox
            name="new_opportunity_identification_feedback"
            label="New Opportunity Identification (This call helped me find additional opportunities to bid on)"
            checked={newOpportunityIdentification}
            onChange={handleCheckboxChange}
          />
          <CustomCheckbox
            name="other_feedback"
            label="Other"
            checked={otherFeedback}
            onChange={handleCheckboxChange}
          />

          {otherFeedback && (
            <Field
              component={TextField}
              multiline
              fullWidth
              name="other_text_feedback"
              label="Other"
              maxLength={1000}
            />
          )}
        </>
      )}
      {isViewerExpert ? (
        <StarRating
          style={{ marginTop: 30 }}
          title="Match for my Experience"
          onStarClick={handleRatingChange}
          name="match_experience_rating"
          value={matchExperienceRating}
        />
      ) : (
        <StarRating
          style={{ marginTop: 30 }}
          title="Match for my Request"
          onStarClick={handleRatingChange}
          name="uselfulness_rating"
          value={uselfulnessRating}
        />
      )}

      <StarRating
        title="Communication"
        onStarClick={handleRatingChange}
        name="communication_rating"
        value={communicationRating}
      />

      <StarRating
        title="Connection Quality"
        onStarClick={handleRatingChange}
        name="connection_quality_rating"
        value={connectionQualityRating}
      />

      {!isViewerExpert && (
        <Field
          component={TextField}
          multiline
          fullWidth
          name="review"
          placeholder="This will be posted publicly on the Expert’s profile"
          label="Write a Review"
        />
      )}

      <Field
        component={TextField}
        multiline
        fullWidth
        name="target_feedback"
        placeholder={`This will only be seen by ${
          isViewerExpert ? 'the Client' : 'your Expert'
        }`}
        label={`Private Feedback to ${isViewerExpert ? 'Client' : 'Expert'}`}
      />

      <Field
        component={TextField}
        multiline
        fullWidth
        name="onfrontiers_feedback"
        placeholder="This will only be seen by OnFrontiers"
        label="Private Feedback"
      />
    </Dialog>
  );
}

ReviewConsultation = reduxForm()(ReviewConsultation);

ReviewConsultation = connect(
  (state, ownProps) => {
    const form = ownProps.form || 'reviewConsultation';
    const selector = formValueSelector(form);
    return {
      viewer: state.viewer,
      form,
      initialValues: {
        uselfulness_rating: ownProps.initialUsefulnessRating,
        match_experience_rating: ownProps.initialMatchExperienceRating,
      },
      match_experience_rating: selector(state, 'match_experience_rating'),
      uselfulness_rating: selector(state, 'uselfulness_rating'),
      communication_rating: selector(state, 'communication_rating'),
      connection_quality_rating: selector(state, 'connection_quality_rating'),
      expert_usefulness_rating: selector(state, 'expert_usefulness_rating'),
      time_savings_feedback: selector(state, 'time_savings_feedback'),
      improved_bid_confidence_feedback: selector(
        state,
        'improved_bid_confidence_feedback'
      ),
      partner_identification_feedback: selector(
        state,
        'partner_identification_feedback'
      ),
      cost_savings_feedback: selector(state, 'cost_savings_feedback'),
      new_opportunity_identification_feedback: selector(
        state,
        'new_opportunity_identification_feedback'
      ),
      other_feedback: selector(state, 'other_feedback'),
    };
  },
  {
    reviewConsultation,
    notify,
    hideMessage,
  }
)(ReviewConsultation);

export default ReviewConsultation;
