import React, { useState, useEffect, memo } from 'react';
import MuiTextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import { Field as FinalField, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash.isequal';
import IconButton from '@mui/material/IconButton';
import CustomSelect from '../Select';
import CustomPhoneInput from '../PhoneInput';
import CustomCheckbox from '../Checkbox';
import CustomRadioGroup from '../RadioGroup';
import CustomFileUpload from '../FileUpload';
import CustomSelectProfile from '../SelectProfile';
import CustomSelectUser from '../SelectUser';
import { orange500, darkGray } from '../../core/colors';
import { PasswordHelpInputAdornment } from '../PasswordHelp/PasswordHelp';
import FAIcon from '../Icon/FAIcon';

const useStyles = makeStyles({
  warning: {
    '& .MuiInput-underline:before': {
      borderBottomColor: orange500,
    },
    '& .MuiInput-underline:after': {
      transform: 'scaleX(1)',
      borderBottomColor: orange500,
    },
  },
});

function areEqual(prevProps, nextProps) {
  // https://github.com/final-form/react-final-form/issues/598
  // input and meta are never the same object, causing unnecessary renders,
  // so we compare them separately
  const { input: prevInput, meta: prevMeta, ...prevOther } = prevProps;
  const { input: nextInput, meta: nextMeta, ...nextOther } = nextProps;
  return (
    isEqual(prevInput, nextInput) &&
    isEqual(prevMeta, nextMeta) &&
    isEqual(prevOther, nextOther)
  );
}

export const helperText = (meta, props) => {
  const classes = useStyles();

  const shouldDisplay = meta.touched || meta.submitFailed;
  const hasError = (shouldDisplay && !!meta.error) || !!meta.submitError;
  const warning = meta.warning || (meta.data && meta.data.warning);
  const hasWarning = shouldDisplay && !hasError && !!warning;
  const helperText = shouldDisplay
    ? meta.error || meta.submitError || warning
    : undefined;

  const rootClass = props && props.classes && props.classes.root;
  return {
    helperText,
    error: hasError,
    FormHelperTextProps: {
      style: { color: hasWarning ? orange500 : undefined },
    },
    classes: {
      root: cx(rootClass, { [classes.warning]: hasWarning }),
    },
  };
};

export const TextField = memo(
  ({ changeOnBlur = true, inputType, input, maxLength = 0, meta, ...rest }) =>
    changeOnBlur ? (
      <ChangeOnBlurTextField
        input={input}
        meta={meta}
        type={inputType}
        {...rest}
        {...characterCounter(maxLength)}
      />
    ) : (
      <MuiTextField
        type={inputType}
        {...input}
        {...rest}
        {...helperText(meta, rest)}
      />
    ),
  areEqual
);

export const characterCounter = (maxCharacters = 0) => {
  if (maxCharacters > 0) {
    const [otherTextLength, setOtherTextLength] = useState(0);
    const handleKeyUp = (event) => {
      const textLength = event.target.value.length;
      setOtherTextLength(
        textLength > maxCharacters ? maxCharacters : textLength
      );
    };
    return {
      onKeyUp: handleKeyUp,
      helperText: otherTextLength + '/' + maxCharacters + ' characters',
      inputProps: { ...{ maxLength: maxCharacters } },
    };
  } else {
    return {};
  }
};

const ChangeOnBlurTextField = ({ input, meta, ...rest }) => {
  const [value, setValue] = useState(input.value);

  useEffect(() => {
    if (value !== input.value) setValue(input.value);
  }, [input.value]);

  return (
    <MuiTextField
      {...rest}
      {...helperText(meta, rest)}
      name={input.name}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => {
        input.onChange(e);
        input.onBlur(e);
      }}
      helperText={rest.helperText}
    />
  );
};

export const Select = memo(
  ({ autocomplete, input, meta, TextFieldProps = {}, ...rest }) => (
    <CustomSelect
      autocomplete={autocomplete}
      {...input}
      {...rest}
      {...(autocomplete
        ? { TextFieldProps: { ...helperText(meta, rest), ...TextFieldProps } }
        : helperText(meta, rest))}
    />
  ),
  areEqual
);

export const Checkbox = memo(
  ({ input, meta, ...rest }) => (
    <CustomCheckbox {...input} {...rest} {...helperText(meta, rest)} />
  ),
  areEqual
);

export const RadioGroup = memo(
  ({ input, meta, ...rest }) => (
    <CustomRadioGroup {...input} {...rest} {...helperText(meta, rest)} />
  ),
  areEqual
);

export const PhoneInput = memo(
  ({ input, meta, ...rest }) => (
    <CustomPhoneInput {...input} {...rest} {...helperText(meta, rest)} />
  ),
  areEqual
);

export const FileUpload = memo(
  ({ input, meta, ...rest }) => (
    <CustomFileUpload {...input} {...rest} {...helperText(meta, rest)} />
  ),
  areEqual
);

// Note that this adapter only supports profile select and not free-form email
// input.
export const SelectProfile = memo(({ input, meta, multiple, ...rest }) => {
  const defaultValue = rest.value;
  const [value, setValue] = useState(defaultValue);

  const onChange = (newValue) => {
    setValue(newValue);
    if (multiple) {
      input.onChange(newValue ? newValue.map((v) => v.value.id) : []);
    } else {
      input.onChange(newValue ? newValue.value.id : null);
    }
  };

  const shouldDisplay = (meta.touched && meta.modified) || meta.submitFailed;

  return (
    <CustomSelectProfile
      {...rest}
      onChange={onChange}
      value={value}
      errorText={shouldDisplay ? meta.error : undefined}
      menuStyle={{ maxHeight: 300 }}
    />
  );
}, areEqual);

// Note that this adapter only supports profile select and not free-form email
// input.
export const SelectUser = memo(({ input, meta, multiple, ...rest }) => {
  const defaultValue = rest.value;
  const [value, setValue] = useState(defaultValue);

  const onChange = (newValue) => {
    setValue(newValue);
    if (multiple) {
      input.onChange(newValue || []);
    } else {
      input.onChange(newValue ? newValue.value.id : null);
    }
  };

  const shouldDisplay = (meta.touched && meta.modified) || meta.submitFailed;

  return (
    <CustomSelectUser
      {...rest}
      onChange={onChange}
      value={value}
      errorText={shouldDisplay ? meta.error : undefined}
      menuStyle={{ maxHeight: 300 }}
      multiple={multiple}
    />
  );
}, areEqual);

// React-Final-Form does not allow for fields with numeric names.
// We adopt a modified form of the suggestions in:
//
// https://final-form.org/docs/react-final-form/fa
// Why can't I have numeric keys in an object?
//
// Field names are prefixed with a "key" string. Form initial
// values are prefixed similarly using a 'stringifyKeys' method.
// The onSubmit and validate calls transform the values by
// removing the prefix using the 'destringifyKeys'.

export const Field = ({ name, ...other }) => {
  return <FinalField name={`key${name}`} {...other} />;
};

const ShowPasswordComponent = ({ showPassword, toggleShowPassword, id }) => (
  <IconButton id={id} onClick={toggleShowPassword}>
    <FAIcon
      iconSet="fal"
      icon={showPassword ? 'eye-slash' : 'eye'}
      color={darkGray}
      size={20}
    />
  </IconButton>
);

const PasswordHelpingAndShowing = ({
  showPassword,
  toggleShowPassword,
  id,
}) => (
  <PasswordHelpInputAdornment id={id}>
    <ShowPasswordComponent
      showPassword={showPassword}
      toggleShowPassword={toggleShowPassword}
      id={id}
    />
  </PasswordHelpInputAdornment>
);

export const PasswordTextField = memo(
  ({
    id,
    endAdornmentId,
    variant,
    fullWidth = false,
    label,
    value,
    name,
    onPasswordChange,
    ...input
  }) => {
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => setShowPassword(!showPassword);
    return (
      <MuiTextField
        id={id}
        variant={variant}
        fullWidth={fullWidth}
        type={showPassword ? 'text' : 'password'}
        label={label}
        value={value}
        name={name}
        onChange={onPasswordChange}
        {...input}
        InputProps={{
          style: {
            paddingRight: 0,
          },
          endAdornment: (
            <>
              <ShowPasswordComponent
                id={endAdornmentId}
                showPassword={showPassword}
                toggleShowPassword={toggleShowPassword}
              />
            </>
          ),
        }}
      />
    );
  },
  areEqual
);

export const PasswordText = memo(
  ({ input, rest, id, variant, changeOnBlur, label, showHelp }) => {
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => setShowPassword(!showPassword);
    return (
      <FinalField
        id={id}
        variant={variant}
        component={TextField}
        changeOnBlur={changeOnBlur}
        name={input.name}
        label={label}
        inputType={showPassword ? 'text' : 'password'}
        {...rest}
        InputProps={{
          endAdornment: (
            <>
              {showHelp ? (
                <PasswordHelpingAndShowing
                  showPassword={showPassword}
                  toggleShowPassword={toggleShowPassword}
                  id={id}
                />
              ) : (
                <ShowPasswordComponent
                  showPassword={showPassword}
                  toggleShowPassword={toggleShowPassword}
                  id={id}
                />
              )}
            </>
          ),
        }}
      />
    );
  },
  areEqual
);

export const Form = ({
  onSubmit,
  initialValues,
  validate,
  children,
  ...other
}) => {
  const stringifyKeys = (values) =>
    Object.keys(values).reduce((result, key) => {
      result[`key${key}`] = values[key];
      return result;
    }, {});

  const destringifyKeys = (values) =>
    Object.keys(values).reduce((result, key) => {
      result[key.substring(3)] = values[key];
      return result;
    }, {});

  const finalOnSubmit = onSubmit
    ? (values) => onSubmit(destringifyKeys(values))
    : undefined;
  const finalInitialValues = initialValues
    ? stringifyKeys(initialValues)
    : undefined;
  const finalValidate = validate
    ? (values) => stringifyKeys(validate(destringifyKeys(values)))
    : undefined;

  return (
    <FinalForm
      onSubmit={finalOnSubmit}
      initialValues={finalInitialValues}
      validate={finalValidate}
      {...other}
    >
      {children}
    </FinalForm>
  );
};
