import React from 'react';
import { redirect } from 'react-router-dom';
import { fetchCaseStudy } from '../../actions/caseStudy';
import { fetchUser } from '../../actions/user';
import { WrapperComponent } from '../../components/WrapperComponent';
import Case from './Case';

const featuredCases = [
  'solar-energy',
  'payroll-lending',
  'animal-feed-in-nigeria',
];

export default {
  path: '/case/:slug',
  element: <WrapperComponent />,
  async action({ params, store }) {
    const { slug } = params;
    if (slug === 'refugee-education') return redirect('/404');

    let caseStudy;
    try {
      caseStudy = await store.dispatch(fetchCaseStudy(slug));
    } catch (err) {
      Promise.reject(err);
    }

    if (!caseStudy) return redirect('/404');

    const caseStudies = await Promise.all(
      featuredCases.map((id) => store.dispatch(fetchCaseStudy(id)))
    );

    const experts =
      caseStudy.experts &&
      (
        await Promise.all(
          caseStudy.experts.map((username) =>
            store.dispatch(fetchUser(username))
          )
        )
      ).filter(Boolean);

    document.title = `OnFrontiers - ${caseStudy.title}`;
    return (
      <Case
        {...caseStudy}
        id={caseStudy.id}
        caseStudies={caseStudies}
        experts={experts}
      />
    );
  },
};
