import React from 'react';
import { json } from 'react-router-dom';

export default {
  path: '/health-check',
  ignoreDomain: true,
  element: <h1>Ok</h1>,
  action() {
    return json({ status: 200 });
  },
};
