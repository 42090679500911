import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { Chip, MenuItem } from '@mui/material';
import Picture from '../Picture';
import FAIcon from '../Icon/FAIcon';
import Link from '../Link/Link';
import { isEmailValid } from '../../core/util';
import { suggestProfiles } from '../../actions/suggestion';
import { teal500 } from '../../core/colors';
import s from './SelectProfile.module.scss';
import SelectWithSuggestions from '../SelectWithSuggestions/SelectWithSuggestions';

const styles = {
  picture: {
    marginRight: 10,
  },
};

class ProfileChip extends PureComponent {
  render() {
    const { profile, showExpertState, showURLEndpoint, disabled, ...other } =
      this.props;

    const expertState = profile.user
      ? profile.user.expert_state || 'Not applied'
      : 'Unregistered';

    let label = profile.name;
    label = showURLEndpoint ? `${label} (${profile.url_endpoint})` : label;
    label = showExpertState ? `${label} (${expertState})` : label;
    return (
      <Chip
        {...other}
        avatar={<Picture user={profile} link={false} size={24} />}
        label={label}
        disabled={disabled}
      />
    );
  }
}

class EmailChip extends PureComponent {
  render() {
    const { email, disabled, ...other } = this.props;
    return (
      <Chip
        {...other}
        avatar={<Picture user={null} link={false} size={24} />}
        label={`${email} (New)`}
        disabled={disabled}
      />
    );
  }
}

class ProfileMenuItem extends PureComponent {
  // Needed for autocomplete check
  static muiName = 'MenuItem';

  render() {
    const {
      profile,
      showCreationTimestamp,
      showExpertState,
      showURLEndpoint,
      ...other
    } = this.props;

    const expertState = profile.user
      ? profile.user.expert_state || 'Not applied'
      : 'Unregistered';

    return (
      <MenuItem {...other}>
        <span className={s.root}>
          <Picture
            style={styles.picture}
            size={40}
            user={profile}
            link={false}
          />
          <span>{profile.name}</span>
          {showURLEndpoint && (
            <small className={s.urlEndpoint}>
              &nbsp;({profile.url_endpoint})
            </small>
          )}
          {showExpertState && (
            <small className={s.expertState}>&nbsp;({expertState})</small>
          )}
          {showCreationTimestamp && (
            <small className={s.date}>
              &nbsp;({moment(profile.created_at).format('YYYY-MM-DD LT')})
            </small>
          )}
          &nbsp;&nbsp;
          <Link
            style={{ zIndex: 999 }}
            force
            target="_blank"
            href={profile.html_url}
          >
            <FAIcon
              size={14}
              iconSet="far"
              color={teal500}
              icon="external-link-square"
            />
          </Link>
        </span>
      </MenuItem>
    );
  }
}

class EmailMenuItem extends PureComponent {
  // Needed for autocomplete check
  static muiName = 'MenuItem';

  render() {
    const { email, ...other } = this.props;

    return (
      <MenuItem {...other}>
        <span className={s.root}>
          <Picture style={styles.picture} size={40} link={false} />
          <span>{email}</span>&nbsp;
        </span>
      </MenuItem>
    );
  }
}

class SelectProfile extends PureComponent {
  static defaultProps = {
    underlineShow: true,
    errorText: undefined,
    allowProfile: true,
    allowEmail: false,
    profileType: undefined,
    suggestionsFilter: () => true,
  };

  renderOption = (props, { type, value }) => {
    if (type === 'email') {
      return <EmailMenuItem {...props} email={value.email} />;
    }

    const { showCreationTimestamp, showExpertState, showURLEndpoint } =
      this.props;

    // profile
    return (
      <ProfileMenuItem
        {...props}
        showExpertState={showExpertState}
        showCreationTimestamp={showCreationTimestamp}
        showURLEndpoint={showURLEndpoint}
        profile={value}
      />
    );
  };

  renderTags = (value, getTagProps) => {
    const { showExpertState, showURLEndpoint, disabled } = this.props;

    return value.map(({ type, value }, index) => {
      if (type === 'email') {
        return (
          <EmailChip
            email={value.email}
            {...getTagProps({ index })}
            disabled={disabled}
          />
        );
      }
      return (
        <ProfileChip
          profile={value}
          showURLEndpoint={showURLEndpoint}
          showExpertState={showExpertState}
          {...getTagProps({ index })}
          disabled={disabled}
        />
      );
    });
  };

  formatOption = ({ type, value }) => {
    if (type === 'email') {
      // email
      return {
        type,
        label: value.email,
        value,
      };
    }

    const { showCreationTimestamp, showExpertState } = this.props;
    const timestamp = showCreationTimestamp
      ? ` (${moment(value.created_at).format('YYYY-MM-DD LT')})`
      : '';
    const expertState = showExpertState
      ? ` (${
          value.user ? value.user.expert_state || 'Not applied' : 'Unregistered'
        })`
      : '';
    const label = `${value.name}${expertState}${timestamp}`;

    // profile
    return {
      type,
      label,
      value,
    };
  };

  isValidEntry = (value) => {
    const { allowEmail, suggestionsFilter } = this.props;

    if (!suggestionsFilter(value)) return false;

    const type = value.type || 'email';
    const rawValue = value.value || value;
    if (
      type === 'email' && // not expecting emails or invalid email
      (!allowEmail || !isEmailValid(rawValue.email))
    )
      return false;

    return true;
  };

  render() {
    const {
      allowEmail,
      allowProfile,
      suggestProfiles,
      suggestBufferSize,
      profileType,
      suggestionsFilter,
      profileSuggestions,
      loadingSuggestions,
      errorText,
      multiple,
      disabled,
      underlineShow,
      ...rest
    } = this.props;

    return (
      <SelectWithSuggestions
        {...rest}
        allowRaw={allowEmail}
        allowSuggestion={allowProfile}
        suggest={suggestProfiles}
        suggestBufferSize={suggestBufferSize}
        suggestScope="profiles"
        suggestArgs={[profileType]}
        suggestionsFilter={suggestionsFilter}
        suggestions={profileSuggestions}
        loadingSuggestions={loadingSuggestions}
        errorText={errorText}
        multiple={multiple}
        disabled={disabled}
        underlineShow={underlineShow}
        renderTags={this.renderTags}
        renderOption={this.renderOption}
        formatOption={this.formatOption}
        isValidEntry={this.isValidEntry}
        isValidRaw={isEmailValid}
        rawType="email"
        rawKey="email"
        suggestionType="profile"
      />
    );
  }
}

SelectProfile = connect(
  (state) => {
    const { loading: loadingSuggestions, cache: profileSuggestions } =
      state.suggestions.profiles;

    return {
      profileSuggestions,
      loadingSuggestions,
    };
  },
  {
    suggestProfiles,
  }
)(SelectProfile);

SelectProfile = SelectProfile;

export default SelectProfile;
