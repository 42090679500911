import moment from 'moment-timezone';
import React from 'react';
import { redirect } from 'react-router-dom';
import {
  engagementTypes,
  fetchConsultation,
  fetchMetrics,
  fetchStarting,
  Trigger,
  updateConsultation,
} from '../../actions/consultation';
import { fetchCountries } from '../../actions/country';
import { fetchPermissions } from '../../actions/permission';
import { track } from '../../actions/tracking';
import { notify } from '../../actions/ui';
import InaccessibleArchived from '../../components/InaccessibleArchived';
import { WrapperComponent } from '../../components/WrapperComponent';
import { formatDate, formatDateTime } from '../../core/util';
import Consultation from './Consultation';

export default {
  path: '/consultation/:id/:section?',
  element: <WrapperComponent />,
  async action({ store, params, query }) {
    const { section, id } = params;

    if (
      section &&
      !['request', 'transcript', 'feedback', 'metrics'].includes(section)
    )
      return redirect('/404');

    const { viewer, consultations } = store.getState();

    const consultation = await getConsultation(store, id);

    if (!consultation) return redirect('/404');

    const { permissions } = consultation;

    if (
      consultation.archived &&
      (!permissions || !permissions.includes('view_archived'))
    ) {
      store.dispatch(track('promo.show.consultation.archived'));
      document.titlte = 'Consultation Details';
      return (
        <InaccessibleArchived
          selectedTab="consultations"
          entity="consultation"
          entities="consultations"
          trackingCode="promo.chat.consultation.archived"
        />
      );
    }

    const requesterId = consultation.requester?.id;
    const billingAccountId = consultation.billing_account_id;
    if (requesterId) {
      await store.dispatch(
        fetchPermissions(viewer.id, [
          {
            service: 'messaging',
            action: 'start_non_anonymous_chat',
            resource: requesterId,
          },
          {
            service: 'messaging',
            action: 'start_anonymous_chat',
            resource: requesterId,
          },
          ...(billingAccountId
            ? [
                {
                  service: 'billing',
                  action: 'create_transaction',
                  resource: billingAccountId,
                },
              ]
            : []),
        ])
      );
    }

    if (section === 'transcript') {
      if (consultation.transcription) {
        store.dispatch(
          track(
            'consultation.transcription.view',
            consultation.id,
            undefined,
            true
          )
        );
      } else {
        return redirect('/404');
      }
    }

    if (section === 'metrics') {
      if (
        !viewer.admin ||
        !consultation.conference ||
        ![
          'confirmed',
          'in_progress',
          'finalizing',
          'completed',
          'incomplete',
        ].includes(consultation.state)
      ) {
        return redirect('/404');
      }
      if (!consultation.conference.participants) {
        await Promise.all([
          store.dispatch(fetchMetrics(id)),
          store.dispatch(fetchCountries()),
        ]);
      }
    }

    const timeChosen = query.confirm_time;
    if (
      timeChosen &&
      timeChosen !== 'none' &&
      consultation.engagement_type !== 'opportunity'
    ) {
      await confirmTime(store, consultation, timeChosen);
    }

    const {
      expert,
      requester,
      external,
      state,
      client_review: clientReview,
      expert_review: expertReview,
      ended_at: endedAt,
    } = consultation;

    if (expertReview) {
      await store.dispatch(
        fetchPermissions(viewer.id, [
          {
            service: 'consultation_review',
            action: 'update',
            resource: expertReview.id,
          },
        ])
      );
    }

    if (clientReview) {
      await store.dispatch(
        fetchPermissions(viewer.id, [
          {
            service: 'consultation_review',
            action: 'update',
            resource: clientReview.id,
          },
        ])
      );
    }

    const openSuggestTime =
      timeChosen === 'none' && userAbleToSelectTime(viewer, consultation);
    const isViewerExpert = expert && viewer.id === expert.id;
    const isViewerClient = requester && viewer.id === requester.id;
    const hasCompletedComplianceTraining =
      expert && !!expert.compliance_completed_at;
    const openCompleteTraining =
      query.token && isViewerExpert && !hasCompletedComplianceTraining;

    if (!consultations.starting) await store.dispatch(fetchStarting());

    const dismissed = store.getState().consultationReviews.dismissedPermanently;
    const isCompleted = state === 'completed';
    const hasReview = !!(isViewerExpert
      ? clientReview && clientReview.id
      : expertReview && expertReview.id);
    const withinOneMonth =
      endedAt && moment(endedAt).add(30, 'd').isAfter(moment());
    const shouldReview =
      !external &&
      isCompleted &&
      !hasReview &&
      withinOneMonth &&
      (isViewerClient || isViewerExpert) &&
      !dismissed.some((d) => d === consultation.id);
    const details =
      consultation.engagement_type === engagementTypes.writtenResponse
        ? 'response_details'
        : 'call_details';

    document.title = `Consultation with ${expert ? expert.name : 'Expert'}`;

    return (
      <Consultation
        store={store}
        consultationId={consultation.id}
        section={section || details}
        openSuggestTime={openSuggestTime}
        openCompleteTraining={openCompleteTraining}
        openReviewCall={query.review_call === 'true' || shouldReview}
        openRequestTranscript={query.request_transcript === 'true'}
        initialMatchExperienceRating={
          query.match_experience && parseInt(query.match_experience)
        }
        initialUsefulnessRating={query.usefulness && parseInt(query.usefulness)}
        joinCall={query.join_call === 'true'}
        timeChosen={timeChosen}
      />
    );
  },
};

async function getConsultation(store, id) {
  const { consultations } = store.getState();

  const edge = consultations?.default?.edges?.find((c) => c.node.id === id);
  const node = edge?.node;
  return (!node?.partial && node) || store.dispatch(fetchConsultation(id));
}

async function confirmTime(store, consultation, time) {
  const { viewer } = store.getState();
  const { proposed_times: proposedTimes = [] } = consultation;
  const selectedTime = proposedTimes.find((x) => x === time);

  // validate the time was selected from proposed
  if (!selectedTime) return redirect('/404');

  // validate user ability to select a time
  if (!userAbleToSelectTime(viewer, consultation)) return redirect('/404');

  try {
    await store.dispatch(
      updateConsultation({
        id: consultation.id,
        starts_at: selectedTime,
        state: 'confirmed',
        trigger: Trigger.emailLink,
      })
    );
    const successMessage =
      consultation.engagement_type === engagementTypes.writtenResponse
        ? `You accepted proposed deadline of ${formatDate(
            selectedTime,
            viewer.timezone
          )}.`
        : `You accepted suggested call time of ${formatDateTime(
            selectedTime,
            viewer.timezone
          )}.`;

    await store.dispatch(notify(successMessage, 'success'));
  } catch (e) {
    await store.dispatch(
      notify('An error occurred when accepting the consultation.', 'error')
    );
  }
}

function userAbleToSelectTime(viewer, consultation) {
  if (
    consultation.state === 'negotiating_expert_time' &&
    consultation.expert &&
    consultation.expert.id === viewer.id &&
    !!consultation.expert.compliance_completed_at
  )
    return true;
  if (
    consultation.state === 'negotiating_client_time' &&
    consultation.requester &&
    consultation.requester.id === viewer.id
  )
    return true;
  return false;
}
