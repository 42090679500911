import Grid from '@mui/material/Grid';
import React from 'react';
import { useLoaderData } from 'react-router-dom';
import Button from '../../components/Button';
import Customers from '../../components/Customers';
import { EngagementsExpert } from '../../components/Engagements';
import FeatureTile from '../../components/FeatureTile/FeatureTile';
import FeaturedExperts from '../../components/FeaturedExperts';
import Layout from '../../components/MarketingLayout/MarketingLayout';
import SplashHero from '../../components/MarketingLayout/SplashHero';
import MarketingSection from '../../components/MarketingSection';
import { darkBlue, sand, teal500, white } from '../../core/colors';
import s from './BecomeAnExpert.module.scss';

function Reasons() {
  return (
    <MarketingSection
      title="Why do business professionals seek out Experts?"
      subTitle="Here are just a few reasons why…"
    >
      <Grid container spacing={3}>
        <FeatureTile icon="presentation" title="Building an investment case" />
        <FeatureTile
          icon="moneyplant"
          title="Evaluating business growth potential"
        />
        <FeatureTile icon="microscope" title="Validating existing research" />
        <FeatureTile
          icon="handsglobe"
          title="Navigating potential partnerships"
        />
      </Grid>
    </MarketingSection>
  );
}

function Benefits() {
  return (
    <MarketingSection title="Benefits of becoming an OnFrontiers Expert">
      <Grid container spacing={3}>
        <FeatureTile icon="lightbulb" title="Share insights">
          Your insights will have influence with international organizations and
          multinationals
        </FeatureTile>
        <FeatureTile icon="cogs" title="Learn & expand">
          Keep pace with the changing needs of clients, expand your audience and
          knowledge base.
        </FeatureTile>
        <FeatureTile icon="money" title="Monetize knowledge">
          Set your own hourly rate and get compensated immediately after a
          client project
        </FeatureTile>
        <FeatureTile icon="handshake" title="Build relationships">
          Introductions can lead to long-lasting and enriching client and
          professional relationships
        </FeatureTile>
      </Grid>
    </MarketingSection>
  );
}

function Features({ actions }) {
  return (
    <MarketingSection darkGreen>
      <Grid container>
        <Grid item sm={12} md={12} lg={4}>
          <div className={s.benefits}>
            <h2 className={s.benefitsTitle}>
              Supercharge your professional and business growth
            </h2>
            {actions}
          </div>
        </Grid>
        <Grid item container md={12} lg={8}>
          {' '}
          {/* AC: cannot set spacing on this grid otherwise the negative margin will affect the sibling Grid.  */}
          <Grid container spacing={4}>
            <Feature icon="coffee" title="Compatible with your day job">
              Engage safely within our platform and rigorous compliance
              framework.
            </Feature>
            <Feature icon="calendarcheck" title="Engage on your terms">
              Set your time and hours and evaluate requests that make sense for
              you.
            </Feature>
            <Feature icon="personquestion" title="Use your specific expertise">
              We scan a global client base to find you clients interested in
              your unique knowledge.
            </Feature>
            <Feature icon="personexpand" title="Extend your client base">
              Engagements are only additive to your existing client
              relationships.
            </Feature>
          </Grid>
        </Grid>
      </Grid>
    </MarketingSection>
  );
}

function Feature(props) {
  return (
    <FeatureTile
      sm={12}
      md={6}
      lg={6}
      iconProps={{ fill: teal500 }}
      titleStyle={{ color: white, fontSize: 24, margin: '20px 0' }}
      textStyle={{ color: white }}
      {...props}
    />
  );
}

const BecomeAnExpert = () => {
  const { profiles, signupUrl } = useLoaderData();
  const actions = (
    <div className={s.actions}>
      <Button size="large" href={signupUrl} style={{ minWidth: 213 }}>
        Become An Expert
      </Button>
    </div>
  );

  return (
    <Layout>
      <SplashHero
        backgroundColor={darkBlue}
        title="Share your real-world experience to accelerate others on their path forward"
        testimonial={{
          iconColor: teal500,
          name: 'Rafael Castillo-Triana',
          title: 'CEO Latín América and Emerging Markets at The Alta Group',
          picture: require('./testimonial.png'),
          children:
            'The call consultation was a great experience. Your mission aligns with my passion to open up new business opportunities for others.',
        }}
      >
        {actions}
      </SplashHero>

      <Reasons />

      <MarketingSection
        style={{ backgroundColor: sand }}
        title="Types of engagements you can complete"
      >
        <EngagementsExpert border={false} />
        {actions}
      </MarketingSection>

      <Benefits />

      <Features actions={actions} />

      <FeaturedExperts profiles={profiles}>
        <div className={s.center}>{actions}</div>
      </FeaturedExperts>

      <Customers />
    </Layout>
  );
};

export default BecomeAnExpert;
