import React from 'react';
import {
  clearCollection,
  fetchAwaiting,
  fetchCanceled,
  fetchCompleted,
  fetchConfirmed,
  fetchStarting,
} from '../../actions/consultation';
import ConsultationsStarting from '../../components/ConsultationsStarting';
import LayoutPage from '../../components/Layout/LayoutPage';
import { WrapperComponent } from '../../components/WrapperComponent';
import Consultations from './Consultations';

export default {
  path: '/consultations',
  element: <WrapperComponent />,
  async action({ store }) {
    const cursor = '';

    store.dispatch(fetchStarting());
    store.dispatch(fetchAwaiting(cursor, 10, true));
    store.dispatch(fetchConfirmed(cursor, 10, true));
    store.dispatch(fetchCanceled(cursor, 10, true));
    store.dispatch(fetchCompleted(cursor, 10, true));
    store.dispatch(clearCollection('default'));

    document.title = 'Consultations';
    return (
      <LayoutPage showNav selected="consultations">
        <ConsultationsStarting />
        <Consultations />
      </LayoutPage>
    );
  },
};
