import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchLandingPages } from '../../../actions/landing';
import ListItems from '../ListItems';
import LandingPageModel from './LandingPageModel';

export default function LandingPageModels({
  fetchLandingPages,
  landingPages,
  model,
  id,
}) {
  const navigate = useNavigate();
  useEffect(() => {
    fetchLandingPages();
  }, []);

  const items = landingPages.map((page) => ({
    id: page.id,
    name: `${page.id} ${page.subdomain} - ${page.title}`,
  }));

  return id ? (
    <LandingPageModel model={model} id={id !== 'new' ? id : undefined} />
  ) : (
    <ListItems
      model={model}
      items={items}
      onCreate={() => navigate(`/admin/${model.path}/new`)}
    />
  );
}

LandingPageModels = connect(
  (state) => {
    return {
      landingPages: state.landing.all || [],
    };
  },
  {
    fetchLandingPages,
  }
)(LandingPageModels);
