import moment from 'moment-timezone';
import ActionTypes from '../core/ActionTypes';
import { removeAt, add, updateAt, mergeAt } from './utils';

const {
  PROFILE__SET_CSV_PREVIEW,
  PROFILE__LIST_LOADING,
  PROFILE__LIST,
  PROFILE__LIST_RESET,
  PROFILE__LIST_LOADED,
  PROFILE__DELETE,
  PROFILE__COUNT_LOADING,
  PROFILE__COUNT,
  PROFILE__UPDATE,
  PROFILE__REMOVE_EXPERIENCE,
  PROFILE__UPDATE_EXPERIENCE,
  PROFILE__REMOVE_EDUCATION,
  PROFILE__UPDATE_EDUCATION,
  ADDRESS__REMOVE_ADDRESS,
  ADDRESS__SET_ADDRESS,
} = ActionTypes;

const initialCollectionState = {
  edges: [],
  pageInfo: { hasNextPage: true },
  loading: false,
};

const initialState = {
  csvPreviews: {},
  fullProfiles: {},
  collections: {
    conflicts: initialCollectionState,
  },
  counts: {},
  $$query_meta$$: {},
};

export default function profilesReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE__SET_CSV_PREVIEW:
      return {
        ...state,
        csvPreviews: {
          ...state.csvPreviews,
          [action.preview.url]: action.preview,
        },
      };
    case PROFILE__LIST_LOADING:
    case PROFILE__LIST:
    case PROFILE__LIST_LOADED:
    case PROFILE__LIST_RESET:
      const col = action.collection;
      return {
        ...state,
        collections: {
          ...state.collections,
          [col]: collectionReducer(state.collections[col], action),
        },
        counts: {
          ...state.counts,
          [col]: countReducer(state.counts[col], action),
        },
      };
    case PROFILE__DELETE:
      return (() => {
        const newProfiles = { ...state.fullProfiles };
        delete newProfiles[action.profile.id];
        delete newProfiles[action.profile.url_endpoint];
        return {
          ...state,
          fullProfiles: newProfiles,
          collections: Object.entries(state.collections).reduce(
            (acc, [key, collection]) => ({
              ...acc,
              [key]: collectionReducer(collection, action),
            }),
            {}
          ),
          counts: {}, // reset counts
        };
      })();
    case PROFILE__COUNT_LOADING:
    case PROFILE__COUNT:
      const { count } = action;
      return {
        ...state,
        counts: {
          ...state.counts,
          [count]: countReducer(state.counts[count], action),
        },
      };
    case PROFILE__UPDATE:
    case PROFILE__REMOVE_EXPERIENCE:
    case PROFILE__UPDATE_EXPERIENCE:
    case PROFILE__REMOVE_EDUCATION:
    case PROFILE__UPDATE_EDUCATION:
    case ADDRESS__REMOVE_ADDRESS:
    case ADDRESS__SET_ADDRESS:
      return (() => {
        const id = action.profile ? action.profile.id : action.profileId;
        const urlEndpoint = action.profile && action.profile.url_endpoint;

        const newState = {
          ...state,
          fullProfiles: {
            ...state.fullProfiles,
            [id]: profileReducer(state.fullProfiles[id], action),
          },
        };

        if (urlEndpoint) {
          newState.fullProfiles[urlEndpoint] = newState.fullProfiles[id];
        }

        if (action.queryKey) {
          newState.$$query_meta$$ = {
            ...newState.$$query_meta$$,
            [action.queryKey]: {
              expiresAt: moment().add(1, 'minute').toISOString(),
              result: newState.fullProfiles[id],
            },
          };
        }

        return newState;
      })();
    default:
      return state;
  }
}

function experienceReducer(state = [], action) {
  const experienceId = action.experience ? action.experience.id : action.id;
  const index = state.findIndex((item) => item.id === experienceId);
  switch (action.type) {
    case PROFILE__REMOVE_EXPERIENCE:
      return removeAt(state, index);
    case PROFILE__UPDATE_EXPERIENCE:
      const { experience } = action;
      if (index < 0) {
        // EKN-355: Sort added experience
        return add(state, experience).sort((e1, e2) =>
          moment(e1.start_date).isBefore(moment(e2.start_date)) ? 1 : -1
        );
      }
      return updateAt(state, index, experience);
    default:
      return state;
  }
}

function educationReducer(state = [], action) {
  const educationId = action.education ? action.education.id : action.id;
  const index = state.findIndex((item) => item.id === educationId);
  switch (action.type) {
    case PROFILE__REMOVE_EDUCATION:
      return removeAt(state, index);
    case PROFILE__UPDATE_EDUCATION:
      const { education } = action;
      return index < 0
        ? add(state, education)
        : updateAt(state, index, education);
    default:
      return state;
  }
}

function profileReducer(state = {}, action) {
  switch (action.type) {
    case ADDRESS__REMOVE_ADDRESS:
    case ADDRESS__SET_ADDRESS:
      const prop =
        action.transport === 'email'
          ? 'emails'
          : action.transport === 'phone'
            ? 'phones'
            : '';

      if (!state || !prop) return state;

      return {
        ...state,
        [prop]: addressesReducer(state[prop], action),
      };
    case PROFILE__REMOVE_EXPERIENCE:
    case PROFILE__UPDATE_EXPERIENCE:
      return {
        ...state,
        experiences: experienceReducer(state.experiences, action),
      };
    case PROFILE__REMOVE_EDUCATION:
    case PROFILE__UPDATE_EDUCATION:
      return {
        ...state,
        education: educationReducer(state.education, action),
      };
    case PROFILE__UPDATE:
      const { profile } = action;
      profile.sector_ids = profile.sectors && profile.sectors.map((v) => v.id);
      profile.region_ids = profile.regions && profile.regions.map((v) => v.id);
      return {
        ...state,
        ...profile,
      };
    default:
      return state;
  }
}

function collectionReducer(state, action) {
  switch (action.type) {
    case PROFILE__LIST_RESET:
      return initialCollectionState;
    case PROFILE__LIST:
      const { reset, edges, pageInfo } = action;
      if (reset) {
        return { ...state, edges, pageInfo, resetAt: new Date() };
      }
      return { ...state, edges: [...state.edges, ...edges], pageInfo };
    case PROFILE__LIST_LOADING:
      return { ...state, loading: true };
    case PROFILE__LIST_LOADED:
      return { ...state, loading: false };
    case PROFILE__DELETE:
      return {
        ...state,
        edges: state.edges.filter((e) => e.node.id !== action.profile.id),
      };
    default:
      return state;
  }
}

function countReducer(state, action) {
  switch (action.type) {
    case PROFILE__COUNT_LOADING:
      return {
        loading: true,
      };
    case PROFILE__COUNT:
      return {
        expire: moment().add(10, 'minutes'),
        value: action.value,
      };
    case PROFILE__LIST_RESET:
      return {};
    default:
      return state;
  }
}

function addressesReducer(state, action) {
  state = state || [];
  let newState;
  switch (action.type) {
    case ADDRESS__REMOVE_ADDRESS:
      return action.addresses;
    case ADDRESS__SET_ADDRESS:
      const { address } = action;

      newState = state;
      if (address.primary) {
        newState.forEach((a) => (a.primary = false));
      }

      const index = state.findIndex((item) => item.address === address.address);
      if (index < 0) {
        return [...newState, address];
      }

      return mergeAt(newState, index, address);
    default:
      return state;
  }
}
