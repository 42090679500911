import React from 'react';
import { fetchAppNotifications } from '../../actions/appNotification';
import {
  fetchAwaiting,
  fetchConfirmed,
  fetchStarting,
} from '../../actions/consultation';
import { fetchProjects } from '../../actions/project';
import { fetchGroupSavedSearches } from '../../actions/savedSearches';
import { fetchUser } from '../../actions/user';
import AppNotifications from '../../components/AppNotifications/AppNotifications';
import LayoutPage from '../../components/Layout/LayoutPage';
import { WrapperComponent } from '../../components/WrapperComponent';
import Dashboard from './Dashboard';

export default {
  path: '/dashboard',
  title: 'Dashboard',
  element: <WrapperComponent />,
  async action({ store }) {
    const cursor = '';
    const { viewer } = store.getState();

    const promises = [
      store.dispatch(fetchAppNotifications()),
      store.dispatch(fetchGroupSavedSearches()),
    ];

    // Data for completeness banner
    if (viewer.signup_type === 'expert') {
      promises.push(
        store.dispatch(
          fetchUser(viewer.username, {
            experiences: true,
            education: true,
            addresses: true,
            expertise: true,
          })
        )
      );
    }

    store.dispatch(fetchStarting());
    store.dispatch(fetchAwaiting(cursor, 1000, false, 'dashboardAwaiting'));
    store.dispatch(fetchConfirmed(cursor, 1000, false, 'dashboardConfirmed'));
    store.dispatch(
      fetchProjects({
        collection: 'dashboard',
        pageSize: 1000,
        openOnly: true,
        memberOnly: true,
      })
    );

    await Promise.all(promises);

    await AppNotifications.prefetch(store);
    document.title = 'Dashboard';
    return (
      <LayoutPage showNav selected="dashboard">
        <Dashboard />
      </LayoutPage>
    );
  },
};
