import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import ListSubheader from '@mui/material/ListSubheader';
import makeStyles from '@mui/styles/makeStyles';
import { fetchSectors, removeSector } from '../../../actions/sector';
import SectorModel from './SectorModel';
import ListItems from '../ListItems';
import Dialog from '../../../components/Dialog/Dialog';
import { notify } from '../../../actions/ui';
import history from '../../../core/history';
import { debounce, normalizeSpace } from '../../../core/util';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: 0,
  },
  search: {
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
      width: 190,
    },
  },
}));

export default function SectorModels({
  fetchSectors,
  removeSector,
  notify,
  sectors,
  model,
  id,
}) {
  const s = useStyles();
  const navigate = useNavigate();

  const [nameSearch, setNameSearch] = useState('');
  const [deleteTarget, setDeleteTarget] = useState(null);

  useEffect(() => {
    if (!id) {
      fetchSectors().catch((error) => {
        notify(error.message, 'error');
      });
    }
  }, [id]);

  const handleDelete = async (sector) => {
    try {
      await removeSector(sector.id);
      setDeleteTarget(null);
    } catch (error) {
      notify(error.message, 'error');
      return;
    }
    notify('Deleted Successfully');
  };

  const items = sectors
    .filter(
      (sector) =>
        sector.name.toLowerCase().indexOf(nameSearch) > -1 ||
        sector.id.toString() === nameSearch
    )
    .map((sector) => ({
      id: sector.id,
      name: `${sector.id} ${sector.name}`,
    }));

  const searchHeader = (
    <ListSubheader disableSticky className={s.header}>
      <div className={s.search}>
        <TextField
          value={nameSearch ?? ''}
          fullWidth={false}
          onChange={({ target: { value } }) => {
            debounce(setNameSearch(normalizeSpace(value)), 250);
          }}
          placeholder="Filter by name or ID"
        />
      </div>
    </ListSubheader>
  );

  return id ? (
    <SectorModel model={model} id={id !== 'new' ? id : undefined} />
  ) : (
    <div className={s.root}>
      <ListItems
        model={model}
        items={items}
        onCreate={() => navigate(`/admin/${model.path}/new`)}
        onDelete={(sector) => setDeleteTarget(sector)}
        editEnabled={false}
        additionalHeaders={[searchHeader]}
      />
      <Dialog
        open={deleteTarget !== null}
        fullWidth
        maxWidth="sm"
        confirmLabel="Remove"
        onConfirm={() => handleDelete(deleteTarget)}
        cancelLabel="Cancel"
        onCancel={() => setDeleteTarget(null)}
      >
        Are you sure? This will remove sector {deleteTarget?.name}.
      </Dialog>
    </div>
  );
}

SectorModels = connect(
  (state) => {
    return {
      sectors: state.sectors.all || [],
    };
  },
  {
    fetchSectors,
    removeSector,
    notify,
  }
)(SectorModels);
