import React from 'react';
import { policies } from '../../core/agreements';
import Body from '../Body';
import Divider from '../Divider/Divider';
import Link from '../Link';
import s from './Footer.module.scss';
import cx from 'classnames';

export default function Footer({ style, is404 }) {
  const tosPol = policies['terms-of-service'];
  const privacyPol = policies.privacy;

  const linkClasses = cx([s.link, is404 && s.page404]);

  return (
    <div className={s.root}>
      <footer className={s.footer} style={style}>
        <Body>
          <Divider
            className={s.divider}
            color={is404 && 'white'}
            spacing={35}
          />
          <div className={s.address}>
            <div className={cx([s.addressColumn, is404 && s.page404])}>
              © OnFrontiers, Inc. All rights reserved.
            </div>
            <div className={s.linksColumn}>
              <Link className={linkClasses} to={privacyPol.url}>
                {privacyPol.name}
              </Link>
              <Link className={linkClasses} to={tosPol.url}>
                {tosPol.name}
              </Link>
            </div>
          </div>
        </Body>
      </footer>
    </div>
  );
}
