import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import DeleteDialog from '.';
import { deleteExpertRequest } from '../../actions/expertRequest';
import { notify } from '../../actions/ui';
import { useNavigate } from 'react-router-dom';

const CONSULTATIONS_ERROR =
  'GraphQL Error: cannot delete request with pending/upcoming/completed consultations';

const DeleteExpertRequestDialog = (props) => {
  const navigate = useNavigate();

  const handleDelete = () => {
    const { expertRequestId, deleteExpertRequest, notify, onCancel, returnTo } =
      props;
    deleteExpertRequest(expertRequestId)
      .then(() => {
        if (returnTo) navigate(returnTo);
      })
      .catch((err) => {
        if (err.message === CONSULTATIONS_ERROR) {
          notify(
            'Cannot delete request with pending, upcoming, or completed consultations.',
            'error'
          );
        } else {
          notify(
            'An unexpected error occurred when trying ' +
              'to delete the expert request.',
            'error'
          );
        }
        onCancel();
      });
  };

  return (
    <DeleteDialog
      {...props}
      title="Are you sure you want to delete this request?"
      onConfirm={handleDelete}
    >
      This action is permanent and will result in the loss of expert matches.
    </DeleteDialog>
  );
};

export default connect(undefined, {
  deleteExpertRequest,
  notify,
})(DeleteExpertRequestDialog);
