import moment from 'moment-timezone';
import actionTypes from '../core/ActionTypes';
import { shouldResetCollection } from '../core/util';
import { gqlParam, gqlVar } from './util';

const {
  GROUP__BATCH_ADD,
  GROUP__UPDATE,
  GROUP__REMOVE_MEMBER,
  GROUP__REMOVE_MEMBER_AWAITING,
  GROUP__ADD_MEMBER,
  GROUP__UPDATE_MEMBER,
  GROUP__LIST_LOADING,
  GROUP__LIST_LOADED,
} = actionTypes;

const NOT_FOUND = 'GraphQL Error: group does not exist';

export function fetchGroup(
  id,
  {
    transactions = false,
    members = false,
    awaitingMembers = false,
    aboutPage = false,
    publicData = false,
    profileKeywordsDefinition = false,
    internalNetwork = false,
    domain = false,
    savedSearches = false,
  } = {}
) {
  return async (dispatch, getState, { graphql }) => {
    const queryKey = JSON.stringify({
      transactions,
      members,
      awaitingMembers,
      aboutPage,
      publicData,
      profileKeywordsDefinition,
      internalNetwork,
    });

    for (const gedge of getState().groups.default.edges || []) {
      if (gedge.node.id !== id) continue;
      if (!gedge.expiresAt || moment().isSameOrAfter(gedge.expiresAt)) break;
      if (gedge.queryKey !== queryKey) break;
      return gedge.node; // re-use cache
    }

    try {
      const result = await graphql.query(
        `query getGroup(
        $id: String,
        $publicData: Boolean
      ) {
        group(id: $id, public: $publicData) {
          id
          html_url
          slug
          name
          internal
          branding_logo_url
          branding_show_poweredbyof
          billing_account {
            id
          }
          enforce_2fa
          ${
            members
              ? `
          members: members {
            edges {
              node {
                id
                role
                email
                user {
                  id
                  html_url
                  picture_url
                  name
                  first_name
                  last_name
                  city
                  country
                }
              }
            }
            pageInfo {
              total
            }
          }`
              : ''
          }
          ${
            awaitingMembers
              ? `
          members_awaiting: members(states:[awaiting_approval]) {
            edges {
              node {
                id
                user {
                  id
                  html_url
                  picture_url
                  name
                  first_name
                  last_name
                }
              }
            }
            pageInfo {
              total
            }
          }`
              : ''
          }
          permissions
          stats { expert_request_count, consultation_count }
          billing_account {
            id
            type
            entity_type
            seat_count
            state
            purchases {
              remaining { cents, currency }
              expires_at
            }
            credit_balance { cents, currency }
            ${
              transactions
                ? `
            transactions {
              id
              created_at
              description
              money { cents, currency }
              credits { cents, currency }
              tracking_code
              state
            }
            `
                : ''
            }
          }
          account_type
          ${
            internalNetwork
              ? `
          internal_network {
            id
            name
            expert_not_available_self_service
            expert_unpaid
          }`
              : ''
          }
          ${
            savedSearches
              ? `
          saved_searches {
            id
            name
            url
          }
          `
              : ''
          }
          ${
            domain
              ? `
          domain {
            subdomain
            root_subdomain_redirect
            logo_url
            signup_enabled
            expert_signup_about
            expert_signup_about_mobile
            network_join_title
            member_signup_about
            member_signup_about_mobile
            signup_prompt_hourly_rate
            signup_prompt_marketplace
            signup_prompt_profile_publicity
            agreements(active_only: false) {
              id
              active
              policy_code
              policy_label
              policy_url
            }
          }`
              : ''
          }
          default_anonymous_messaging
          about
          ${
            aboutPage
              ? `
          about_page {
            public
            enabled
            body_logo_url
            signup_url
            html_url
            search_engine_index
            logo_link_url
          }`
              : ''
          }
          ${
            profileKeywordsDefinition
              ? `
          profile_keywords_definition {
            id
            name
          }`
              : ''
          }
        }
      }`,
        { id, publicData }
      );

      const { group } = result;
      if (group) dispatch({ type: GROUP__UPDATE, group, queryKey });
      return group;
    } catch (err) {
      if (err.isPermissionError || err.message === NOT_FOUND) return;
      throw err;
    }
  };
}

export function fetchGroupMembers(
  groupId,
  { limit = 10, page, name, states } = {}
) {
  return async (_, __, { graphql }) => {
    try {
      const result = await graphql.query(
        `query getGroupMembers(
        $groupId: String!
        $limit: Int!
        $page: Int
        $name: String
        $states: [GroupMemberState]
      ) {
        groupMembers(
          group_id: $groupId
          limit: $limit
          page: $page
          name: $name
          states: $states
        ) {
          edges {
            node {
              id
              role
              email
              user {
                id
                html_url
                picture_url
                name
                first_name
                last_name
                city
                country
              }
            }
          }
          pageInfo {
            total
            lastPage
          }
        }
      }`,
        { groupId, limit, page, states, name }
      );

      const { groupMembers } = result;
      return groupMembers;
    } catch (err) {
      if (err.isPermissionError || err.message === NOT_FOUND) return;
      throw err;
    }
  };
}

export function fetchAllGroups(opts) {
  return async (dispatch, getState) => {
    const { viewer } = getState();
    return dispatch(
      fetchGroups({
        collection: 'all',
        pageSize: 1000,
        memberLimit: 0,
        memberOnly: !viewer.admin,
        ...opts,
      })
    );
  };
}

export function fetchGroups({
  // params
  cursor,
  collection = 'default',
  memberLimit = 5,
  pageSize = 10,
  internal = false,
  sharingExperts = false,
  memberOnly = true,
  name = '',

  // cache
  force,

  // fields
  billingAccount = false,
  stats = false,
  internalNetwork = false,
  keywordsConfig = false,
} = {}) {
  return async (dispatch, getState, { graphql }) => {
    const reset = !cursor;
    const groups = getState().groups[collection];
    if (!force && reset && !shouldResetCollection(groups, pageSize))
      return groups;

    dispatch({ type: GROUP__LIST_LOADING, collection });

    const variables = {
      internal,
      memberOnly,
      memberLimit,
      cursor,
      pageSize,
      sharingExperts,
      name,
    };

    try {
      const result = await graphql.query(
        `query getGroups(
        $internal: Boolean
        $memberOnly: Boolean
        $cursor: String
        $pageSize: Int!
        $sharingExperts: Boolean
        ${memberLimit > 0 ? '$memberLimit: Int' : ''}
        $name: String
      ) {
        groups(
          internal: $internal
          member_only: $memberOnly
          first: $pageSize
          after: $cursor
          sharing_experts: $sharingExperts
          name: $name
        ) {
          pageInfo { hasNextPage }
          edges {
            cursor
            node {
              id
              html_url
              slug
              name
              internal
              about
              ${
                stats
                  ? `
              stats {
                expert_request_count,
                consultation_count
              }`
                  : ''
              }
              ${
                billingAccount
                  ? `
              billing_account {
                id
                type
                state
                credit_balance { cents, currency }
              }`
                  : ''
              }
              ${
                memberLimit > 0
                  ? `
              members(first: $memberLimit) {
                edges {
                  node {
                    id
                    role
                    email
                    user {
                      id
                      first_name
                      last_name
                      name
                      html_url
                      picture_url
                    }
                  }
                }
                pageInfo {
                  total
                }
              }`
                  : ''
              }
              ${
                internalNetwork
                  ? `
              internal_network {
                id
                name
              }`
                  : ''
              }
              ${
                keywordsConfig
                  ? `
              profile_keywords_definition {
                id
                name
              }`
                  : ''
              }
            }
          }
        }
      }`,
        variables
      );

      const page = result.groups;

      dispatch({
        type: GROUP__BATCH_ADD,
        reset,
        collection,
        ...page,
      });

      dispatch({ type: GROUP__LIST_LOADED, collection });

      return page;
    } catch (err) {
      dispatch({ type: GROUP__LIST_LOADED, collection });
      throw err;
    }
  };
}

export function fetchGroupKeywordCounts(keywordIDs) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.query(
      `
        query groupKeywordCounts (
          $keywordIDs: [String]!
        ){
        groupKeywordCounts (
          keyword_ids: $keywordIDs) {
            id
            count
            name
          }
        }
      `,
      { keywordIDs }
    );
    return result;
  };
}

export function addGroupMember(groupId, { userId, role }) {
  return (dispatch, getState, { graphql }) =>
    graphql
      .mutate(
        `(
      $groupId: String!
      $userId: String!
      $role: GroupRole!
    ) {
      addGroupMember(
        group_id: $groupId
        user_id: $userId
        role: $role
      ) {
        id
        email
        role
        user {
          id
          first_name
          last_name
          name
          html_url
          picture_url
          city
          country
        }
      }
    }`,
        { groupId, userId, role }
      )
      .then(({ addGroupMember: member }) => {
        dispatch({
          type: GROUP__ADD_MEMBER,
          groupId,
          member,
        });
      });
}

export function updateGroupMember(groupId, groupMemberId, { role }) {
  return (dispatch, getState, { graphql }) =>
    graphql
      .mutate(
        `(
      $id: String!
      $role: GroupRole!
    ) {
      updateGroupMember(
        id: $id
        role: $role
      ) {
        id
        email
        user {
          id
        }
      }
    }`,
        { id: groupMemberId, role }
      )
      .then(() => {
        dispatch({
          type: GROUP__UPDATE_MEMBER,
          groupId,
          groupMemberId,
          role,
        });
      });
}

export function removeGroupMember(groupId, groupMemberId) {
  return (dispatch, getState, { graphql }) =>
    graphql
      .mutate(
        `(
      $id: String!
      ) {
        removeGroupMember(id: $id) {
          id
          email
          user {
            id
          }
        }
    }`,
        { id: groupMemberId }
      )
      .then(() => {
        dispatch({
          type: GROUP__REMOVE_MEMBER,
          groupId,
          groupMemberId,
        });
      });
}

export function approveGroupMember(member) {
  return async (dispatch, getState, { graphql }) =>
    graphql
      .mutate(
        `(
      $id: String!
    ) {
      updateGroupMember(
        id: $id
        state: active
      ) {
        id
        email
        role
        user {
          id
          first_name
          last_name
          name
          html_url
          picture_url
          city
          country
        }
        group { id }
      }
    }`,
        { id: member.id }
      )
      .then(({ updateGroupMember: active }) => {
        dispatch({
          type: GROUP__ADD_MEMBER,
          groupId: active.group.id,
          member: active,
        });
        dispatch({
          type: GROUP__REMOVE_MEMBER_AWAITING,
          groupId: active.group.id,
          groupMemberId: active.id,
        });
      });
}

export function denyGroupMember(member) {
  return async (dispatch, getState, { graphql }) => {
    const { removeGroupMember: removed } = await graphql.mutate(
      `(
      $id: String!
      ) {
        removeGroupMember(id: $id) {
          id
          email
          user {
            id
          }
          group {
            id
          }
        }
    }`,
      { id: member.id }
    );

    dispatch({
      type: GROUP__REMOVE_MEMBER_AWAITING,
      groupId: removed.group.id,
      groupMemberId: removed.id,
    });
  };
}

export function updateGroup(group) {
  return async (dispatch, getState, { graphql }) => {
    const data = {
      id: group.id,
      about: group.about,
      default_anonymous_messaging: group.default_anonymous_messaging,
      profile_keywords_definition: group.profile_keywords_definition,
      enforce_2fa: group.enforce_2fa,
    };

    const result = await graphql.mutate(
      `
      (
        ${'id' in data ? '$id: String!' : ''}
        ${'about' in data ? '$about: String' : ''}
        ${
          'default_anonymous_messaging' in data
            ? '$default_anonymous_messaging: Boolean'
            : ''
        }
        ${
          'profile_keywords_definition' in data
            ? '$profile_keywords_definition: [String]'
            : ''
        }
        ${'enforce_2fa' in data ? '$enforce_2fa: Boolean' : ''}
      ) {
        updateGroup (
          ${'id' in data ? 'id: $id' : ''}
          ${'about' in data ? 'about: $about' : ''}
          ${
            'default_anonymous_messaging' in data
              ? 'default_anonymous_messaging: $default_anonymous_messaging'
              : ''
          }
          ${
            'profile_keywords_definition' in data
              ? 'profile_keywords_definition: $profile_keywords_definition'
              : ''
          }
          ${'enforce_2fa' in data ? 'enforce_2fa: $enforce_2fa' : ''}
        ) {
          id
        }
      }`,
      data
    );

    if (result && result.updateGroup) {
      dispatch({
        type: GROUP__UPDATE,
        group: result.updateGroup,
      });

      return result.updateGroup;
    }
  };
}

export function updateGroupAboutPage(data) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.mutate(
      `(
      ${'group_id' in data ? '$group_id: String!' : ''}
      ${'body_logo_url' in data ? '$body_logo_url: String' : ''}
      ${'public' in data ? '$public: Boolean' : ''}
      ${'enabled' in data ? '$enabled: Boolean' : ''}
      ${'search_engine_index' in data ? '$search_engine_index: Boolean' : ''}
      ${'logo_link_url' in data ? '$logo_link_url: String' : ''}
    ) {
      updateGroupAboutPage (
        ${'group_id' in data ? 'group_id: $group_id' : ''}
        ${'body_logo_url' in data ? 'body_logo_url: $body_logo_url' : ''}
        ${'public' in data ? 'public: $public' : ''}
        ${'enabled' in data ? 'enabled: $enabled' : ''}
        ${
          'search_engine_index' in data
            ? 'search_engine_index: $search_engine_index'
            : ''
        }
        ${'logo_link_url' in data ? 'logo_link_url: $logo_link_url' : ''}
      ) {
        body_logo_url
        public
        enabled
        html_url
        search_engine_index
        logo_link_url
      }
    }`,
      data
    );

    const aboutPage = result && result.updateGroupAboutPage;

    if (aboutPage) {
      dispatch({
        type: GROUP__UPDATE,
        group: {
          id: data.group_id,
          about_page: aboutPage,
        },
      });

      return aboutPage;
    }
  };
}

export function updateGroupSavedSearches(
  groupId,
  { saved_searches: savedSearches }
) {
  return async (dispatch, getState, { graphql }) => {
    const { updateGroupSavedSearches } = await graphql.mutate(
      `(
      $group_id: String!
      $saved_searches: [GroupSavedSearchInput]
    ) {
      updateGroupSavedSearches(
        group_id: $group_id
        saved_searches: $saved_searches
      ) {
        id
        name
        url
      }
    }`,
      { group_id: groupId, saved_searches: savedSearches }
    );

    if (updateGroupSavedSearches && groupId) {
      dispatch({
        type: GROUP__UPDATE,
        group: { id: groupId, saved_searches: updateGroupSavedSearches },
      });
    }

    return updateGroupSavedSearches;
  };
}

export function updateGroupBranding(groupId, values) {
  return async (dispatch, getState, { graphql }) => {
    const { updateGroup } = await graphql.mutate(
      `(
      $id: String!
      ${gqlVar('branding_logo_url', 'String!', values)}
      ${gqlVar('branding_show_poweredbyof', 'Boolean!', values)}
    ) {
      updateGroup (
        id: $id
        ${gqlParam('branding_logo_url', values)}
        ${gqlParam('branding_show_poweredbyof', values)}
      ) {
        id
        branding_logo_url
        branding_show_poweredbyof
      }
    }`,
      {
        id: groupId,
        branding_logo_url: values.branding_logo_url,
        branding_show_poweredbyof: values.branding_show_poweredbyof,
      }
    );

    if (updateGroup) {
      dispatch({
        type: GROUP__UPDATE,
        group: updateGroup,
      });

      return updateGroup;
    }
  };
}

export function getAboutPageLogoURL() {
  return async (dispatch, getState, { graphql }) => {
    const { basicAuthentication } = getState().auth;
    const client = basicAuthentication
      ? graphql.fromAuth(basicAuthentication)
      : graphql;
    const { aboutPageLogoURL } = await client.query(`query {
      aboutPageLogoURL
    }`);
    return aboutPageLogoURL;
  };
}
