import React, { useState } from 'react';
import { connect } from 'react-redux';
import TeamAccountPromoDialog from './TeamAccountPromoDialog';
import { useNavigate } from 'react-router-dom';

const TeamAccountPromoButton = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const {
    component: ClickableComponent,
    onClick,
    viewer,
    to = '/request_expert',
    dialogProps,
    dispatch,
    ...other
  } = props;

  const handleClick = () => {
    if (viewer.groups && viewer.groups.length > 0) {
      if (onClick) onClick();
      if (to) navigate(to);
      return;
    }
    setOpen(true);
  };

  return (
    <React.Fragment>
      <TeamAccountPromoDialog
        dialogpProps={dialogProps}
        open={open}
        onClose={() => setOpen(false)}
      />
      <ClickableComponent {...other} onClick={handleClick}>
        {other.label}
        {other.children}
      </ClickableComponent>
    </React.Fragment>
  );
};

export default connect((state) => ({
  viewer: state.viewer,
}))(TeamAccountPromoButton);
