import React, { PureComponent } from 'react';

import FAIcon from '../../../components/Icon/FAIcon';
import { highlight } from '../../../core/util';
import { formatExperiencePeriod } from '../../../core/profile';
import { darkGray } from '../../../core/colors';
import s from './Experiences.module.scss';

class Experiences extends PureComponent {
  render() {
    const { experiences } = this.props;
    if (!experiences || experiences.length === 0) return null;
    return (
      <div className={s.experiences}>
        {experiences.map((e) => {
          const period = formatExperiencePeriod(
            e.start_date,
            e.end_date,
            e.current,
            {
              duration: true,
            }
          );
          const description =
            e.description_snippets && e.description_snippets.join(' (…) ');

          const titleSeparator = e.title && e.organization ? ', ' : '';
          const title = `${e.title || ''}${titleSeparator}${
            e.organization || ''
          }`;

          const locationSeparator =
            e.location && (period || description) ? ' · ' : '';
          const periodSeparator = period && description ? ' · ' : '';

          return (
            <div key={JSON.stringify(e)} className={s.experience}>
              <div className={s.experienceIcon}>
                <FAIcon
                  iconSet="fal"
                  icon={e.current ? 'briefcase' : 'business-time'}
                  style={{ marginRight: 5 }}
                  color={darkGray}
                  size={14}
                />
              </div>
              <div className={s.experienceInfo}>
                <div
                  className={s.experienceTitle}
                  dangerouslySetInnerHTML={{ __html: highlight(title) }}
                />
                <div className={s.experiencePeriod}>
                  {e.location && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: highlight(e.location),
                      }}
                    />
                  )}
                  {locationSeparator}
                  {period}
                  {periodSeparator}
                  {description && (
                    <span
                      className={s.experienceSummary}
                      dangerouslySetInnerHTML={{
                        __html: highlight(`(…) ${description}…`),
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Experiences;
