import React, { useCallback, useState } from 'react';
import moment from 'moment-timezone';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import Alert from '../../components/Alert';
import AppNotifications from '../../components/AppNotifications';
import Link from '../../components/Link';
import FAIcon from '../../components/Icon/FAIcon';
import SavedSearchesModule from './SavedSearchesModule';
import ConsultationsStarting from '../../components/ConsultationsStarting';
import Button from '../../components/Button';
import RedirectCompleteness from '../../components/ProfileCompleteness/RedirectCompleteness';
import Projects from './Projects';
import Consultations from './Consultations';
import ExpertApplicationPromo from './ExpertApplicationPromo';
import NoConsultationsInstructions from './NoConsultationsInstructions';
import { red500, darkGreen } from '../../core/colors';
import agreements, { policies } from '../../core/agreements';

const getStyles = makeStyles(() => ({
  title: {
    margin: '0 0 30px',
  },
}));

function Dashboard({
  user,
  userContext,
  profile,
  awaitingConsultations,
  confirmedConsultations,
  savedSearches,
}) {
  const s = getStyles();
  const [passwordUpdateClosed, setPasswordUpdate] = useState(false);
  const handlePasswordUpdateClose = useCallback(
    () => setPasswordUpdate(true),
    []
  );

  const expertPartPol = policies['expert-participation-agreement'];
  const isExpert = userContext === 'expert';
  const isApplying =
    user.expert_state === 'applying' ||
    (user.signup_type === 'expert' && !user.expert_state);

  const alerts = [];
  const agreement = agreements(user.agreements);
  if (!agreement.hasAccepted('terms-of-use', 'privacy')) {
    alerts.push(
      <Alert type="error">
        <div style={{ marginBottom: 20 }}>
          Please take a moment to accept our updated Terms of Use and Privacy
          Policy.
        </div>

        <Link to="/legal_ack/privacy">
          <Button color="white" fontColor={red500}>
            Review &amp; Accept
          </Button>
        </Link>
      </Alert>
    );
  }

  if (isExpert && !agreement.hasAccepted('expert-participation-agreement')) {
    alerts.push(
      <Alert type="error">
        <div style={{ marginBottom: 20 }}>
          You must accept our {expertPartPol.name} before accepting a
          consultation request.
        </div>

        <Link to="/legal_ack/expert-participation-agreement">
          <Button color="white" fontColor={red500}>
            Review &amp; Accept
          </Button>
        </Link>
      </Alert>
    );
  }

  if (
    !passwordUpdateClosed &&
    user.password_expiry &&
    user.password_expiry.expiring
  ) {
    alerts.push(
      <Alert
        icon={<FAIcon color={darkGreen} icon="lock" />}
        preset="notification"
        onRequestClose={handlePasswordUpdateClose}
      >
        <div>
          To increase security, we ask users to update their password
          periodically. Your password will expire at{' '}
          {moment(user.password_expiry.expiry).format('MMM DD')}.
          <br />
          <br />
          <Link to="/settings/password">
            <Button variant="contained" size="medium">
              Update Password
            </Button>
          </Link>
        </div>
      </Alert>
    );
  }

  const loading =
    awaitingConsultations.loading || confirmedConsultations.loading;
  const totalConsultations =
    (awaitingConsultations.edges || []).length +
    (confirmedConsultations.edges || []).length;

  return (
    <div id="dashboard-root">
      <Grid container spacing={4}>
        <Grid item md xs>
          <ConsultationsStarting />

          {alerts}
          <AppNotifications />

          <RedirectCompleteness profile={profile} />

          <h2 className={s.title}>Dashboard</h2>

          <Consultations
            loading={loading}
            awaiting={awaitingConsultations.edges || []}
            confirmed={confirmedConsultations.edges || []}
          />

          {!isExpert && <Projects isViewerApplying={isApplying} />}

          {isExpert && isApplying && <ExpertApplicationPromo />}
          {isExpert && !isApplying && !loading && totalConsultations === 0 && (
            <NoConsultationsInstructions />
          )}
        </Grid>

        {!!savedSearches?.length && (
          <Grid item md={4} xs={12}>
            <SavedSearchesModule />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default connect((state) => ({
  user: state.viewer,
  userContext: state.ui.userContext,
  awaitingConsultations: state.consultations.dashboardAwaiting || {},
  confirmedConsultations: state.consultations.dashboardConfirmed || {},
  profile: state.profiles.fullProfiles?.[state.viewer?.profile?.id] || {},
  savedSearches: state.savedSearches,
}))(Dashboard);
