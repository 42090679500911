import React from 'react';
import Jobs from './Jobs';

export default {
  path: '/careers',
  element: <Jobs />,
  action() {
    document.title = 'Careers';
    return null;
  },
};
