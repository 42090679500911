import React from 'react';
import Body from '../Body';
import Layout from './Layout';
import { useLoaderData } from 'react-router-dom';

function LayoutPage({ children, bodyClassName, bodyStyle, ...other }) {
  const loaderData = useLoaderData();
  children = loaderData?.children || children;

  return (
    <Layout {...other}>
      <Body
        className={bodyClassName}
        style={{ paddingTop: 30, paddingBottom: 30, ...bodyStyle }}
      >
        {children}
      </Body>
    </Layout>
  );
}

export default LayoutPage;
